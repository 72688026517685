:root {
  --neutral-600: #344054;
  --neutral-800: #101828;
  --primary-main: #166fc2;
  --neutral-700: #1d2939;
  --neutral-400: #98a2b3;
  --neutral-100: #f9fafb;
  --shadow-long-card: rgba(27, 27, 27, .06);
  --neutral-500: #667085;
  --neutral-300: #e4e7ec;
  --neutral-200: #f2f4f7;
  --shadow-short-card: rgba(27, 27, 27, .04);
  --shadow-long-button-primary: rgba(22, 111, 194, .21);
  --primary-dark: #1362ab;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercelayoutcontainer {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercelayoutmain {
  flex: 0 800px;
  margin-right: 20px;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutblockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
}

.w-commerce-commercecheckoutcolumn {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.w-commerce-commercecheckoutshippingcity {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

.w-commerce-commercecheckoutshippingmethoditem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0;
  padding: 16px;
  font-weight: 400;
  display: flex;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 64px 16px;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus, .w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus, .w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus, .w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  flex-direction: row;
  display: flex;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  margin-left: 8px;
  font-weight: 400;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  flex: 0 0 320px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem, .w-commerce-commercecheckoutordersummaryextraitemslistitem {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutdiscounts {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  flex-wrap: wrap;
  padding: 20px;
  display: flex;
}

.w-commerce-commercecheckoutdiscountslabel {
  flex-basis: 100%;
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutdiscountsinput {
  height: 38px;
  width: auto;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  min-width: 0;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  flex: 1;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutdiscountsinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutdiscountsbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  margin-left: 8px;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartapplepaybutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  min-height: 30px;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  margin-bottom: 8px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercequickcheckoutgoogleicon, .w-commerce-commercequickcheckoutmicrosofticon {
  margin-right: 8px;
  display: block;
}

.w-commerce-commercecheckoutplaceorderbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-pagination-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.w-pagination-previous, .w-pagination-next {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 9px 20px;
  font-size: 14px;
  display: block;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-commerce-commercecartwrapper {
  display: inline-block;
  position: relative;
}

.w-commerce-commercecartopenlink {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartopenlinkcount {
  height: 18px;
  min-width: 18px;
  color: #3898ec;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
  margin-left: 8px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  display: inline-block;
}

.w-commerce-commercecartcontainerwrapper {
  z-index: 1001;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.w-commerce-commercecartcontainerwrapper--cartType-modal {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftSidebar {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightSidebar {
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftDropdown {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: auto;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  bottom: auto;
  left: auto;
  right: 0;
}

.w-commerce-commercecartcontainer {
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  overflow: auto;
  box-shadow: 0 5px 25px rgba(0, 0, 0, .25);
}

.w-commerce-commercecartheader {
  border-bottom: 1px solid #e6e6e6;
  flex: none;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.w-commerce-commercecartheading {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.w-commerce-commercecartcloselink {
  width: 16px;
  height: 16px;
}

.w-commerce-commercecartformwrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.w-commerce-commercecartform {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecartlist {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  padding: 12px 24px;
  overflow: auto;
}

.w-commerce-commercecartitem {
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}

.w-commerce-commercecartiteminfo {
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

.w-commerce-commercecartoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercecartquantity {
  height: 38px;
  width: 60px;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecartquantity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity::placeholder {
  color: #999;
}

.w-commerce-commercecartquantity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecartfooter {
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  flex: none;
  padding: 16px 24px 24px;
  display: flex;
}

.w-commerce-commercecartlineitem {
  flex: none;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px;
  display: flex;
}

.w-commerce-commercecartordervalue {
  font-weight: 700;
}

.w-commerce-commercecartcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecartemptystate {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
}

.w-commerce-commercecarterrorstate {
  background-color: #ffdede;
  flex: none;
  margin: 0 24px 24px;
  padding: 10px;
}

.w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem, .w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: .33em;
}

.w-commerce-commercecheckoutshippingsummarywrapper, .w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutcontainer {
    flex-direction: column;
    align-items: stretch;
  }

  .w-commerce-commercelayoutmain {
    flex-basis: auto;
    margin-right: 0;
  }

  .w-commerce-commercelayoutsidebar {
    flex-basis: auto;
  }

  .w-layout-blockcontainer {
    max-width: none;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecheckoutemailinput, .w-commerce-commercecheckoutshippingfullname, .w-commerce-commercecheckoutshippingstreetaddress, .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity, .w-commerce-commercecheckoutshippingstateprovince, .w-commerce-commercecheckoutshippingzippostalcode, .w-commerce-commercecheckoutshippingcountryselector, .w-commerce-commercecheckoutcardnumber, .w-commerce-commercecheckoutcardexpirationdate, .w-commerce-commercecheckoutcardsecuritycode, .w-commerce-commercecheckoutbillingfullname, .w-commerce-commercecheckoutbillingstreetaddress, .w-commerce-commercecheckoutbillingstreetaddressoptional, .w-commerce-commercecheckoutbillingcity, .w-commerce-commercecheckoutbillingstateprovince, .w-commerce-commercecheckoutbillingzippostalcode, .w-commerce-commercecheckoutbillingcountryselector, .w-commerce-commercecheckoutdiscountsinput {
    font-size: 16px;
  }

  .w-commerce-commercecartcontainerwrapper--cartType-modal {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  .w-commerce-commercecartcontainerwrapper--cartType-leftDropdown, .w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .w-commerce-commercecartquantity {
    font-size: 16px;
  }
}

body {
  color: var(--neutral-600);
  font-family: Plus Jakarta Display, sans-serif;
  line-height: 1.667em;
}

h1 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.233em;
}

h2 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 38px;
  font-weight: 700;
  line-height: 1.289em;
}

h3 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.36em;
}

h4 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.273em;
}

h5 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.556em;
}

h6 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375em;
}

p {
  margin-bottom: 20px;
}

a {
  color: var(--primary-main);
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  color: var(--neutral-700);
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

li {
  margin-bottom: 16px;
}

label {
  color: var(--neutral-800);
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1.111em;
  display: block;
}

strong {
  color: var(--neutral-800);
  font-weight: 700;
}

blockquote {
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-100);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  color: var(--primary-main);
  text-align: center;
  margin-bottom: 10px;
  padding: 114px 110px;
  font-size: 27px;
  line-height: 1.63em;
}

figure {
  margin-bottom: 10px;
}

figcaption {
  text-align: center;
  margin-top: 28px;
}

.mg-top-8px {
  margin-top: 8px;
}

.mg-bottom-8px {
  margin-bottom: 8px;
}

.mg-top-24px {
  margin-top: 24px;
}

.mg-top-32px {
  margin-top: 32px;
}

.mg-bottom-32px {
  margin-bottom: 32px;
}

.mg-top-40px {
  margin-top: 40px;
}

.flex {
  display: flex;
}

.color-white {
  color: var(--neutral-100);
}

.bg-primary-1 {
  background-color: var(--primary-main);
}

.bg-neutral-700 {
  background-color: var(--neutral-700);
}

.bg-neutral-600 {
  background-color: var(--neutral-600);
}

.bg-neutral-500 {
  background-color: var(--neutral-500);
}

.bg-neutral-400 {
  background-color: var(--neutral-400);
}

.bg-neutral-300 {
  background-color: var(--neutral-300);
}

.bg-neutral-200 {
  background-color: var(--neutral-200);
}

.bg-neutral-100 {
  background-color: var(--neutral-100);
}

.style-guide-sidebar {
  z-index: 2;
  height: 100%;
  min-height: 96vh;
  flex: 0 14em;
  margin-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 32px;
}

.container-default {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  text-align: left;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
}

.container-default.home-topics {
  position: relative;
}

.style-guide-content {
  max-width: 100%;
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-100);
  box-shadow: 0 8px 22px 0 var(--shadow-long-card);
  flex: 1;
  margin-left: 18px;
}

.section-styleguide {
  padding-top: 60px;
  padding-bottom: 86px;
}

.style-guide-title {
  color: #fff;
  margin-top: 0;
}

.style-guide-header {
  background-color: var(--primary-main);
  padding: 100px 68px;
}

.sidebar-navigation {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.style-guide-icon-link {
  width: 56px;
  height: 56px;
  background-color: var(--primary-main);
  border-radius: 10000000px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  transition: background-color .25s;
  display: flex;
}

.style-guide-sidebar-icon {
  width: 60%;
}

.style-guide-link {
  color: var(--neutral-600);
  align-items: center;
  padding: 12px 20px;
  text-decoration: none;
  display: flex;
}

.style-guide-link:hover {
  background-color: var(--neutral-300);
  color: var(--primary-main);
}

.style-guide-link.w--current {
  background-color: var(--neutral-300);
  color: var(--primary-main);
  font-weight: 700;
  transition: color .3s, background-color .3s;
}

.style-guide-link-wrapper {
  margin-bottom: 8px;
}

.style-guide-sidebar-title {
  border-bottom: 1px solid var(--neutral-400);
  color: var(--neutral-800);
  margin-bottom: 24px;
  padding-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 22px;
  line-height: 24px;
}

.style-guide-sidebar-title.middle {
  border-top: 1px solid var(--neutral-400);
  margin-top: 24px;
  padding-top: 24px;
}

.color-primary-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.color-container {
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-100);
  box-shadow: 0 6px 24px 0 var(--shadow-short-card);
}

.color-block {
  min-height: 120px;
}

.color-content {
  border-top: 1px solid var(--neutral-400);
  padding: 22px 16px 24px;
}

.color-title {
  color: var(--neutral-800);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.color-hex {
  color: var(--neutral-600);
  line-height: 20px;
}

.style-guide-subtitle {
  margin-top: 0;
}

.color-content-wrapper {
  padding-bottom: 56px;
}

.color-content-wrapper.last {
  padding-bottom: 0;
}

.color-neutral-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
}

.typography-container {
  border-bottom: 1px solid var(--neutral-400);
  padding-top: 54px;
  padding-bottom: 54px;
}

.typography-container.last {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.container-small-left {
  max-width: 650px;
}

.typography-details {
  color: var(--primary-main);
  margin-top: 15px;
  font-weight: 500;
}

.paragraph-large {
  font-size: 24px;
  line-height: 1.583em;
}

.style-guide-subheader {
  background-color: var(--neutral-200);
  padding: 56px 68px;
}

.style-guide-content-wrapper {
  padding: 110px 68px;
}

.icons-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 80px);
}

.buttons-grid {
  grid-column-gap: 24px;
  grid-row-gap: 56px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.button-primary {
  background-color: var(--primary-main);
  box-shadow: 0 12px 35px 0 var(--shadow-long-button-primary);
  color: var(--neutral-100);
  text-align: center;
  border-radius: 1000px;
  padding: 24px 48px;
  font-weight: 700;
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s, background-color .3s;
}

.button-primary:hover {
  background-color: var(--primary-dark);
  box-shadow: 0 3px 5px 0 var(--shadow-long-button-primary);
  color: var(--neutral-100);
  transform: scale(.97);
}

.button-primary.small {
  padding: 14px 28px;
  line-height: 1.667em;
}

.button-primary.small.footer-newsletter {
  position: absolute;
  right: 12px;
}

.button-primary.small.back-to-resources {
  margin-bottom: 48px;
  margin-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.button-primary.large {
  padding: 24px 68px;
  font-size: 20px;
}

.button-primary.full-width {
  align-self: stretch;
  display: block;
}

.button-primary.button-white {
  color: var(--primary-main);
  background-color: #fff;
}

.button-primary.checkout {
  margin-top: 15px;
  margin-bottom: 0;
}

.button-primary._2-buttons {
  margin-bottom: 20px;
  margin-right: 26px;
}

.button-primary.header-button {
  padding: 18px 26px;
  font-size: 16px;
}

.button-primary.blog-newsletter {
  position: absolute;
  right: 11px;
}

.button-primary.cart-empty {
  margin-top: 40px;
}

.button-primary.button-row {
  margin-right: 26px;
}

.button-secondary {
  background-color: var(--neutral-100);
  color: var(--neutral-800);
  text-align: center;
  border-radius: 1000px;
  padding: 24px 48px;
  font-weight: 300;
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s, color .3s, background-color .3s;
  box-shadow: 0 6px 14px rgba(0, 0, 0, .02), 0 1px 4px rgba(27, 27, 27, .02), 0 4px 14px rgba(25, 25, 25, .06);
}

.button-secondary:hover {
  background-color: var(--primary-main);
  color: var(--neutral-100);
  transform: scale(.97);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .02), 0 1px 4px rgba(25, 25, 25, .06);
}

.button-secondary.small {
  padding: 14px 28px;
  line-height: 1.667em;
}

.button-secondary.large {
  padding: 24px 68px;
  font-size: 20px;
}

.button-secondary.discounts {
  height: auto;
  border-width: 1px;
  flex: 0 auto;
  margin-left: 0;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
}

.button-secondary._2-buttons {
  margin-bottom: 20px;
}

.button-secondary.full-width {
  display: block;
}

.button-secondary.full-width.buy-now {
  margin-top: 22px;
}

.button-secondary.category {
  border: 1px solid var(--neutral-400);
  color: var(--neutral-600);
  padding: 14px 28px;
  line-height: 1.667em;
  transition: transform .3s, border-color .3s, box-shadow .3s, color .3s, background-color .3s;
}

.button-secondary.category:hover {
  border-color: var(--primary-main);
  background-color: var(--primary-main);
  box-shadow: none;
  color: var(--neutral-100);
}

.button-secondary.category.w--current {
  border-color: var(--primary-main);
  background-color: var(--primary-main);
  color: var(--neutral-100);
  font-weight: 700;
}

.button-secondary.category.all {
  margin-right: 23px;
}

.button-secondary.pagination {
  margin-top: 80px;
  font-size: 18px;
}

.style-guide-button-container {
  position: absolute;
  top: auto;
  bottom: 24px;
  left: 0%;
  right: 0%;
}

.cards-grid {
  grid-column-gap: 24px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  align-items: start;
}

.header {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 32px;
  padding-bottom: 32px;
}

.header-navigation {
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.nav-item-wrapper {
  margin-bottom: 0;
  margin-right: 40px;
}

.nav-item-wrapper.button-mobile {
  display: none;
}

.nav-link {
  color: var(--neutral-600);
  line-height: 1.125em;
  text-decoration: none;
}

.nav-link:hover {
  color: var(--primary-main);
}

.nav-link.dropdown {
  padding: 0;
  display: block;
}

.footer {
  border-top: 1px solid var(--neutral-300);
  padding-top: 100px;
  padding-bottom: 40px;
}

.footer.v3 {
  padding-top: 46px;
  padding-bottom: 46px;
}

.footer-logo-container {
  transition-property: transform;
}

.footer-logo-container:hover {
  transform: scale(.97);
}

.footer-logo-container.mg-bottom-16px {
  margin-bottom: 16px;
}

.footer-nav {
  margin-bottom: 0;
  margin-right: 20px;
  padding-left: 0;
}

.footer-nav.last {
  margin-right: 0;
}

.footer-logo {
  width: 222px;
}

.footer-nav-item {
  margin-bottom: 26px;
  line-height: 1em;
}

.section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.section.bg-neutral-200.course-videos-hero {
  margin-top: 0;
  padding-top: 64px;
  padding-bottom: 64px;
  display: block;
  overflow: hidden;
}

.section.bg-neutral-200.courses {
  overflow: hidden;
}

.section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.section.home-course-steps {
  overflow: hidden;
}

.section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.section.about-hero.resources-page {
  padding-top: 24px;
}

.section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.section.values, .section.about-testimonials {
  overflow: hidden;
}

.section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  overflow: hidden;
}

.section.course-levels, .section.blog-category {
  padding-top: 60px;
}

.section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.section.other-courses {
  padding-top: 128px;
}

.section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.section.notification-bar, .section.notification-bar-tab, .section.notification-bar-landscape, .section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.section.test-portal {
  height: 100%;
  max-height: 100%;
  background-color: white;
  padding: 28px 16px 128px;
  overflow: hidden;
}

.section.resources-hero.resources-page {
  padding-top: 24px;
}

.section.tc-hero, .section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.utility-page-wrap {
  min-height: 800px;
  background-color: var(--neutral-200);
  justify-content: center;
  align-items: center;
  padding: 80px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.utility-page-wrap._404 {
  background-color: var(--neutral-800);
}

.utility-page-content-password {
  z-index: 1;
  width: 100%;
  max-width: 580px;
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-100);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  text-align: center;
  flex-direction: column;
  padding: 72px 50px;
  display: flex;
  position: relative;
}

.utility-page-form {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.input {
  min-height: 68px;
  background-color: var(--neutral-200);
  color: var(--neutral-800);
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 100000px;
  margin-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 18px;
  line-height: 1.111em;
  transition: box-shadow .3s, border-color .3s, background-color .3s, color .3s;
}

.input:hover {
  border-color: var(--primary-main);
  background-color: var(--neutral-100);
  box-shadow: 0 4px 20px rgba(27, 27, 27, .05);
}

.input:focus, .input.-wfp-focus {
  border-color: var(--primary-main);
  background-color: var(--neutral-100);
  color: var(--neutral-800);
  box-shadow: 0 4px 20px rgba(27, 27, 27, .05);
}

.input::-ms-input-placeholder {
  color: var(--neutral-600);
  font-size: 18px;
}

.input::placeholder {
  color: var(--neutral-600);
  font-size: 18px;
}

.input.password {
  margin-bottom: 20px;
}

.input.checkout {
  min-height: 60px;
  margin-bottom: 16px;
}

.input.discounts {
  width: 100%;
  min-height: 60px;
  border-width: 1px;
  flex: 0 auto;
  margin-bottom: 16px;
}

.input.footer-newsletter {
  min-height: 80px;
  padding-right: 165px;
}

.input.blog-newsletter {
  min-height: 92px;
  padding-left: 44px;
  padding-right: 205px;
}

.input.cart-quantity-item {
  min-height: 38px;
  padding-left: 12px;
  padding-right: 6px;
  display: none;
}

.utility-page-content-404 {
  z-index: 1;
  text-align: center;
  position: relative;
}

._404-title {
  color: var(--primary-main);
  text-shadow: 0 12px 35px var(--shadow-long-button-primary);
  margin-bottom: 30px;
  font-size: 180px;
  font-weight: 700;
  line-height: 1em;
}

.container-medium-761px {
  max-width: 761px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-medium-761px.utility-page {
  text-align: center;
}

.divider {
  width: 100%;
  min-height: 1px;
  background-color: var(--neutral-500);
}

.divider.utility-pages {
  margin-top: 60px;
  margin-bottom: 60px;
}

.divider.card-testimonial {
  margin-top: 0;
  margin-bottom: 0;
}

.divider.card-get-course {
  background-color: var(--neutral-400);
  margin-top: 28px;
  margin-bottom: 28px;
}

.divider.course-videos-library {
  background-color: var(--neutral-400);
  margin-top: 0;
  margin-bottom: 0;
}

.divider.course-videos {
  background-color: var(--neutral-400);
  margin-top: 48px;
  margin-bottom: 48px;
}

.icon-password {
  width: 150px;
  height: 150px;
  background-color: var(--primary-main);
  border-radius: 1000px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
  overflow: hidden;
}

.paragraph.password, .paragraph.home-hero {
  margin-bottom: 40px;
}

.paragraph.home-about {
  margin-bottom: 56px;
}

.paragraph.home-course-steps, .paragraph.card-home-course-step {
  margin-bottom: 0;
}

.paragraph.home-topics {
  max-width: 540px;
  text-align: right;
  flex: 1;
  margin-bottom: 0;
}

.paragraph.card-home-topic, .paragraph.faqs, .paragraph.faq {
  margin-bottom: 0;
}

.paragraph.color-neutral-400 {
  color: var(--neutral-400);
}

.paragraph.color-neutral-400.testimonials {
  max-width: 540px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.paragraph.color-neutral-400.cta, .paragraph.color-neutral-400.blog-newsletter {
  margin-bottom: 0;
}

.paragraph.color-neutral-400._404 {
  margin-bottom: 40px;
}

.paragraph.footer-newsletter {
  margin-bottom: 24px;
}

.paragraph.about-hero {
  margin-bottom: 40px;
}

.paragraph.story {
  text-align: justify;
  margin-bottom: 0;
  padding-right: 84px;
}

.paragraph.card-value {
  margin-bottom: 0;
}

.paragraph.experience {
  max-width: 540px;
  margin-bottom: 0;
}

.paragraph.card-experience {
  margin-bottom: 24px;
}

.paragraph.about-testimonials {
  max-width: 540px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.paragraph.card-about-testimonial {
  margin-bottom: 40px;
}

.paragraph.courses-hero {
  max-width: none;
  margin-bottom: 0;
}

.paragraph.card-course-featured-summary {
  margin-bottom: 16px;
}

.paragraph.card-course-summary, .paragraph.course-hero {
  margin-bottom: 40px;
}

.paragraph.course-reviews {
  z-index: 1;
  margin-bottom: 72px;
  position: relative;
}

.paragraph.course-videos-hero-course-summary {
  margin-bottom: 47px;
}

.paragraph.course-videos, .paragraph.card-course-video {
  margin-bottom: 0;
}

.paragraph.course-videos-links {
  margin-bottom: 45px;
}

.paragraph.card-course-videos-community {
  margin-bottom: 34px;
}

.paragraph.post-featured, .paragraph.post-item {
  margin-bottom: 24px;
}

.paragraph.post-excerpt {
  max-width: 644px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.paragraph.card-author {
  margin-bottom: 40px;
}

.paragraph.contact {
  max-width: 488px;
  margin-bottom: 40px;
}

.paragraph.card-direct-contact-link, .paragraph.utility-page, .paragraph.card-default, .paragraph.checkout-hero {
  margin-bottom: 0;
}

.paragraph.story {
  text-align: justify;
  text-align: justify;
  margin-bottom: 0;
  padding-right: 84px;
}

.paragraph.course-content-paragraph {
  max-width: 540px;
  text-align: right;
  flex: 1;
  margin-bottom: 0;
  padding-left: 16px;
}

.paragraph-small {
  font-size: 16px;
  line-height: 1.75em;
}

.card {
  border: 1px solid var(--neutral-300);
  background-color: var(--neutral-100);
  box-shadow: 0 6px 24px 0 var(--shadow-short-card);
  overflow: hidden;
}

.card.changelog {
  z-index: 1;
  border-color: var(--neutral-400);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  text-align: center;
  margin-bottom: 40px;
  padding: 60px 50px;
  position: relative;
}

.card.changelog.last {
  margin-bottom: 0;
}

.card.checkout {
  border-color: var(--neutral-400);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  margin-bottom: 40px;
}

.card.checkout.last {
  margin-bottom: 0;
}

.card.checkout.order-summary {
  box-shadow: none;
  border-width: 0;
  margin-bottom: 0;
}

.card.home-about {
  z-index: 2;
  height: 50%;
  max-height: 50%;
  max-width: 100%;
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  align-self: flex-end;
  margin-top: -62px;
  padding: 16px 48px;
  position: relative;
}

.card.bg-neutral-300 {
  background-color: var(--neutral-300);
  box-shadow: none;
}

.card.bg-neutral-300.home-course-step {
  box-shadow: none;
  border-width: 0;
  padding: 32px 40px;
}

.card.bg-neutral-300.value {
  text-align: center;
  padding: 56px 38px 72px;
}

.card.bg-neutral-300.course-video {
  color: var(--neutral-600);
  padding: 58px 48px 68px;
  text-decoration: none;
  display: flex;
}

.card.bg-neutral-300.course-video:hover {
  color: var(--neutral-600);
}

.card.bg-neutral-300.course-videos-resource {
  color: var(--neutral-600);
  align-items: center;
  padding: 46px 51px;
  text-decoration: none;
  display: flex;
}

.card.bg-neutral-300.course-videos-resource:hover {
  color: var(--neutral-600);
}

.card.bg-neutral-300.course-videos-community {
  color: var(--neutral-600);
  padding: 58px 48px 62px;
  text-decoration: none;
  display: flex;
}

.card.bg-neutral-300.course-videos-community:hover {
  color: var(--neutral-600);
}

.card.home-topic {
  text-align: center;
  padding: 56px 38px 72px;
}

.card.testimonial {
  border-width: 0;
  justify-content: space-between;
  align-items: flex-end;
  padding: 26px 75px 36px;
  display: flex;
}

.card.faq {
  cursor: pointer;
  margin-bottom: 24px;
  padding: 45px 31px;
  transition: box-shadow .3s;
}

.card.faq:hover {
  box-shadow: 0 4px 15px rgba(27, 27, 27, .12);
}

.card.faq.last {
  margin-bottom: 0;
}

.card.experience {
  border-color: var(--neutral-400);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  padding: 64px 40px;
}

.card.about-testimonial {
  flex-direction: column;
  padding: 72px 48px;
  display: flex;
}

.card.course-featured {
  z-index: 1;
  height: 75%;
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  color: var(--neutral-600);
  border-radius: 8px;
  justify-content: flex-start;
  margin-bottom: 64px;
  text-decoration: none;
  transition: box-shadow .3s, transform .3s;
  display: flex;
  position: relative;
}

.card.course-featured:hover {
  box-shadow: 0 2px 4px 0 var(--shadow-long-card);
  color: var(--neutral-600);
  transform: translate(0, -5px);
}

.card.course {
  min-height: 100%;
  border-color: var(--neutral-400);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  color: var(--neutral-600);
  flex-direction: column;
  text-decoration: none;
  transition: box-shadow .3s, transform .3s;
  display: flex;
}

.card.course:hover {
  box-shadow: 0 2px 4px 0 var(--shadow-long-card);
  color: var(--neutral-600);
  transform: translate(0, -10px);
}

.card.get-course {
  z-index: 1;
  padding: 56px 42px 74px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.card.author {
  max-width: 780px;
  border-color: var(--neutral-400);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  justify-content: space-between;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 76px 64px;
  display: flex;
}

.card.contact {
  z-index: 1;
  min-height: 100%;
  border-color: var(--neutral-400);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  align-items: center;
  padding: 28px 40px;
  display: flex;
  position: relative;
}

.card.direct-contact-link {
  color: var(--neutral-600);
  text-align: center;
  padding: 56px 38px 61px;
  text-decoration: none;
  transition: box-shadow .3s, transform .3s;
}

.card.direct-contact-link:hover {
  box-shadow: 0 1px 2px 0 var(--shadow-short-card);
  color: var(--neutral-600);
  transform: translate(0, -10px);
}

.card.default-v1 {
  padding: 64px 40px;
}

.card.default-v2 {
  border-color: var(--neutral-400);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  padding: 64px 40px;
}

.card.default-v3 {
  background-color: var(--neutral-300);
  box-shadow: none;
  padding: 64px 40px;
}

.checkout-form {
  background-color: rgba(0, 0, 0, 0);
  padding: 80px 0 160px;
}

.checkout-block-header {
  border-width: 0;
  flex-wrap: wrap;
  padding: 30px 40px 20px;
}

.checkout-block-content {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  padding: 10px 40px 40px;
}

.title.checkout {
  margin-bottom: 0;
  margin-right: 30px;
}

.title.order-item-title {
  color: var(--neutral-800);
  font-size: 19px;
  font-weight: 700;
  text-decoration: none;
}

.title.order-item-title:hover {
  color: var(--primary-main);
}

.title.card-home-about-name {
  margin-bottom: 10px;
}

.title.h3-size {
  color: var(--neutral-800);
  font-size: 25px;
  font-weight: 700;
  line-height: 1.36em;
}

.title.h3-size.home-features {
  z-index: 1;
  color: var(--neutral-100);
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}

.title.h3-size.card-course-featured {
  margin-bottom: 36px;
}

.title.h3-size.post-featured {
  margin-bottom: 20px;
}

.title.h3-size.card-author-name {
  margin-bottom: 8px;
}

.title.card-home-course-step {
  margin: 0;
}

.title.home-topics {
  max-width: 457px;
  margin-bottom: 0;
  margin-right: 30px;
}

.title.color-neutral-100 {
  color: var(--neutral-100);
}

.title.color-neutral-100.blog-newsletter {
  margin-bottom: 12px;
}

.title.h4-size {
  color: var(--neutral-800);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.273em;
}

.title.h4-size.faq {
  margin-bottom: 0;
}

.title.h4-size.blog-hero-popular {
  margin-bottom: 38px;
}

.title.footer-menu-title, .title.dropdown-nav-title {
  color: var(--primary-main);
  letter-spacing: .12em;
  text-transform: uppercase;
  margin-bottom: 48px;
  font-size: 14px;
  font-weight: 700;
  line-height: 2em;
}

.title.values {
  z-index: 1;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}

.title.experience {
  margin-bottom: 0;
  margin-right: 30px;
}

.title.card-experience {
  margin-bottom: 8px;
}

.title.about-testimonials {
  max-width: 523px;
  margin-left: auto;
  margin-right: auto;
}

.title.courses {
  z-index: 1;
  text-align: center;
  margin-bottom: 56px;
  position: relative;
}

.title.card-course {
  margin-bottom: 14px;
}

.title.course-tabs {
  z-index: 1;
  font-size: 32px;
  position: relative;
}

.title.course-tabs.career-opportunities {
  margin-top: 24px;
  margin-bottom: 24px;
}

.title.card-get-course {
  margin-bottom: 32px;
}

.title.other-courses {
  margin-bottom: 0;
}

.title.card-course-videos-resource {
  margin-bottom: 14px;
}

.title.card-course-videos-community {
  margin-bottom: 8px;
}

.title.blog-hero {
  margin-bottom: 55px;
}

.title.h5-size {
  font-size: 18px;
  line-height: 1.556em;
}

.title.h5-size.post-popular, .title.blog {
  margin-bottom: 0;
}

.title.post-item {
  margin-bottom: 20px;
}

.title.latest-articles {
  margin-bottom: 0;
}

.title.direct-contact-links {
  z-index: 1;
  text-align: center;
  margin-bottom: 32px;
  position: relative;
}

.title.contact {
  margin-bottom: 32px;
}

.title.card-direct-contact-link {
  margin-bottom: 7px;
}

.title.category {
  margin-bottom: 0;
  margin-right: 10px;
}

.title.h1-size {
  color: var(--neutral-800);
  font-size: 60px;
  font-weight: 700;
  line-height: 1.233em;
}

.title.h1-size.category-text {
  margin-bottom: 0;
}

.title.h2-size {
  color: var(--neutral-800);
  font-size: 38px;
  font-weight: 700;
  line-height: 1.289em;
}

.title.h2-size._404 {
  color: var(--neutral-100);
}

.title.course-content-title {
  max-width: 457px;
  margin-bottom: 0;
  margin-right: 30px;
}

.title.resource-title {
  text-align: center;
  margin-bottom: 55px;
  font-size: 28px;
}

.split-content.checkout-left, .split-content.checkout-right {
  flex-grow: 1;
}

.split-content.header-left {
  align-items: center;
  display: flex;
}

.split-content.home-hero-left {
  z-index: 1;
  max-width: 46%;
  min-width: 504px;
  margin-right: 30px;
  position: relative;
}

.split-content.home-hero-right {
  max-width: 47%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.split-content.home-about-left {
  width: 100%;
  max-width: 47%;
  flex-direction: column;
  margin-right: 30px;
  display: flex;
  position: relative;
}

.split-content.home-about-right {
  z-index: 1;
  max-width: 44%;
  min-width: 493px;
  position: relative;
}

.split-content.faqs-left {
  z-index: 1;
  width: 100%;
  max-width: 618px;
  margin-right: 30px;
  position: relative;
}

.split-content.faqs-right {
  z-index: 1;
  width: 100%;
  max-width: 618px;
  position: relative;
}

.split-content.cta-left {
  z-index: 1;
  max-width: 555px;
  flex: 1;
  margin-right: 30px;
  position: relative;
}

.split-content.cta-right {
  z-index: 1;
  position: relative;
}

.split-content.header-center {
  align-items: center;
  display: flex;
}

.split-content.header-center.right-mg-responsive {
  margin-right: 40px;
}

.split-content.about-hero-left {
  max-width: 670px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  display: flex;
  position: relative;
}

.split-content.about-hero-right {
  z-index: 1;
  max-width: 43%;
  min-width: 438px;
  position: relative;
}

.split-content.story-left {
  z-index: 1;
  max-height: 100%;
  max-width: 100%;
  flex-flow: row;
  flex: 0 auto;
  order: 0;
  align-content: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  margin-top: 52px;
  margin-right: 30px;
  display: flex;
  position: relative;
}

.split-content.story-right {
  z-index: 1;
  max-height: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: 30px;
  display: flex;
  position: relative;
}

.split-content.content-bottom-card-about-testimonial-left {
  width: 100%;
  max-width: 286px;
  align-items: center;
  margin-bottom: 25px;
  margin-right: 30px;
  display: flex;
}

.split-content.course-hero-content {
  z-index: 1;
  max-width: 549px;
  min-width: 445px;
  margin-right: 20px;
  position: relative;
}

.split-content.video-preview-wrapper {
  max-width: 723px;
  position: relative;
  transform: translate(0);
}

.split-content.course-left {
  width: 100%;
  max-width: 58%;
  margin-right: 30px;
}

.split-content.course-right {
  width: 100%;
  max-width: 36%;
}

.split-content.course-videos-hero-left {
  z-index: 1;
  max-width: 535px;
  min-width: 483px;
  margin-right: 0;
  position: relative;
}

.split-content.course-videos-hero-right {
  max-width: 723px;
  position: relative;
}

.split-content.card-course-video-left {
  max-width: 424px;
  margin-right: 20px;
}

.split-content.contact-left {
  z-index: 1;
  height: 100%;
  max-height: 100%;
  max-width: 524px;
  min-width: 380px;
  margin-right: 30px;
  position: relative;
}

.split-content.contact-right {
  width: 100%;
  max-width: 687px;
  position: relative;
}

.split-content.blog-newsletter-content {
  max-width: 41%;
  min-width: 360px;
  margin-right: 20px;
}

.split-content.header-right-align {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.split-content.comingsoon-header {
  z-index: 1;
  max-width: 100%;
  min-width: 100%;
  text-align: center;
  margin-right: 20px;
  position: relative;
}

.checkout-field-label {
  margin-top: 20px;
  margin-bottom: 10px;
}

.checkout-required-text {
  color: var(--primary-main);
}

.checkout-billing-address-toggle {
  align-items: center;
  margin-top: 10px;
}

.checkout-checkbox-label {
  margin-bottom: 0;
}

.checkout-checkbox {
  margin-top: 0;
}

.order-item-list {
  margin-bottom: -40px;
}

.order-item {
  justify-content: space-between;
  margin-bottom: 40px;
}

.image-wrapper {
  align-items: center;
  display: flex;
  overflow: hidden;
  transform: translate(0);
}

.image-wrapper.order-item-image {
  max-width: 130px;
  margin-bottom: 15px;
  margin-right: 15px;
  transition-property: transform;
}

.image-wrapper.order-item-image:hover {
  transform: scale(.97)translate(0);
}

.image-wrapper.order-item-image.cart {
  max-width: 100px;
  margin-top: 8px;
}

.image-wrapper.home-hero {
  z-index: 1;
  position: relative;
}

.image-wrapper.home-hero-about-teacher {
  width: 72px;
  max-height: 72px;
  min-height: 72px;
  min-width: 72px;
  border-radius: 1000px;
  margin-right: 24px;
}

.image-wrapper.home-about {
  z-index: 1;
  max-width: 100%;
  position: relative;
}

.image-wrapper.home-course-steps {
  z-index: 1;
  max-width: 49%;
  position: relative;
}

.image-wrapper.about-hero {
  z-index: 1;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: relative;
}

.image-wrapper.story-left {
  z-index: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
}

.image-wrapper.story-right {
  z-index: 1;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: fill;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
}

.image-wrapper.card-experience-icon {
  width: 80px;
  max-height: 80px;
  min-height: 80px;
  min-width: 80px;
  border-radius: 1000px;
  margin-right: 16px;
}

.image-wrapper.card-course-featured {
  max-width: 723px;
}

.image-wrapper.video-preview {
  z-index: 1;
  justify-content: center;
  position: relative;
}

.image-wrapper.course-videos-hero-course-image {
  z-index: 1;
  position: relative;
}

.image-wrapper.post-featured {
  margin-bottom: 35px;
}

.image-wrapper.post-popular {
  max-width: 39%;
  margin-right: 28px;
}

.image-wrapper.post-item {
  margin-bottom: 32px;
}

.image-wrapper.post {
  margin-bottom: 140px;
}

.image-wrapper.post-author {
  width: 72px;
  max-height: 72px;
  min-height: 72px;
  min-width: 72px;
  border-radius: 1000px;
  margin-right: 24px;
}

.image-wrapper.card-author {
  width: 168px;
  max-height: 168px;
  min-height: 168px;
  min-width: 168px;
  border-radius: 10000px;
  margin-right: 33px;
}

.image-wrapper.card-direct-contact-link {
  width: 110px;
  max-height: 110px;
  min-height: 110px;
  min-width: 110px;
  border-radius: 10000px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}

.image-wrapper.course-image {
  width: 40%;
  flex: 0 auto;
}

.image.order-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image.home-hero {
  width: 100%;
  object-fit: cover;
}

.image.company-home-hero {
  max-width: 100%;
}

.image.company-home-hero.logo-image {
  width: 100%;
  height: 33px;
  object-fit: contain;
  object-position: 50% 50%;
}

.image.home-about {
  width: 100%;
  object-fit: cover;
  border-radius: 0;
}

.image.card-home-about-signature {
  margin-bottom: 20px;
  margin-right: 20px;
}

.image.home-course-steps {
  height: 100%;
  min-height: 100%;
  object-fit: cover;
}

.image.card-testimonial {
  max-width: 309px;
  margin-right: 0;
}

.image.card-testimonial-logo {
  display: none;
}

.image.about-hero {
  width: 75%;
  max-width: 75%;
  clear: none;
  object-fit: cover;
  flex: 0 auto;
  align-self: auto;
}

.image.address-icon {
  width: 42px;
  height: 42px;
  max-height: 100%;
  min-height: 26px;
  min-width: 26px;
  border-radius: 1000px;
  margin-right: 16px;
}

.image.story-left {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: auto;
}

.image.story-right {
  width: 100%;
  height: 100%;
  max-height: 100%;
  aspect-ratio: auto;
  object-fit: fill;
}

.image.card-experience-icon {
  width: 100%;
  object-fit: cover;
}

.image.card-about-testimonial-logo {
  margin-bottom: 25px;
}

.image.card-about-testimonial {
  width: 72px;
  max-height: 72px;
  min-height: 72px;
  min-width: 72px;
  border-radius: 10000px;
  margin-right: 24px;
}

.image.card-course-featured {
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
}

.image.card-course {
  width: 100%;
  object-fit: cover;
}

.image.card-course-level-icon {
  max-width: 18px;
  margin-right: 8px;
}

.image.video-preview-button-icon {
  width: 31%;
  position: relative;
  left: 6px;
}

.image.course-feature-icon {
  width: 28px;
  max-height: 28px;
  min-height: 28px;
  min-width: 28px;
  border-radius: 10000px;
  margin-right: 14px;
  position: relative;
  top: -4px;
}

.image.course-videos-hero-course-image {
  width: 100%;
  object-fit: cover;
}

.image.card-course-video-button-play-icon {
  max-width: 26px;
  position: relative;
  left: 4px;
}

.image.card-course-videos-resource-icon, .image.card-course-videos-community-icon {
  max-width: 34px;
}

.image.post-featured, .image.post-popular, .image.post-item, .image.post, .image.post-author, .image.card-author, .image.card-direct-contact-link {
  width: 100%;
  object-fit: cover;
}

.image.style-guide-icon {
  width: 80px;
  max-height: 80px;
  min-height: 80px;
  min-width: 80px;
  background-color: var(--primary-main);
  object-fit: scale-down;
  border-radius: 1000000000px;
}

.image.password {
  width: 100%;
  object-fit: cover;
}

.image.course-image {
  width: 100%;
  height: 75%;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 0;
}

.order-item-wrapper {
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-right: 16px;
  display: flex;
}

.order-item-wrapper.cart {
  align-items: flex-start;
}

.order-item-content {
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
}

.order-item-price {
  color: var(--neutral-800);
}

.apple-pay {
  height: 55px;
  border-radius: 100000000px;
}

.checkout-line-item {
  flex-wrap: wrap;
}

.order-summary-subtotal {
  color: var(--neutral-800);
  font-weight: 500;
}

.order-summary-total {
  color: var(--neutral-800);
  font-size: 20px;
}

.discounts {
  border-width: 0;
  flex-direction: column;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.paypal {
  margin-bottom: 15px;
}

.checkout-hero-wrapper {
  max-width: 650px;
}

.header-wrapper {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.header-wrapper.center-align {
  justify-content: center;
}

.cart-button {
  color: var(--neutral-600);
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  font-size: 16px;
  line-height: 1.125em;
}

.cart-button:hover {
  color: var(--primary-main);
}

.cart-quantity {
  height: auto;
  min-width: auto;
  color: var(--primary-main);
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.125em;
}

.cart-container {
  max-width: 500px;
  min-width: auto;
}

.grid-licenses-images {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 24px;
}

.licenses-link-image-container {
  transition: transform .35s;
}

.licenses-link-image-container:hover {
  transform: translate(0, -4px);
}

.bg-neutral-800 {
  background-color: var(--neutral-800);
}

.home-hero-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 128px;
  display: flex;
}

.home-hero-about-teacher-wrapper {
  align-items: center;
  margin-bottom: 56px;
  display: flex;
}

._2-buttons-wrapper {
  margin-bottom: -20px;
}

.home-hero-about-teacher-name {
  color: var(--neutral-800);
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1em;
}

.home-hero-about-teacher-rol {
  color: var(--primary-main);
  line-height: 1.111em;
}

.companies-home-hero-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.companies-home-hero-text {
  margin-right: 30px;
  line-height: 1.111em;
}

.companies-home-hero-grid {
  width: 100%;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex: 1;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.container-large-1215px {
  max-width: 1215px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-large-1215px.home-features {
  position: relative;
}

.home-about-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card-home-about-rol {
  color: var(--primary-main);
  margin-bottom: 24px;
  line-height: 1.111em;
}

.card-home-about-content-top {
  margin-bottom: 24px;
}

.card-home-about-content-bottom {
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -20px;
  display: flex;
}

.card-home-about-social-media-grid {
  width: 100%;
  max-width: 159px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.social-media-link {
  color: var(--neutral-500);
  font-family: Icons Course X Template, sans-serif;
  font-size: 21px;
  line-height: 1em;
  text-decoration: none;
}

.social-media-link:hover {
  color: var(--primary-main);
}

.social-media-link.icon-size-20px {
  font-size: 20px;
}

.social-media-link.icon-size-22px {
  font-size: 22px;
}

.social-media-link.icon-size-19px {
  font-size: 19px;
}

.container-small-588px {
  max-width: 588px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-small-588px.home-course-steps {
  text-align: center;
  margin-bottom: 26px;
}

.home-course-steps-wrapper {
  justify-content: space-between;
  margin-bottom: 56px;
  display: flex;
  position: relative;
}

.flex-vc {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.flex-vc.relative {
  z-index: 1;
  position: relative;
}

.home-features-wrapper {
  z-index: 1;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  position: relative;
}

.home-feature-wrapper {
  border-right: 1px solid var(--neutral-700);
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
}

.home-feature-wrapper._5 {
  border-right-width: 0;
  border-radius: 13px;
}

.home-feature-number {
  color: var(--neutral-100);
  margin-bottom: 16px;
  font-size: 68px;
  font-weight: 700;
  line-height: 1.029em;
}

.home-feature-text {
  color: var(--neutral-400);
  white-space: pre-line;
  line-height: 1.111em;
}

.home-course-steps-content {
  z-index: 1;
  max-width: 47%;
  grid-column-gap: 16px;
  grid-row-gap: 22px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  position: relative;
}

.card-home-course-step-content-top {
  align-items: center;
  margin-bottom: 14px;
  display: flex;
}

.card-home-course-step-number {
  width: 39px;
  max-height: 39px;
  min-height: 39px;
  min-width: 39px;
  background-color: var(--primary-main);
  box-shadow: 0 6px 16px 0 var(--shadow-long-button-primary);
  color: var(--neutral-100);
  border-radius: 100000px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2em;
  display: flex;
  overflow: hidden;
}

.content-top {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.content-top.home-topics {
  z-index: 1;
  margin-bottom: 32px;
  position: relative;
}

.content-top.experience {
  z-index: 1;
  margin-bottom: 54px;
  position: relative;
}

.content-top.other-courses {
  margin-bottom: 47px;
}

.content-top.blog {
  margin-bottom: 67px;
}

.content-top.latest-articles {
  margin-bottom: 47px;
}

.content-top.blog-category {
  flex-direction: column;
  margin-bottom: 67px;
}

.content-top.course-content-topic {
  z-index: 1;
  margin-bottom: 32px;
  position: relative;
}

.home-topics-grid {
  z-index: 1;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 56px;
  position: relative;
}

.card-home-topic-icon {
  width: 136px;
  max-height: 136px;
  min-height: 136px;
  min-width: 136px;
  border-radius: 1000px;
  margin-bottom: 32px;
}

.container-medium-728px {
  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-medium-728px.testimonials {
  z-index: 1;
  text-align: center;
  margin-bottom: 56px;
  position: relative;
}

.container-large-1048px {
  max-width: 1048px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-large-1048px.testimonials {
  max-height: 100%;
  position: relative;
}

.slide-nav {
  height: 100%;
  max-width: 50%;
  min-height: 0%;
  justify-content: space-between;
  margin-top: 50px;
  font-size: 10px;
  line-height: 1em;
  display: flex;
  position: static;
}

.testimonials-slider {
  z-index: 1;
  height: 0%;
  max-height: 0%;
  min-height: 0%;
  background-color: rgba(0, 0, 0, 0);
  display: block;
}

.testimonials-slider-left-arrow, .testimonials-slider-right-arrow {
  display: none;
}

.testimonials-mask {
  padding-bottom: 0;
  overflow: visible;
}

.card-testimonial-content {
  max-width: 100%;
  margin-bottom: 0;
}

.card-testimonial-content-bottom {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  display: flex;
}

.card-testimonial-name-wrapper {
  flex: 1;
}

.card-testimonial-name {
  color: var(--neutral-800);
  margin-bottom: 1px;
  font-weight: 700;
  line-height: 1.556em;
}

.card-testimonial-rol {
  color: var(--primary-main);
  line-height: 1.333em;
  display: none;
}

.container-medium-661px {
  max-width: 661px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-medium-661px.faqs {
  z-index: 1;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}

.faqs-wrapper {
  justify-content: space-between;
  margin-bottom: 56px;
  display: flex;
  position: relative;
}

.faqs-wrapper.contact {
  margin-bottom: 0;
}

.faq-content-top {
  align-items: center;
  display: flex;
}

.faq-content-bottom {
  margin-left: 62px;
}

.faq-icon-wrapper {
  width: 35px;
  max-height: 35px;
  min-height: 35px;
  min-width: 35px;
  background-color: #f1f5f4;
  border-radius: 1000000px;
  justify-content: center;
  align-items: center;
  margin-right: 27px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.space.faq {
  min-height: 17px;
}

.faq-icon-1 {
  width: 14px;
  min-height: 2px;
  background-color: #9ba8a5;
  position: absolute;
}

.faq-icon-2 {
  width: 2px;
  min-height: 14px;
  background-color: #9ba8a5;
  position: absolute;
}

.cta-wrapper {
  background-color: var(--neutral-800);
  justify-content: space-between;
  align-items: center;
  padding: 80px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.shape {
  position: absolute;
}

.shape.home-hero-1 {
  bottom: -51px;
  left: -51px;
}

.shape.home-hero-2 {
  right: -46px;
}

.shape.home-hero-3 {
  top: -78px;
}

.shape.home-about-1 {
  bottom: -47px;
  left: 58px;
}

.shape.home-about-2 {
  top: -99px;
  left: -107px;
}

.shape.home-features-1 {
  width: 46%;
  border: 1px solid var(--neutral-700);
  border-radius: 1000000px;
  padding-top: 46%;
  bottom: -461.891px;
  left: -328px;
}

.shape.home-features-2 {
  width: 46%;
  border: 1px solid var(--neutral-700);
  border-radius: 1000000px;
  padding-top: 46%;
  top: -466px;
  right: -332px;
}

.shape.home-course-steps-1 {
  bottom: -34px;
  left: 3px;
}

.shape.home-course-steps-2 {
  bottom: -49px;
  right: -49px;
}

.shape.home-course-steps-3 {
  top: -66px;
  left: -181px;
}

.shape.home-topics-1 {
  top: 235px;
  left: -21px;
}

.shape.home-topics-2 {
  bottom: 79px;
  right: -19px;
}

.shape.testimonials-1 {
  bottom: 37px;
  left: -24px;
}

.shape.testimonials-2 {
  top: -46px;
  right: -19px;
}

.shape.testimonials-3 {
  width: 42%;
  border: 1px solid var(--neutral-700);
  border-radius: 1000px;
  padding-top: 42%;
  bottom: -50px;
  left: -162px;
}

.shape.testimonials-4 {
  width: 42%;
  border: 1px solid var(--neutral-700);
  border-radius: 1000px;
  padding-top: 42%;
  top: -160px;
  right: -185px;
}

.shape.home-faqs-1 {
  width: 39%;
  border: 1px solid var(--neutral-500);
  border-radius: 1000px;
  padding-top: 39%;
  bottom: -113px;
  left: -292px;
}

.shape.home-faqs-2 {
  width: 49%;
  border: 1px solid var(--neutral-500);
  border-radius: 1000px;
  padding-top: 49%;
  top: -153px;
  right: -250px;
}

.shape.cta-1 {
  width: 43%;
  border: 1px solid var(--neutral-700);
  border-radius: 10000px;
  padding-top: 43%;
  top: -317.203px;
  left: -36px;
}

.shape.cta-2 {
  width: 43%;
  border: 1px solid var(--neutral-700);
  border-radius: 10000px;
  padding-top: 43%;
  bottom: -296.203px;
  right: -53px;
}

.shape.about-hero-1 {
  top: -73px;
  left: 79.547px;
}

.shape.about-hero-2 {
  bottom: -44px;
}

.shape.about-hero-3 {
  top: -45px;
  right: -3%;
}

.shape.about-hero-4 {
  width: 41%;
  border: 1px solid var(--neutral-400);
  border-radius: 100000px;
  padding-top: 41%;
  bottom: -84.266px;
  right: -357px;
}

.shape.story-1 {
  width: 28%;
  bottom: -106.25px;
}

.shape.story-2 {
  top: -72px;
  left: 67px;
}

.shape.story-3 {
  bottom: -75px;
  right: 45px;
}

.shape.values-1 {
  bottom: -45px;
  left: -45px;
}

.shape.values-2 {
  top: -45px;
  right: -45px;
}

.shape.experience-1 {
  top: -38px;
  left: 463px;
}

.shape.experience-2 {
  bottom: -69px;
  right: 50px;
}

.shape.about-testimonials-1 {
  bottom: -45px;
  left: -45px;
}

.shape.about-testimonials-2 {
  top: -45px;
  right: -45px;
}

.shape.courses-hero {
  top: -98px;
  right: -139px;
}

.shape.courses-1 {
  width: 49%;
  border: 1px solid var(--neutral-400);
  border-radius: 100000px;
  padding-top: 49%;
  bottom: -110px;
  left: -271px;
}

.shape.courses-2 {
  width: 49%;
  border: 1px solid var(--neutral-400);
  border-radius: 100000px;
  padding-top: 49%;
  top: -179px;
  right: -148px;
}

.shape.course-hero-1 {
  top: -44px;
  left: 38px;
}

.shape.course-hero-2 {
  bottom: -82px;
  right: -97px;
}

.shape.course-reviews-1 {
  top: -40px;
  right: -40px;
}

.shape.course-reviews-2 {
  bottom: -40px;
  right: -40px;
}

.shape.course-videos-hero-1 {
  bottom: -42px;
  left: 197px;
}

.shape.course-videos-hero-2 {
  top: -45px;
  right: -45px;
}

.shape.contact-1 {
  bottom: -122px;
  right: 154px;
}

.shape.contact-2 {
  z-index: -2;
  bottom: 48px;
  left: -51px;
}

.shape.contact-3 {
  top: -38px;
  right: -38px;
}

.shape.direct-contact-links-1 {
  top: -40px;
  left: -40px;
}

.shape.direct-contact-links-2 {
  bottom: -40px;
  right: -40px;
}

.shape.changelog-1 {
  bottom: -40px;
  left: -40px;
}

.shape.changelog-2 {
  top: -40px;
  right: -40px;
}

.shape.password-1 {
  bottom: 45.832px;
  left: 301.642px;
}

.shape.password-2 {
  top: 36.355px;
  right: 302.915px;
}

.shape.not-found-1 {
  bottom: 124px;
  left: 38px;
}

.shape.not-found-2 {
  bottom: 40px;
  right: 67px;
}

.shape.not-found-3 {
  top: -101.497px;
  left: -120px;
}

.shape.not-found-4 {
  top: 65.5px;
  right: -120px;
}

.shape.home-hero-3_2 {
  z-index: -1;
  top: 135px;
  left: 603.531px;
}

.shape.home-hero-1_2 {
  bottom: -51px;
  left: -51px;
}

.shape.home-hero-shape {
  z-index: -10;
  top: 215px;
  left: 688px;
}

.footer-small-print {
  text-align: center;
  font-size: 16px;
  line-height: 1.75em;
}

.footer-content-top {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  display: flex;
}

.footer-content {
  justify-content: space-between;
  margin-bottom: 100px;
  display: flex;
}

.footer-menu {
  width: 100%;
  max-width: 95%;
  flex: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 0;
  padding-left: 16px;
  display: flex;
}

.footer-newsletter-wrapper {
  max-width: 479px;
  min-width: 395px;
}

.footer-menu-wrapper {
  width: 100%;
  max-width: 375px;
  margin-right: 20px;
}

.footer-menu-wrapper.last {
  width: auto;
  max-width: none;
  margin-right: 0;
}

.footer-menu-content {
  justify-content: space-between;
  display: flex;
}

.footer-nav-link {
  color: var(--neutral-600);
  white-space: nowrap;
  line-height: 1.125em;
  text-decoration: none;
  transition: transform .3s, color .3s;
  display: inline-block;
}

.footer-nav-link:hover {
  color: var(--primary-main);
  transform: translate(4px);
}

.footer-nav-link.link-badge {
  max-height: 21px;
  align-items: center;
  display: flex;
}

.footer-nav-link.special {
  border-bottom: 3px solid var(--primary-main);
  color: var(--primary-main);
  padding-bottom: 6px;
  font-weight: 700;
  transition: border-color .3s, transform .3s, color .3s;
}

.footer-nav-link.special:hover {
  border-bottom-color: var(--neutral-800);
  color: var(--neutral-800);
}

.link-badge-text {
  background-color: var(--primary-main);
  color: var(--neutral-100);
  margin-left: 10px;
  padding: 8px 14px;
  font-size: 12px;
  line-height: 1.167em;
}

.footer-social-media-wrapper {
  width: 100%;
  max-width: 397px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -24px;
  margin-right: -10px;
  display: flex;
}

.footer-social-media-link {
  color: var(--neutral-500);
  margin-bottom: 24px;
  margin-right: 24px;
  font-family: Icons Course X Template, sans-serif;
  font-size: 26px;
  line-height: 1em;
  text-decoration: none;
}

.footer-social-media-link:hover {
  color: var(--primary-main);
}

.footer-social-media-link.icon-size-22px {
  font-size: 22px;
}

.footer-social-media-link.icon-size-25px {
  font-size: 25px;
}

.footer-social-media-link.icon-size-24px {
  font-size: 24px;
}

.footer-social-media-link.icon-size-28px {
  font-size: 28px;
}

.footer-newsletter-form-block {
  margin-bottom: 0;
}

.footer-newsletter-form {
  align-items: center;
  display: flex;
  position: relative;
}

.brand {
  color: var(--primary-main);
  padding-left: 0;
  transition-property: transform;
}

.brand:hover {
  color: var(--primary-dark);
  transform: scale(.97);
}

.accent-color-primary-1 {
  color: var(--primary-main);
}

.nav-link-dropdown {
  display: block;
}

.dropdown-nav-link {
  max-height: 21px;
  color: var(--neutral-600);
  white-space: nowrap;
  font-size: 16px;
  text-decoration: none;
  transition: transform .3s, color .3s;
  display: inline-block;
}

.dropdown-nav-link:hover {
  color: var(--primary-main);
  transform: translate(4px);
}

.dropdown-nav-link.link-badge {
  align-items: center;
  display: flex;
}

.dropdown-nav-link.special {
  max-height: none;
  border-bottom: 3px solid var(--primary-main);
  color: var(--primary-main);
  padding-bottom: 6px;
  font-weight: 700;
  transition: border-color .3s, transform .3s, color .3s;
}

.dropdown-nav-link.special:hover {
  border-bottom-color: var(--neutral-800);
  color: var(--neutral-800);
}

.dropdown-nav-content {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.dropdown-arrow {
  font-size: 6.5px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.dropdown-nav-main-wrapper {
  width: 100%;
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-100);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  border-radius: 12px;
  justify-content: space-between;
  padding: 20px 20px 20px 40px;
  display: flex;
}

.dropdown-nav-item {
  margin-bottom: 26px;
  line-height: 1.125em;
}

.dropdown-nav-item.browse-button {
  display: none;
}

.dropdown-nav {
  margin-top: 20px;
  margin-bottom: 0;
  margin-right: 0;
  padding-left: 0;
  list-style-type: none;
}

.dropdown-nav.last {
  margin-right: 0;
}

.dropdown-nav-pages-wrapper {
  width: 100%;
  margin-right: 40px;
}

.dropdown-nav-pages-wrapper.last {
  margin-right: 0;
}

.dropdown-nav-pages-wrapper.last.second-column {
  display: none;
}

.dropdown-list {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 30px;
  right: -159px;
}

.dropdown-list.w--open {
  right: -106px;
}

.dropdown-list.courses {
  right: -322px;
}

.dropdown-list.courses.w--open {
  right: -353px;
}

.success-message {
  background-color: var(--primary-main);
  box-shadow: 0 12px 35px 0 var(--shadow-long-button-primary);
  color: var(--neutral-100);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
}

.error-message {
  color: var(--neutral-100);
  text-align: center;
  background-color: #e63e52;
  align-self: stretch;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 11px 35px rgba(149, 7, 52, .21);
}

.no-wrap {
  white-space: nowrap;
}

.cart-wrapper {
  z-index: 999;
  background-color: rgba(18, 20, 19, .85);
}

.cart-close-button {
  justify-content: center;
  align-items: center;
  transition-property: transform;
  display: flex;
}

.cart-close-button:hover {
  transform: scale(1.2);
}

.cart-header {
  border-bottom-color: var(--neutral-400);
  padding-top: 20px;
  padding-bottom: 20px;
}

.about-hero-wrapper {
  justify-content: space-between;
  align-items: center;
  display: block;
  position: relative;
}

.about-hero-features-grid {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  margin-bottom: 48px;
}

.about-hero-feature-wrapper {
  color: var(--neutral-800);
  align-items: center;
  line-height: 1.111em;
  display: flex;
}

.story-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.story-left-content-top {
  margin-bottom: 156px;
}

.story-left-content-bottom {
  position: relative;
}

.story-right-content-top {
  margin-bottom: 171px;
  position: relative;
}

.story-right-content-bottom {
  padding-left: 84px;
}

.values-grid {
  z-index: 1;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
}

.card-value-icon {
  width: 136px;
  max-height: 136px;
  min-height: 136px;
  min-width: 136px;
  border-radius: 1000px;
  margin-bottom: 32px;
}

.experience-slide-nav {
  display: none;
}

.experience-left-arrow {
  width: 60px;
  max-height: 60px;
  min-height: 60px;
  min-width: 60px;
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-100);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  color: var(--neutral-800);
  border-radius: 100000px;
  font-size: 28px;
  transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s, transform .3s;
  top: auto;
  bottom: auto;
  left: -20px;
  right: auto;
}

.experience-left-arrow:hover {
  border-color: var(--primary-main);
  background-color: var(--primary-main);
  box-shadow: 0 2px 4px 0 var(--shadow-long-card);
  color: var(--neutral-100);
  transform: scale(.97);
}

.experience-slider {
  z-index: 1;
  height: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0);
  align-self: stretch;
  align-items: center;
  display: flex;
}

.experience-mask {
  max-width: 414px;
  overflow: visible;
}

.experience-slide {
  margin-right: 26px;
}

.card-experience-content-top {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.card-experience-grid {
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  margin-bottom: 48px;
}

.card-experience-rol {
  color: var(--primary-main);
  line-height: 1.333em;
}

.card-experience-list-wrapper {
  color: var(--neutral-800);
  align-items: flex-start;
  line-height: 1.111em;
  display: flex;
}

.card-experience-list-dot {
  width: 8px;
  max-height: 8px;
  min-height: 8px;
  min-width: 8px;
  background-color: var(--primary-main);
  border-radius: 10000px;
  margin-right: 9px;
  position: relative;
  top: 6px;
}

.card-experience-date {
  line-height: 1.111em;
}

.container-medium-795px {
  max-width: 795px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-medium-795px.about-testimonials {
  z-index: 1;
  text-align: center;
  margin-bottom: 44px;
  position: relative;
}

.about-testimonials-wrapper {
  margin-bottom: 48px;
  position: relative;
}

.about-testimonials-grid {
  z-index: 1;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-rows: auto;
  position: relative;
}

.card-about-testimonial-content-bottom {
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: -25px;
  display: flex;
}

.card-about-testimonial-name {
  color: var(--neutral-800);
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1em;
}

.card-about-testimonial-rol {
  color: var(--primary-main);
  line-height: 1.111em;
}

.values-wrapper {
  margin-bottom: 56px;
  position: relative;
}

.experience-right-arrow {
  width: 60px;
  max-height: 60px;
  min-height: 60px;
  min-width: 60px;
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-100);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  color: var(--neutral-800);
  border-radius: 100000px;
  font-size: 28px;
  transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s, transform .3s;
  top: auto;
  bottom: auto;
  left: auto;
  right: -20px;
}

.experience-right-arrow:hover {
  border-color: var(--primary-main);
  background-color: var(--primary-main);
  box-shadow: 0 2px 4px 0 var(--shadow-long-card);
  color: var(--neutral-100);
  transform: scale(.97);
}

.experience-slider-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.container-medium-671px {
  max-width: 671px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-medium-671px.courses-hero {
  z-index: 1;
  max-width: 70%;
  text-align: center;
  margin-bottom: 57px;
  position: relative;
}

.course-featured-item {
  z-index: 1;
  position: relative;
}

.card-course-featured-content {
  flex: 1;
  align-self: center;
  padding: 70px 56px;
}

.card-course-featured-price {
  color: var(--primary-main);
  margin-bottom: 2px;
  font-weight: 700;
  line-height: 1.556em;
}

.card-course-featured-content-bottom {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.card-course-featured-badge {
  background-color: var(--neutral-100);
  color: var(--neutral-800);
  text-align: center;
  border: 1px solid #000;
  padding: 18px 26px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
}

.link-underline {
  color: var(--primary-main);
  text-align: center;
  margin-top: auto;
  font-weight: 700;
  line-height: 1.111em;
  overflow: hidden;
}

.link-underline.card-course-featured {
  margin-top: 0;
  text-decoration: none;
}

.link-underline.weight-normal {
  font-weight: 400;
}

.underline {
  width: 100%;
  min-height: 2px;
  background-color: var(--primary-main);
  pointer-events: auto;
  margin-top: 4px;
}

.underline.height-1px {
  min-height: 1px;
}

.courses-grid {
  z-index: 1;
  grid-column-gap: 26px;
  grid-row-gap: 50px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  position: relative;
}

.card-course-content {
  flex-direction: column;
  flex: 1;
  padding: 40px 32px 56px;
  display: flex;
}

.card-course-content-bottom {
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  display: flex;
}

.card-course-level {
  color: var(--neutral-800);
  align-items: center;
  line-height: 1.111em;
  display: flex;
}

.card-course-price {
  color: var(--primary-main);
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.556em;
}

.course-featured-wrapper, .courses-wrapper {
  position: relative;
}

.empty-state {
  z-index: 1;
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-100);
  box-shadow: 0 20px 48px 0 var(--shadow-long-card);
  color: var(--neutral-700);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 40px 25px;
  position: relative;
}

.empty-state.small {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
}

.empty-state.cart-empty {
  box-shadow: none;
  border-width: 0;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
}

.course-hero-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.video-preview-filter {
  background-color: var(--neutral-800);
  opacity: .66;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.video-preview-button {
  width: 22%;
  max-height: 39%;
  min-height: 39%;
  min-width: 22%;
  background-color: var(--neutral-100);
  border-radius: 100000px;
  justify-content: center;
  align-items: center;
  transition: box-shadow .3s, transform .3s;
  display: flex;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 34px 84px rgba(0, 0, 0, .65);
}

.video-preview-button:hover {
  transform: scale(.95);
  box-shadow: 0 3px 18px rgba(0, 0, 0, .65);
}

.course-wrapper {
  justify-content: space-between;
  display: flex;
}

.course-tabs-menu {
  margin-bottom: 70px;
  display: flex;
}

.course-tab-link {
  border-bottom: 1px solid var(--neutral-500);
  color: var(--neutral-600);
  text-align: center;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  flex: 1;
  padding: 0 20px 13px;
  transition: border-color .3s, border-width .3s, color .3s;
}

.course-tab-link:hover {
  color: var(--primary-main);
}

.course-tab-link.w--current {
  border-bottom-width: 3px;
  border-bottom-color: var(--primary-main);
  color: var(--primary-main);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 700;
}

.course-tabs-content {
  overflow: visible;
}

.course-content-rich-text ul {
  margin-bottom: 0;
  margin-right: 86px;
  padding-left: 20px;
  display: inline-block;
}

.course-content-rich-text p {
  margin-bottom: 18px;
  font-size: 16px;
}

.course-reviews-wrapper {
  max-width: 634px;
  position: relative;
}

.course-reviews-grid {
  z-index: 1;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  position: relative;
}

.add-cart-option-list {
  margin-bottom: 19px;
}

.add-cart-default-state {
  margin-bottom: 0;
}

.course-price-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 33px;
  display: flex;
}

.course-price {
  color: var(--neutral-800);
  margin-right: 16px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.36em;
}

.course-compare-price {
  color: var(--neutral-500);
  font-size: 25px;
  line-height: 1.36em;
  text-decoration: line-through;
}

.add-cart-buttons-wrapper {
  flex-direction: column;
  display: flex;
}

.other-courses-grid {
  z-index: 1;
  grid-column-gap: 26px;
  grid-row-gap: 50px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  position: relative;
}

.select-wrapper {
  min-height: 68px;
  background-color: var(--neutral-200);
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 100000px;
  margin-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
  transition: box-shadow .3s, border-color .3s, background-color .3s;
}

.select-wrapper:hover, .select-wrapper:focus {
  border-color: var(--primary-main);
  background-color: var(--neutral-100);
  box-shadow: 0 4px 20px rgba(27, 27, 27, .05);
}

.select-wrapper::-ms-input-placeholder {
  color: var(--neutral-600);
  font-size: 18px;
}

.select-wrapper::placeholder {
  color: var(--neutral-600);
  font-size: 18px;
}

.select-wrapper.checkout {
  min-height: 60px;
  margin-bottom: 16px;
}

.select {
  min-height: 68px;
  color: var(--neutral-600);
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid #000;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  line-height: 1.111em;
  transition: color .3s;
}

.select:hover, .select:focus {
  color: var(--neutral-800);
}

.select::-ms-input-placeholder {
  color: var(--neutral-600);
  font-size: 18px;
}

.select::placeholder {
  color: var(--neutral-600);
  font-size: 18px;
}

.select.checkout {
  min-height: 60px;
}

.course-features-grid {
  width: 100%;
  max-width: 100%;
  grid-column-gap: 18px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.course-feature-wrapper {
  display: flex;
}

.course-feature-content {
  flex-wrap: wrap;
  line-height: 1.111em;
  display: flex;
}

.course-feature-result {
  color: var(--neutral-800);
  font-size: 16px;
  font-weight: 700;
}

.duration-wrapper {
  display: flex;
}

.container-medium-888px {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.course-videos-tabs-menu {
  text-align: center;
  margin-bottom: 90px;
  display: flex;
}

.course-videos-tab-link {
  border-bottom: 1px solid var(--neutral-500);
  color: var(--neutral-600);
  text-align: center;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  flex: 1;
  padding: 0 20px 13px;
  transition: border-color .3s, border-width .3s, color .3s;
}

.course-videos-tab-link:hover {
  color: var(--primary-main);
}

.course-videos-tab-link.w--current {
  border-bottom-width: 3px;
  border-bottom-color: var(--primary-main);
  color: var(--primary-main);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 700;
}

.course-videos-library-hero-wrapper {
  justify-content: space-between;
  align-items: center;
  display: block;
}

.course-videos-hero-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.course-videos-hero-course-features-grid {
  max-width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
}

.course-videos-tab-pane-wrapper {
  max-width: 744px;
  margin-left: auto;
  margin-right: auto;
}

.course-videos-title-wrapper {
  max-width: 500px;
  text-align: center;
  margin-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
}

.course-videos-grid {
  grid-row-gap: 34px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.card-course-video-button {
  width: 64px;
  max-height: 64px;
  min-height: 64px;
  min-width: 64px;
  background-color: var(--primary-main);
  border-radius: 10000000px;
  justify-content: center;
  align-items: center;
  margin-right: 31px;
  display: flex;
  overflow: hidden;
}

.card-course-video-content {
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.card-course-video-duration {
  color: var(--neutral-800);
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
}

.course-videos-resources-grid {
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.card-course-videos-resource-icon-wrapper {
  width: 78px;
  max-height: 78px;
  min-height: 78px;
  min-width: 78px;
  background-color: var(--primary-main);
  border-radius: 1000000px;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  display: flex;
  overflow: hidden;
}

.card-course-videos-resource-content {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.card-course-videos-community-icon-wrapper {
  width: 64px;
  max-height: 64px;
  min-height: 64px;
  min-width: 64px;
  background-color: var(--primary-main);
  border-radius: 1000000px;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  display: flex;
  overflow: hidden;
}

.card-course-videos-community-grid {
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.card-course-videos-community-content {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.blog-hero-grid {
  grid-column-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: minmax(auto, 633px) minmax(auto, 593px);
  justify-content: space-between;
}

.blog-hero-popular-wrapper {
  width: 100%;
  max-width: 593px;
}

.post-featured-wrapper {
  width: 100%;
  max-width: 633px;
  color: var(--neutral-600);
  text-decoration: none;
  transition-property: transform;
  display: block;
}

.post-featured-wrapper:hover {
  color: var(--neutral-600);
  transform: translate(0, -8px);
}

.post-featured-content {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.post-content-top {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.post-content-top-divider {
  width: 32px;
  min-height: 1px;
  background-color: var(--neutral-500);
  margin-left: 16px;
  margin-right: 16px;
}

.post-popular-item {
  border-top: 1px solid var(--neutral-400);
  border-bottom: 1px solid var(--neutral-400);
  padding-top: 35px;
  padding-bottom: 35px;
}

.post-popular-item:first-child {
  border-top-width: 0;
  padding-top: 0;
}

.post-popular-item:last-child {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.post-popular-wrapper {
  color: var(--neutral-600);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.post-popular-wrapper:hover {
  color: var(--neutral-600);
}

.blog-grid {
  grid-column-gap: 26px;
  grid-row-gap: 76px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.post-item-wrapper {
  min-height: 100%;
  color: var(--neutral-600);
  flex-direction: column;
  text-decoration: none;
  transition-property: transform;
  display: flex;
}

.post-item-wrapper:hover {
  color: var(--neutral-600);
  transform: translate(0, -10px);
}

.post-item-content {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.categories-wrapper {
  flex-wrap: wrap;
  margin-bottom: -23px;
  margin-right: -23px;
  display: flex;
}

.category-item {
  margin-bottom: 23px;
  margin-right: 23px;
  display: flex;
}

.container-large-1122px {
  max-width: 1122px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container-large-1122px.post {
  text-align: center;
  margin-bottom: 84px;
}

.post-about-wrapper {
  max-width: 798px;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.post-author-link {
  color: var(--neutral-600);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.post-author-link:hover {
  color: var(--neutral-600);
}

.post-author-name {
  color: var(--neutral-800);
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1em;
}

.post-author-rol {
  color: var(--primary-main);
  line-height: 1.111em;
}

.latest-articles-grid {
  grid-column-gap: 26px;
  grid-row-gap: 76px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.card-author-social-media-grid {
  width: 100%;
  max-width: 159px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  align-items: center;
}

.card-author-content-top {
  margin-bottom: 24px;
}

.card-author-rol {
  color: var(--primary-main);
  line-height: 1.111em;
}

.rich-text h2 {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
}

.rich-text p {
  max-width: 780px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}

.rich-text ul {
  max-width: 720px;
  margin: 32px auto 56px;
}

.rich-text figure {
  margin-top: 50px;
  margin-bottom: 82px;
}

.rich-text h3 {
  max-width: 780px;
  margin-top: 56px;
  margin-left: auto;
  margin-right: auto;
}

.rich-text ol {
  max-width: 720px;
  margin: 32px auto 56px;
}

.rich-text blockquote {
  margin-top: 36px;
  margin-bottom: 24px;
  padding: 0;
}

.rich-text h6, .rich-text h4, .rich-text h5 {
  max-width: 780px;
  margin-top: 56px;
  margin-left: auto;
  margin-right: auto;
}

.contact-wrapper {
  height: 100%;
  max-height: 100%;
  justify-content: space-between;
  margin-bottom: 160px;
  display: flex;
}

.contact-links-grid {
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.contact-link {
  color: var(--neutral-800);
  align-items: center;
  font-weight: 700;
  line-height: 1.111em;
  text-decoration: none;
  display: flex;
}

.contact-link:hover {
  color: var(--primary-main);
}

.contact-link-icon {
  width: 42px;
  max-height: 42px;
  min-height: 42px;
  min-width: 42px;
  background-color: var(--primary-main);
  color: var(--neutral-100);
  border-radius: 100000px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  font-family: Icons Course X Template, sans-serif;
  font-size: 17px;
  font-weight: 400;
  display: flex;
  overflow: hidden;
}

.contact-link-icon.icon-size-22px {
  font-family: Icons Course X Template, sans-serif;
  font-size: 22px;
}

.contact-form-block {
  flex: 1;
  margin-bottom: 0;
}

.contact-form {
  grid-column-gap: 19px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.text-area {
  max-height: 200px;
  max-width: 100%;
  min-height: 163px;
  min-width: 100%;
  background-color: var(--neutral-200);
  color: var(--neutral-800);
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  margin-bottom: 0;
  padding: 21px 25px;
  font-size: 18px;
  transition: box-shadow .3s, border-color .3s, background-color .3s, color .3s;
}

.text-area:hover {
  border-color: var(--primary-main);
  background-color: var(--neutral-100);
  box-shadow: 0 4px 20px rgba(27, 27, 27, .05);
}

.text-area:focus {
  border-color: var(--primary-main);
  background-color: var(--neutral-100);
  color: var(--neutral-800);
  box-shadow: 0 4px 20px rgba(27, 27, 27, .05);
}

.text-area::-ms-input-placeholder {
  color: var(--neutral-600);
  font-size: 18px;
}

.text-area::placeholder {
  color: var(--neutral-600);
  font-size: 18px;
}

.direct-contact-links-content {
  position: relative;
}

.direct-contact-links-grid {
  z-index: 1;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
}

.card-direct-contact-link-text {
  color: var(--primary-main);
  margin-bottom: 16px;
}

.bg {
  position: absolute;
}

.bg.contact {
  z-index: -1;
  min-height: 60%;
  background-color: var(--neutral-200);
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.bg.home-hero {
  z-index: -1;
  height: 100%;
  max-height: 40%;
  min-height: auto;
  background-color: var(--neutral-200);
  top: 142px;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.contact-link-text {
  flex: 1;
}

.blog-newsletter-wrapper {
  background-color: var(--neutral-800);
  justify-content: space-between;
  align-items: center;
  padding: 104px 80px;
  display: flex;
}

.blog-newsletter-form-block {
  width: 100%;
  max-width: 558px;
  margin-bottom: 0;
}

.blog-newsletter-form {
  align-items: center;
  display: flex;
  position: relative;
}

.category-title-wrapper {
  flex-wrap: wrap;
  display: flex;
}

.category-title-wrapper.course-levels {
  text-align: center;
  justify-content: center;
  margin-bottom: 56px;
}

.category-title-wrapper.blog-category {
  text-align: center;
  justify-content: center;
  margin-bottom: 50px;
}

.link-white {
  color: var(--neutral-100);
  transition: opacity .3s, color .3s;
}

.link-white:hover {
  color: rgba(255, 255, 255, .7);
}

.changelog-wrapper {
  position: relative;
}

._404-wrapper {
  max-width: 533px;
}

.order-item-option-result {
  color: var(--neutral-800);
}

.children-wrap {
  flex-wrap: wrap;
}

.cart-footer {
  border-top-color: var(--neutral-400);
  padding-top: 20px;
}

.remove-button {
  font-size: 16px;
}

.cart-item {
  justify-content: space-between;
}

.cart-subtotal {
  color: var(--neutral-800);
  font-weight: 700;
}

.cart-line-item {
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.heading-size-h3 {
  color: var(--neutral-800);
  margin-bottom: 16px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.36em;
}

.style---line-icons-grid {
  grid-column-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 20px);
}

.line-rounded-icon {
  font-family: Line Rounded Icons, sans-serif;
  font-weight: 400;
}

.line-square-icon {
  font-family: Line Square Icons, sans-serif;
}

.line-square-icon.top-bar-close-icon {
  cursor: pointer;
  align-items: center;
  padding: 20px 32px;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.line-square-icon.top-bar-close-icon:hover {
  opacity: .55;
}

.filled-icons {
  font-family: Filled Icons, sans-serif;
}

.social-icon-font {
  font-family: Social Icons Font, sans-serif;
  line-height: 21px;
}

.btn-index-cta {
  color: #fff;
  text-align: center;
  transform-style: preserve-3d;
  background-color: #4a3aff;
  border-radius: 48px;
  justify-content: center;
  padding: 26px 38px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  transition: background-color .3s, transform .3s, color .3s;
}

.btn-index-cta:hover {
  color: #fff;
  background-color: #3324d5;
  transform: translate3d(0, -3px, .01px);
}

.btn-index-cta.button-row {
  margin-right: 28px;
}

.btn-index-cta.no-hover:hover {
  background-color: #4a3aff;
  transform: none;
}

.btn-index-cta.white {
  color: #4a3aff;
  background-color: #fff;
}

.btn-index-cta.white:hover {
  color: #4a3aff;
  background-color: #f2f1ff;
}

.btn-index-cta.white.no-hover {
  color: #146ef5;
}

.btn-index-cta.white.no-hover:hover {
  background-color: #fff;
  transform: none;
}

.figma-file-image-wrapper {
  width: 100%;
  max-width: 640px;
}

.card-home-pages-wrapper {
  padding-top: 56px;
  padding-left: 56px;
  padding-right: 56px;
}

.card-sales-home-image {
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.mg-bottom-12px {
  margin-bottom: 12px;
}

.card-index {
  border-style: solid;
  border-width: 1px;
  border-color: var(--neutral-300) #eff0f6 #eff0f6;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 2px 7px rgba(20, 20, 43, .06);
}

.card-index.sales-home-page {
  text-align: center;
  text-decoration: none;
  transition: transform .3s, color .3s;
}

.card-index.sales-home-page:hover {
  transform: scale(.96);
}

.card-index.buy-now {
  background-color: #146ef5;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: transform .3s, color .3s;
  display: flex;
  overflow: hidden;
}

.card-index.buy-now:hover {
  transform: scale(.95);
}

.card-index.buy-now.white {
  background-color: #fff;
}

.card-index.figma-file {
  justify-content: center;
  align-items: stretch;
  margin-top: 32px;
  display: flex;
  overflow: hidden;
}

.card-index.extra-components {
  border-radius: 16px;
  margin-bottom: 36px;
  padding: 32px 24px;
}

.home-pages-top-wrapper {
  justify-content: center;
  display: flex;
  position: static;
}

.image-home-link-wrapper {
  max-width: 400px;
  transform-style: preserve-3d;
  border-radius: 28px;
  flex: none;
  margin-left: 12px;
  margin-right: 12px;
  transition: transform .3s, color .3s;
  overflow: hidden;
  box-shadow: 0 0 18px 4px rgba(0, 0, 0, .08);
}

.image-home-link-wrapper:hover {
  transform: scale3d(.98, .98, 1.01);
}

.figma-file-text-wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
}

.figma-file-text-wrapper.pd-large {
  padding-left: 120px;
  padding-right: 120px;
}

.grid-3-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-2-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.grid-2-columns.suprises-pages-right {
  grid-column-gap: 100px;
  grid-template-columns: 1fr 1.25fr;
  margin-bottom: 124px;
}

.grid-2-columns.suprises-pages-right.last {
  margin-bottom: 0;
}

.grid-2-columns.suprises-pages-left {
  grid-column-gap: 100px;
  grid-template-columns: 1.25fr 1fr;
  margin-bottom: 124px;
}

.grid-2-columns.footer-v3 {
  grid-column-gap: 50px;
  grid-template-columns: auto 1fr;
}

.home-surprises-card {
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0 2px 12px rgba(20, 20, 43, .08);
}

.buttons-row {
  align-items: center;
  display: flex;
}

.buttons-row.center {
  justify-content: center;
}

.mg-bottom-40px {
  margin-bottom: 40px;
}

.mg-bottom-24px {
  margin-bottom: 24px;
}

.image-home-link {
  width: 100%;
  object-fit: cover;
}

.inner-container._680px {
  max-width: 680px;
}

.inner-container._900px {
  max-width: 900px;
}

.inner-container._680px {
  max-width: 680px;
}

.inner-container.center {
  margin-left: auto;
  margin-right: auto;
}

.inner-container._850px {
  max-width: 850px;
}

.position-relative {
  position: relative;
}

.color-accent-1 {
  color: var(--primary-main);
}

.display-4 {
  color: var(--neutral-800);
  font-size: 25px;
  font-weight: 700;
  line-height: 34px;
}

.display-4.mg-bottom-0px {
  margin-bottom: 0;
}

.cta-square-logo {
  border-radius: 22px;
  margin-bottom: 22px;
  box-shadow: 0 8px 28px rgba(20, 20, 43, .1);
}

.cta-square-logo.white {
  border: 1px solid #f7f7fc;
}

.home-pages-cointainer {
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  display: flex;
}

.text-center {
  text-align: center;
}

.text-center.mobile-l {
  text-align: left;
}

.card-sales-home-content {
  border-top: 1px solid var(--neutral-300);
  text-align: center;
  padding: 32px;
}

.color-neutral-100 {
  color: #fff;
}

.mg-bottom-56px {
  margin-bottom: 56px;
}

.cta-image-card {
  width: 1140px;
  max-width: 1140px;
  margin-top: 52px;
  margin-bottom: -23px;
}

.color-neutral-600 {
  color: #6e7191;
}

.flex-vertical {
  flex-direction: column;
  display: flex;
}

.flex-vertical.center {
  align-items: center;
}

.badge-home {
  background-color: var(--primary-main);
  color: var(--neutral-100);
  border-radius: 50px;
  padding: 18px 26px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: 20px;
  bottom: auto;
  left: auto;
  right: 20px;
  box-shadow: 0 2px 12px rgba(20, 20, 43, .08);
}

.heading-size-h2 {
  color: var(--neutral-800);
  font-size: 38px;
  font-weight: 700;
  line-height: 49px;
}

.mg-top-28px {
  margin-top: 28px;
}

.mg-bottom-0px {
  margin-bottom: 0;
}

.elements-wrapper {
  height: 60%;
  text-align: left;
  margin-bottom: 0;
}

.text-right {
  text-align: right;
}

.top-bar-flex {
  justify-content: center;
  align-items: center;
  display: flex;
}

.top-bar-flex.space-between {
  justify-content: space-between;
}

.link-text {
  transition: color .3s;
  display: inline-block;
}

.link-wrapper {
  color: #211f54;
  transition: none;
  display: inline-block;
}

.link-wrapper:hover {
  color: #4a3aff;
}

.link-wrapper.white {
  color: #fff;
}

.link-wrapper.white:hover {
  color: #f2f1ff;
}

.link-wrapper.white.text-bold {
  font-weight: 700;
}

.line-rounded-icon-bar {
  font-family: Line Rounded Icons, sans-serif;
}

.line-rounded-icon-bar.link-icon-right {
  margin-left: 6px;
  display: inline-block;
}

.mg-right-24px {
  margin-right: 24px;
}

.top-bar-padding {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.top-bar-padding.small-padding {
  padding-top: 22px;
  padding-bottom: 22px;
}

.text-200 {
  line-height: 20px;
}

.text-200.list {
  line-height: 24px;
}

.top-bar-wrapper {
  background-color: var(--primary-main);
  color: #fff;
  text-align: center;
  padding-left: 72px;
  padding-right: 72px;
  position: relative;
  overflow: hidden;
}

.logo {
  mix-blend-mode: normal;
  font-family: Onstage, sans-serif;
  font-size: 56px;
  line-height: 56px;
}

.link {
  display: none;
}

.html-embed {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.home-hero-wrapper-2 {
  height: 100%;
  max-height: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  padding-left: 48px;
  padding-right: 48px;
  display: block;
}

.hero-container-companies {
  max-width: 1342px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.hero-container-companies.home-topics {
  position: relative;
}

.hero-container-default {
  max-width: 100%;
  background-color: var(--neutral-100);
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.hero-container-default.home-topics {
  position: relative;
}

.home-hero-wrapper-2-mobile, .home-hero-wrapper-2-mobile-landscape {
  height: 100%;
  max-height: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  padding-left: 48px;
  padding-right: 48px;
  display: block;
}

.block-quote {
  padding: 0;
}

.list-item {
  font-size: 18px;
}

.list-2 {
  flex-direction: column;
  display: flex;
}

.accordian-item, .subject-syllabus {
  width: 100%;
  max-width: 100%;
}

.text-block {
  font-weight: 700;
}

.syllabus-subject {
  width: 100%;
  overflow: hidden;
}

.accordion-toggle {
  height: 80px;
  background-color: var(--neutral-100);
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.accordion-icon {
  margin-left: 20px;
}

.subject-title {
  width: 70%;
  white-space: normal;
  overflow-wrap: normal;
  object-fit: fill;
  flex: none;
  font-size: 20px;
}

.subject-content {
  background-color: #fff;
  padding: 20px;
  display: block;
  position: static;
}

.view-more {
  width: auto;
  text-align: left;
  white-space: normal;
  flex: none;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
}

.course-content-quote {
  margin-bottom: 16px;
}

.course-content-description {
  margin-top: 16px;
  margin-bottom: 16px;
}

.list, .list-3, .list-4, .list-5, .list-6 {
  font-size: 16px;
}

.text-block-2 {
  margin-bottom: 12px;
}

.list-in-card {
  font-size: 16px;
}

.list-item-in-card {
  white-space: normal;
  word-break: normal;
  line-height: 1.667;
}

.list-title-in-card {
  margin-bottom: 16px;
}

.course-deliverance-list {
  flex-direction: column;
  font-size: 16px;
  display: flex;
}

.course-type-accordian {
  width: 100%;
  overflow: hidden;
}

.course-type-content {
  background-color: #fff;
  padding: 20px;
  display: block;
  position: static;
}

.course-type-title {
  width: 70%;
  white-space: normal;
  overflow-wrap: normal;
  object-fit: fill;
  flex: none;
  font-size: 20px;
}

.rich-text-block, .rich-text-block-2, .paragraph-2, .paragraph-3 {
  font-size: 16px;
}

.course-type-features-grid {
  width: 100%;
  max-width: 100%;
  grid-column-gap: 18px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.heading {
  margin-bottom: 20px;
}

.paragraph-4, .rich-text-block-3, .paragraph-5 {
  font-size: 16px;
}

.div-block {
  margin-top: 16px;
}

.about-the-course---mca {
  margin-bottom: 16px;
}

.types-of-mca-courses {
  margin-bottom: 32px;
}

.course-deliverance-styles {
  background-color: var(--neutral-100);
  margin-top: 16px;
  padding-left: 8px;
  padding-right: 0;
}

.course-card-title {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 33px;
  display: block;
}

.course-title-text-box {
  color: var(--neutral-800);
  margin-right: 16px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.36em;
}

.text-block-3 {
  font-size: 14px;
}

.text-block-4 {
  font-size: 16px;
}

.div-block-2 {
  display: flex;
}

.hero-container-colleges {
  max-width: 1342px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  display: flex;
}

.hero-container-colleges.home-topics {
  position: relative;
}

.slide {
  width: 100%;
  max-width: 100%;
  padding-right: 0;
}

.college-logos {
  width: 100%;
  max-width: 100%;
}

.companies-home-hero-wrapper-copy {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.colleges-home-hero-wrapper {
  width: 100%;
  max-width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.colleges-home-hero-text {
  width: 20%;
  max-width: 100%;
  margin-right: 0;
  line-height: 1.111em;
}

.mask {
  width: 100%;
  max-width: 100%;
}

.slide-nav-t8 {
  justify-content: center;
  align-items: center;
  font-size: 10px;
  display: flex;
}

.slider-t8 {
  width: 85%;
  height: 65%;
  max-width: none;
  background-color: rgba(0, 0, 0, 0);
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-around;
  margin-left: 25px;
  margin-right: 0;
  display: flex;
  overflow: hidden;
}

.slider-t8.slider-mobile {
  width: 80%;
}

.quote-t8 {
  color: #777;
  text-align: center;
}

.user-picture-t8 {
  max-width: none;
  float: none;
  clear: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  border-radius: 0;
  margin-top: 0;
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
  position: relative;
  box-shadow: 1px 1px 3px #bbb;
}

.slide-wrapper-t8 {
  max-width: 100%;
  border-radius: 4px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 25px;
  padding: 0;
  display: flex;
  box-shadow: 5px 5px 20px #cecece;
}

.right-arrow-t8 {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  top: auto;
  bottom: 0%;
  left: 200px;
  right: 0%;
}

.user-name-t8 {
  margin-top: 12px;
  font-weight: 700;
}

.mask-t8 {
  width: 150px;
  flex: 0 auto;
  align-self: auto;
  overflow: visible;
}

.left-arrow-t8 {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 200px;
}

.testimonials-wrapper-t8 {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.slide-1 {
  padding-bottom: 0;
  overflow: visible;
}

.image-2, .college-image {
  width: 75%;
  height: 100%;
  max-width: none;
  float: none;
  clear: none;
  display: inline-block;
  position: static;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.chat-block {
  width: 80%;
  background-color: #fff;
  border-radius: 15px;
  padding: 8px 8px 8px 15px;
}

.whatsapp-container {
  z-index: 30;
  width: 75px;
  height: 75px;
  cursor: pointer;
  background-color: rgba(2, 185, 74, .84);
  border: 1px solid rgba(255, 255, 255, .3);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  transition: all .3s cubic-bezier(.45, .182, .111, .989);
  display: flex;
  position: relative;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.whatsapp-container:hover {
  background-color: #02b94a;
  transform: scale(1.05);
}

.whatsapp-chat {
  width: 300px;
  height: 315px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .16);
  border-radius: 20px;
  flex-direction: column;
  margin-bottom: 16px;
  display: flex;
  overflow: hidden;
}

.whatsapp-integration {
  z-index: 30;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 30px;
  left: auto;
  right: 30px;
}

.small-whatsapp-icon {
  margin-right: 10px;
}

.live-chat-text {
  color: #fff;
  text-decoration: none;
}

.live-chat-bottom-text {
  font-size: 12px;
}

.live-chat-bottom-text.block {
  margin-bottom: 5px;
  font-size: 14px;
}

.live-icon-chat {
  margin-right: 0;
}

.live-chat-link {
  height: 25%;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.live-chat-top-text {
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 500;
  display: block;
  position: static;
}

.live-chat-top-text.block {
  color: #b0b0b0;
  margin-bottom: 10px;
  font-size: 12px;
}

.live-icon {
  width: 15px;
  height: 15px;
  background-color: #00b94a;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  bottom: auto;
  left: 5px;
  right: auto;
}

.chat-section {
  height: 50%;
  background-color: #e6ddd4;
  padding: 16px 20px 0;
}

.close-live-chat-container {
  width: 10%;
  height: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.livechat-button {
  width: 80%;
  height: 42px;
  background-color: #02b94a;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.top-section {
  height: 25%;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.whatsapp-icon {
  width: 60%;
  max-width: none;
}

.top-bar-overflow-padding {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.top-bar-overflow-padding.small-padding {
  padding-top: 22px;
  padding-bottom: 22px;
}

.top-bar-overflow-wrapper {
  background-color: var(--neutral-700);
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.elements-overflow-wrapper {
  height: 60%;
  margin-bottom: 0;
  text-decoration: none;
}

.overflow-container {
  max-width: 1342px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.overflow-container.home-topics {
  position: relative;
}

.overflow-text {
  line-height: 20px;
}

.overflow-text.list {
  width: 4800px;
  text-align: left;
  margin-left: 0;
  padding-left: 0;
  font-weight: 400;
  line-height: 24px;
}

.div-block-3 {
  padding-left: 36px;
  padding-right: 36px;
}

.rich-text-block-4, .main-policy {
  margin-bottom: 16px;
}

.privacy-policy-body, .terms-and-conditions-body {
  word-break: keep-all;
  overflow-wrap: anywhere;
  padding-left: 36px;
  padding-right: 36px;
}

.main-tc {
  margin-bottom: 16px;
}

.pdf-link {
  background-color: var(--neutral-100);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  padding-top: 0;
  display: flex;
}

.link-2 {
  padding-left: 24px;
}

.html-embed-2 {
  width: 100%;
}

.pdf-viewer {
  width: 100%;
  height: 75%;
  max-height: 75%;
}

.text-block-5, .pdf-title {
  padding-left: 24px;
}

.top-bar-text {
  line-height: 20px;
}

.top-bar-text.list {
  text-align: center;
  line-height: 24px;
}

.top-bar-join-us-now-link {
  opacity: 1;
  pointer-events: auto;
  color: var(--neutral-100);
  mix-blend-mode: normal;
  font-weight: 700;
  transition-property: none;
}

.enquiry-form {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -20px;
  display: flex;
}

.html-embed-3 {
  width: 100%;
  height: 2930px;
  border: 1px dashed #000;
}

.alert-bar-hyperlink {
  opacity: 1;
  pointer-events: auto;
  color: var(--neutral-100);
  mix-blend-mode: normal;
  font-weight: 700;
  transition-property: none;
}

.alert-bar-hyperlink:hover {
  color: var(--neutral-300);
}

.bold-text {
  color: var(--neutral-100);
  font-weight: 700;
  text-decoration: underline;
}

.notification-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.notification-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.notification-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.notification-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.notification-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.notification-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.notification-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.notification-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.notification-section.bg-neutral-200.courses {
  overflow: hidden;
}

.notification-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.notification-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.notification-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.notification-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.notification-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.notification-section.home-course-steps {
  overflow: hidden;
}

.notification-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.notification-section.about-hero.resources-page {
  padding-top: 24px;
}

.notification-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.notification-section.values, .notification-section.about-testimonials {
  overflow: hidden;
}

.notification-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.notification-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.notification-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.notification-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.notification-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.notification-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.notification-section.course-levels, .notification-section.blog-category {
  padding-top: 60px;
}

.notification-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.notification-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.notification-section.other-courses {
  padding-top: 128px;
}

.notification-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.notification-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.notification-section.notification-bar, .notification-section.notification-bar-tab, .notification-section.notification-bar-landscape, .notification-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.notification-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.notification-section.resources-hero.resources-page {
  padding-top: 24px;
}

.notification-section.tc-hero, .notification-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.alert-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.alert-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.alert-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.alert-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.alert-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.alert-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.alert-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.alert-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.alert-section.bg-neutral-200.courses {
  overflow: hidden;
}

.alert-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.alert-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.alert-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.alert-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.alert-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.alert-section.home-course-steps {
  overflow: hidden;
}

.alert-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.alert-section.about-hero.resources-page {
  padding-top: 24px;
}

.alert-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.alert-section.values, .alert-section.about-testimonials {
  overflow: hidden;
}

.alert-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.alert-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.alert-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.alert-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.alert-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.alert-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.alert-section.course-levels, .alert-section.blog-category {
  padding-top: 60px;
}

.alert-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.alert-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.alert-section.other-courses {
  padding-top: 128px;
}

.alert-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.alert-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.alert-section.notification-bar, .alert-section.notification-bar-tab, .alert-section.notification-bar-landscape, .alert-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.alert-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.alert-section.resources-hero.resources-page {
  padding-top: 24px;
}

.alert-section.tc-hero, .alert-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.home-hero-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.home-hero-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.home-hero-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.home-hero-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.home-hero-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.home-hero-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.home-hero-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.home-hero-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.home-hero-section.bg-neutral-200.courses {
  overflow: hidden;
}

.home-hero-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.home-hero-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.home-hero-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.home-hero-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.home-hero-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.home-hero-section.home-course-steps {
  overflow: hidden;
}

.home-hero-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.home-hero-section.about-hero.resources-page {
  padding-top: 24px;
}

.home-hero-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.home-hero-section.values, .home-hero-section.about-testimonials {
  overflow: hidden;
}

.home-hero-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.home-hero-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.home-hero-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.home-hero-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.home-hero-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.home-hero-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.home-hero-section.course-levels, .home-hero-section.blog-category {
  padding-top: 60px;
}

.home-hero-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.home-hero-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.home-hero-section.other-courses {
  padding-top: 128px;
}

.home-hero-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.home-hero-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.home-hero-section.notification-bar, .home-hero-section.notification-bar-tab, .home-hero-section.notification-bar-landscape, .home-hero-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.home-hero-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.home-hero-section.resources-hero.resources-page {
  padding-top: 24px;
}

.home-hero-section.tc-hero, .home-hero-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.marketing-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.marketing-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.marketing-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.marketing-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.marketing-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.marketing-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.marketing-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.marketing-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.marketing-section.bg-neutral-200.courses {
  overflow: hidden;
}

.marketing-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.marketing-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.marketing-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.marketing-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.marketing-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.marketing-section.home-course-steps {
  overflow: hidden;
}

.marketing-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.marketing-section.about-hero.resources-page {
  padding-top: 24px;
}

.marketing-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.marketing-section.values, .marketing-section.about-testimonials {
  overflow: hidden;
}

.marketing-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.marketing-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.marketing-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.marketing-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.marketing-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.marketing-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.marketing-section.course-levels, .marketing-section.blog-category {
  padding-top: 60px;
}

.marketing-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.marketing-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.marketing-section.other-courses {
  padding-top: 128px;
}

.marketing-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.marketing-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.marketing-section.notification-bar, .marketing-section.notification-bar-tab, .marketing-section.notification-bar-landscape, .marketing-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.marketing-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.marketing-section.resources-hero.resources-page {
  padding-top: 24px;
}

.marketing-section.tc-hero, .marketing-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.courses-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.courses-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.courses-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.courses-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.courses-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.courses-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.courses-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.courses-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.courses-section.bg-neutral-200.courses {
  overflow: hidden;
}

.courses-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.courses-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.courses-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.courses-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.courses-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.courses-section.home-course-steps {
  overflow: hidden;
}

.courses-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.courses-section.about-hero.resources-page {
  padding-top: 24px;
}

.courses-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.courses-section.values, .courses-section.about-testimonials {
  overflow: hidden;
}

.courses-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.courses-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.courses-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.courses-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.courses-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.courses-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.courses-section.course-levels, .courses-section.blog-category {
  padding-top: 60px;
}

.courses-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.courses-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.courses-section.other-courses {
  padding-top: 128px;
}

.courses-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.courses-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.courses-section.notification-bar, .courses-section.notification-bar-tab, .courses-section.notification-bar-landscape, .courses-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.courses-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.courses-section.resources-hero.resources-page {
  padding-top: 24px;
}

.courses-section.tc-hero, .courses-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.features-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.features-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.features-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.features-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.features-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.features-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.features-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.features-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.features-section.bg-neutral-200.courses {
  overflow: hidden;
}

.features-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.features-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.features-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.features-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.features-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.features-section.home-course-steps {
  overflow: hidden;
}

.features-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.features-section.about-hero.resources-page {
  padding-top: 24px;
}

.features-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.features-section.values, .features-section.about-testimonials {
  overflow: hidden;
}

.features-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.features-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.features-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.features-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.features-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.features-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.features-section.course-levels, .features-section.blog-category {
  padding-top: 60px;
}

.features-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.features-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.features-section.other-courses {
  padding-top: 128px;
}

.features-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.features-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.features-section.notification-bar, .features-section.notification-bar-tab, .features-section.notification-bar-landscape, .features-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.features-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.features-section.resources-hero.resources-page {
  padding-top: 24px;
}

.features-section.tc-hero, .features-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.student-testimonials-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.student-testimonials-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.student-testimonials-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-200.courses {
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.student-testimonials-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.student-testimonials-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.student-testimonials-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.student-testimonials-section.home-course-steps {
  overflow: hidden;
}

.student-testimonials-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.student-testimonials-section.about-hero.resources-page {
  padding-top: 24px;
}

.student-testimonials-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.student-testimonials-section.values, .student-testimonials-section.about-testimonials {
  overflow: hidden;
}

.student-testimonials-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.student-testimonials-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.student-testimonials-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.student-testimonials-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.student-testimonials-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.student-testimonials-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.student-testimonials-section.course-levels, .student-testimonials-section.blog-category {
  padding-top: 60px;
}

.student-testimonials-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.student-testimonials-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.student-testimonials-section.other-courses {
  padding-top: 128px;
}

.student-testimonials-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.student-testimonials-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.student-testimonials-section.notification-bar, .student-testimonials-section.notification-bar-tab, .student-testimonials-section.notification-bar-landscape, .student-testimonials-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.student-testimonials-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.student-testimonials-section.resources-hero.resources-page {
  padding-top: 24px;
}

.student-testimonials-section.tc-hero, .student-testimonials-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.cta-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.cta-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.cta-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.cta-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.cta-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.cta-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.cta-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.cta-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.cta-section.bg-neutral-200.courses {
  overflow: hidden;
}

.cta-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.cta-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.cta-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.cta-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.cta-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.cta-section.home-course-steps {
  overflow: hidden;
}

.cta-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.cta-section.about-hero.resources-page {
  padding-top: 24px;
}

.cta-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.cta-section.values, .cta-section.about-testimonials {
  overflow: hidden;
}

.cta-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.cta-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.cta-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.cta-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.cta-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.cta-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.cta-section.course-levels, .cta-section.blog-category {
  padding-top: 60px;
}

.cta-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.cta-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.cta-section.other-courses {
  padding-top: 128px;
}

.cta-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.cta-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.cta-section.notification-bar, .cta-section.notification-bar-tab, .cta-section.notification-bar-landscape, .cta-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.cta-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.cta-section.resources-hero.resources-page {
  padding-top: 24px;
}

.cta-section.tc-hero, .cta-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

.enquiry-section {
  padding-top: 160px;
  padding-bottom: 160px;
}

.enquiry-section.home-hero {
  padding-top: 0;
  padding-bottom: 48px;
  overflow: hidden;
}

.enquiry-section.bg-neutral-200.home-topics {
  padding-top: 142px;
  overflow: hidden;
}

.enquiry-section.bg-neutral-200.home-faqs {
  padding-top: 116px;
  padding-bottom: 126px;
  display: block;
  overflow: hidden;
}

.enquiry-section.bg-neutral-200.home-about {
  background-color: var(--neutral-100);
  overflow: hidden;
}

.enquiry-section.bg-neutral-200.experience {
  display: block;
  overflow: hidden;
}

.enquiry-section.bg-neutral-200.course {
  padding-bottom: 128px;
}

.enquiry-section.bg-neutral-200.course-videos-hero {
  padding-top: 123px;
  padding-bottom: 118px;
  overflow: hidden;
}

.enquiry-section.bg-neutral-200.courses {
  overflow: hidden;
}

.enquiry-section.bg-neutral-200.story {
  padding-top: 76px;
  padding-bottom: 76px;
  overflow: hidden;
}

.enquiry-section.bg-neutral-800.home-features {
  padding-top: 98px;
  padding-bottom: 106px;
  overflow: hidden;
}

.enquiry-section.bg-neutral-800.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.enquiry-section.home-faqs {
  padding-top: 152px;
  padding-bottom: 135px;
}

.enquiry-section.cta-gradient {
  background-image: linear-gradient(to bottom, var(--neutral-200) 50%, var(--neutral-100) 50%);
  padding-top: 96px;
  padding-bottom: 0;
}

.enquiry-section.home-course-steps {
  overflow: hidden;
}

.enquiry-section.about-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.enquiry-section.about-hero.resources-page {
  padding-top: 24px;
}

.enquiry-section.cta {
  padding-top: 0;
  padding-bottom: 0;
}

.enquiry-section.values, .enquiry-section.about-testimonials {
  overflow: hidden;
}

.enquiry-section.courses-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.enquiry-section.course-hero {
  padding-top: 80px;
  padding-bottom: 108px;
  overflow: hidden;
}

.enquiry-section.course-videos {
  padding-top: 80px;
  padding-bottom: 100px;
}

.enquiry-section.blog-hero {
  padding-top: 80px;
  padding-bottom: 144px;
}

.enquiry-section.post {
  padding-top: 80px;
  padding-bottom: 80px;
}

.enquiry-section.contact {
  background-color: var(--neutral-100);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}

.enquiry-section.course-levels, .enquiry-section.blog-category {
  padding-top: 60px;
}

.enquiry-section.utility-page {
  background-color: var(--neutral-200);
  padding-top: 80px;
  padding-bottom: 80px;
}

.enquiry-section.changelog {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.enquiry-section.other-courses {
  padding-top: 128px;
}

.enquiry-section.hero-page {
  background-image: linear-gradient(to top, var(--primary-main) 35%, white 35%);
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

.enquiry-section.cta-home-pages {
  background-image: linear-gradient(to bottom, var(--neutral-200) 40%, white 40%);
  padding-top: 0;
}

.enquiry-section.notification-bar, .enquiry-section.notification-bar-tab, .enquiry-section.notification-bar-landscape, .enquiry-section.overflow-notification-bar {
  height: 60%;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.enquiry-section.resources-hero {
  height: 100%;
  max-height: 100%;
  background-color: var(--neutral-100);
  padding: 80px 16px 128px;
  overflow: hidden;
}

.enquiry-section.resources-hero.resources-page {
  padding-top: 24px;
}

.enquiry-section.tc-hero, .enquiry-section.pp-hero {
  background-color: var(--neutral-100);
  padding-top: 80px;
  padding-bottom: 80px;
}

@media screen and (min-width: 1920px) {
  .section-styleguide {
    padding-top: 86px;
  }

  .section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .section.post {
    padding-top: 100px;
  }

  .section.contact {
    padding-top: 96px;
  }

  .section.course-levels, .section.blog-category {
    padding-top: 80px;
  }

  .section.utility-page, .section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.notification-bar, .section.notification-bar-tab, .section.notification-bar-landscape, .section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .section.tc-hero, .section.pp-hero {
    padding-top: 100px;
  }

  .card.course-featured {
    z-index: 1;
    position: relative;
  }

  .checkout-form {
    padding-top: 100px;
    padding-bottom: 200px;
  }

  .title.dropdown-nav-title {
    margin-bottom: 16px;
  }

  .title.dropdown-nav-title.resources-title {
    display: none;
  }

  .image-wrapper.card-course-featured {
    width: 40%;
  }

  .image.company-home-hero.logo-image {
    height: 100%;
    object-position: 50% 50%;
  }

  .companies-home-hero-text {
    margin-right: 16px;
  }

  .shape.courses-hero {
    z-index: -100;
  }

  .shape.password-1 {
    left: 623.642px;
  }

  .shape.password-2 {
    right: 625.915px;
  }

  .footer-social-media-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .dropdown-nav-main-wrapper {
    border-style: solid;
    border-radius: 8px;
    padding: 20px 20px 0;
  }

  .dropdown-nav-item {
    margin-bottom: 20px;
  }

  .dropdown-nav-pages-wrapper.last.second-column {
    display: none;
  }

  .dropdown-list.w--open {
    border: 0 #e4e7ec;
    border-radius: 0;
    right: -117px;
  }

  .dropdown-list.courses {
    right: -282px;
  }

  .course-featured-item {
    margin-bottom: 64px;
  }

  .image-home-link-wrapper {
    max-width: 560px;
  }

  .colleges-home-hero-text {
    margin-right: 16px;
  }

  .whatsapp-chat {
    display: none;
  }

  .livechat-button {
    transition: all .2s;
  }

  .livechat-button:hover {
    background-color: #000;
    transform: scale(1.05);
  }

  .notification-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .notification-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .notification-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .notification-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .notification-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .notification-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .notification-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .notification-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .notification-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .notification-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .notification-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .notification-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .notification-section.post {
    padding-top: 100px;
  }

  .notification-section.contact {
    padding-top: 96px;
  }

  .notification-section.course-levels, .notification-section.blog-category {
    padding-top: 80px;
  }

  .notification-section.utility-page, .notification-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .notification-section.notification-bar, .notification-section.notification-bar-tab, .notification-section.notification-bar-landscape, .notification-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .notification-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .notification-section.tc-hero, .notification-section.pp-hero {
    padding-top: 100px;
  }

  .alert-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .alert-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .alert-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .alert-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .alert-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .alert-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .alert-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .alert-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .alert-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .alert-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .alert-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .alert-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .alert-section.post {
    padding-top: 100px;
  }

  .alert-section.contact {
    padding-top: 96px;
  }

  .alert-section.course-levels, .alert-section.blog-category {
    padding-top: 80px;
  }

  .alert-section.utility-page, .alert-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .alert-section.notification-bar, .alert-section.notification-bar-tab, .alert-section.notification-bar-landscape, .alert-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .alert-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .alert-section.tc-hero, .alert-section.pp-hero {
    padding-top: 100px;
  }

  .home-hero-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .home-hero-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .home-hero-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .home-hero-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .home-hero-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .home-hero-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .home-hero-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .home-hero-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .home-hero-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .home-hero-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .home-hero-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .home-hero-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .home-hero-section.post {
    padding-top: 100px;
  }

  .home-hero-section.contact {
    padding-top: 96px;
  }

  .home-hero-section.course-levels, .home-hero-section.blog-category {
    padding-top: 80px;
  }

  .home-hero-section.utility-page, .home-hero-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .home-hero-section.notification-bar, .home-hero-section.notification-bar-tab, .home-hero-section.notification-bar-landscape, .home-hero-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .home-hero-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .home-hero-section.tc-hero, .home-hero-section.pp-hero {
    padding-top: 100px;
  }

  .marketing-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .marketing-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .marketing-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .marketing-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .marketing-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .marketing-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .marketing-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .marketing-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .marketing-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .marketing-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .marketing-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .marketing-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .marketing-section.post {
    padding-top: 100px;
  }

  .marketing-section.contact {
    padding-top: 96px;
  }

  .marketing-section.course-levels, .marketing-section.blog-category {
    padding-top: 80px;
  }

  .marketing-section.utility-page, .marketing-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .marketing-section.notification-bar, .marketing-section.notification-bar-tab, .marketing-section.notification-bar-landscape, .marketing-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .marketing-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .marketing-section.tc-hero, .marketing-section.pp-hero {
    padding-top: 100px;
  }

  .courses-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .courses-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .courses-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .courses-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .courses-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .courses-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .courses-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .courses-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .courses-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .courses-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .courses-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .courses-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .courses-section.post {
    padding-top: 100px;
  }

  .courses-section.contact {
    padding-top: 96px;
  }

  .courses-section.course-levels, .courses-section.blog-category {
    padding-top: 80px;
  }

  .courses-section.utility-page, .courses-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .courses-section.notification-bar, .courses-section.notification-bar-tab, .courses-section.notification-bar-landscape, .courses-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .courses-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .courses-section.tc-hero, .courses-section.pp-hero {
    padding-top: 100px;
  }

  .features-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .features-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .features-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .features-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .features-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .features-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .features-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .features-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .features-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .features-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .features-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .features-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .features-section.post {
    padding-top: 100px;
  }

  .features-section.contact {
    padding-top: 96px;
  }

  .features-section.course-levels, .features-section.blog-category {
    padding-top: 80px;
  }

  .features-section.utility-page, .features-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .features-section.notification-bar, .features-section.notification-bar-tab, .features-section.notification-bar-landscape, .features-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .features-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .features-section.tc-hero, .features-section.pp-hero {
    padding-top: 100px;
  }

  .student-testimonials-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .student-testimonials-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .student-testimonials-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .student-testimonials-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .student-testimonials-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .student-testimonials-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .student-testimonials-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .student-testimonials-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .student-testimonials-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .student-testimonials-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .student-testimonials-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .student-testimonials-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .student-testimonials-section.post {
    padding-top: 100px;
  }

  .student-testimonials-section.contact {
    padding-top: 96px;
  }

  .student-testimonials-section.course-levels, .student-testimonials-section.blog-category {
    padding-top: 80px;
  }

  .student-testimonials-section.utility-page, .student-testimonials-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .student-testimonials-section.notification-bar, .student-testimonials-section.notification-bar-tab, .student-testimonials-section.notification-bar-landscape, .student-testimonials-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .student-testimonials-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .student-testimonials-section.tc-hero, .student-testimonials-section.pp-hero {
    padding-top: 100px;
  }

  .cta-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .cta-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .cta-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .cta-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .cta-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .cta-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .cta-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .cta-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .cta-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .cta-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .cta-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .cta-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .cta-section.post {
    padding-top: 100px;
  }

  .cta-section.contact {
    padding-top: 96px;
  }

  .cta-section.course-levels, .cta-section.blog-category {
    padding-top: 80px;
  }

  .cta-section.utility-page, .cta-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .cta-section.notification-bar, .cta-section.notification-bar-tab, .cta-section.notification-bar-landscape, .cta-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .cta-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .cta-section.tc-hero, .cta-section.pp-hero {
    padding-top: 100px;
  }

  .enquiry-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .enquiry-section.home-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .enquiry-section.bg-neutral-200.home-topics {
    padding-top: 178px;
  }

  .enquiry-section.bg-neutral-200.course-videos-hero {
    padding-top: 154px;
    padding-bottom: 147px;
  }

  .enquiry-section.bg-neutral-800.home-features {
    padding-top: 123px;
    padding-bottom: 133px;
  }

  .enquiry-section.bg-neutral-800.testimonials {
    padding-top: 180px;
    padding-bottom: 190px;
  }

  .enquiry-section.home-faqs {
    padding-top: 190px;
    padding-bottom: 160px;
  }

  .enquiry-section.about-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .enquiry-section.courses-hero {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .enquiry-section.course-hero {
    padding-top: 87px;
    padding-bottom: 135px;
  }

  .enquiry-section.course-videos {
    padding-top: 100px;
    padding-bottom: 121px;
  }

  .enquiry-section.blog-hero {
    padding-top: 100px;
    padding-bottom: 180px;
  }

  .enquiry-section.post {
    padding-top: 100px;
  }

  .enquiry-section.contact {
    padding-top: 96px;
  }

  .enquiry-section.course-levels, .enquiry-section.blog-category {
    padding-top: 80px;
  }

  .enquiry-section.utility-page, .enquiry-section.changelog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .enquiry-section.notification-bar, .enquiry-section.notification-bar-tab, .enquiry-section.notification-bar-landscape, .enquiry-section.overflow-notification-bar {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .enquiry-section.resources-hero {
    padding-top: 100px;
    padding-bottom: 160px;
  }

  .enquiry-section.tc-hero, .enquiry-section.pp-hero {
    padding-top: 100px;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    margin-bottom: 20px;
    font-size: 50px;
  }

  h2 {
    margin-bottom: 20px;
  }

  h3, h4, h5, h6 {
    margin-bottom: 10px;
  }

  blockquote {
    padding: 95px 60px;
    font-size: 25px;
  }

  .style-guide-sidebar {
    display: none;
  }

  .container-default {
    flex-direction: column;
    align-items: stretch;
    padding-left: 36px;
    padding-right: 36px;
  }

  .style-guide-content {
    margin-left: 0;
  }

  .section-styleguide {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .style-guide-header {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .typography-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .style-guide-subheader {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .style-guide-content-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .buttons-grid {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
  }

  .button-primary.large {
    padding-left: 50px;
    padding-right: 50px;
  }

  .button-primary.header-button {
    margin-right: 20px;
  }

  .button-primary.header-button.mobile {
    margin-right: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    display: block;
  }

  .button-secondary.large {
    padding-left: 50px;
    padding-right: 50px;
  }

  .cards-grid {
    grid-row-gap: 64px;
    grid-template-columns: 1fr;
  }

  .header {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .header-navigation {
    flex-direction: column;
    align-items: stretch;
    margin-left: 24px;
    margin-right: 24px;
  }

  .nav-item-wrapper {
    margin-right: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
  }

  .nav-item-wrapper.button-mobile {
    margin-top: 150px;
    display: block;
  }

  .nav-link {
    font-size: 30px;
    transition: transform .3s, color .35s;
  }

  .nav-link:hover {
    transform: translate(4px);
  }

  .menu-button {
    width: 54px;
    max-height: 54px;
    min-height: 54px;
    min-width: 54px;
    background-color: var(--primary-main);
    box-shadow: 0 12px 35px 0 var(--shadow-long-button-primary);
    color: #fff;
    border-radius: 100000px;
    padding: 12px;
    font-size: 30px;
    transition: box-shadow .3s, transform .3s, background-color .35s;
  }

  .menu-button:hover {
    box-shadow: 0 3px 5px 0 var(--shadow-long-button-primary);
    background-color: #068355;
    transform: scale(.97);
  }

  .menu-button.w--open {
    background-color: #068355;
  }

  .nav-menu {
    width: 100%;
    max-width: 100vw;
    border: 1px solid var(--neutral-300);
    background-color: #fff;
    padding-top: 125px;
    padding-bottom: 36px;
    box-shadow: 0 4px 10px rgba(74, 58, 255, .18);
  }

  .footer {
    padding-top: 80px;
  }

  .footer-logo-container, .footer-logo-container.w--current {
    margin-bottom: 30px;
  }

  .footer-logo-container.mg-bottom-0px {
    margin-bottom: 0;
  }

  .section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .section.courses-hero, .section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .section.post, .section.contact, .section.course-levels, .section.blog-category {
    padding-top: 40px;
  }

  .section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .section.other-courses {
    padding-top: 100px;
  }

  .section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .section.notification-bar-tab, .section.notification-bar-landscape, .section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .section.tc-hero, .section.pp-hero {
    padding-top: 40px;
  }

  .utility-page-content-password {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .divider.card-get-course {
    width: 1px;
    min-height: auto;
    align-self: stretch;
    margin: 0 40px;
  }

  .divider.course-videos {
    margin-top: 118px;
    margin-bottom: 118px;
  }

  .paragraph.footer-newsletter {
    max-width: 479px;
  }

  .paragraph.course-reviews {
    margin-bottom: 60px;
  }

  .card.bg-neutral-300.value, .card.home-topic {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .card.testimonial {
    align-items: center;
    padding: 40px 30px 50px;
  }

  .card.experience, .card.about-testimonial {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .card.course-featured {
    flex-direction: column;
  }

  .card.get-course {
    justify-content: space-between;
    align-items: center;
    padding: 47px 43px 62px;
    display: flex;
    position: static;
  }

  .card.author {
    padding: 63px 53px;
  }

  .card.contact {
    min-height: 703px;
    padding-top: 67px;
    padding-bottom: 67px;
  }

  .card.default-v1, .card.default-v2, .card.default-v3 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .checkout-form {
    padding-top: 64px;
    padding-bottom: 128px;
  }

  .checkout-block-header, .checkout-block-content {
    padding-left: 60px;
    padding-right: 60px;
  }

  .title.h3-size.card-course-featured {
    margin-bottom: 10px;
  }

  .title.home-topics {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .title.color-neutral-100.blog-newsletter {
    margin-bottom: 10px;
  }

  .title.card-testimonial {
    max-width: 90%;
  }

  .title.footer-menu-title, .title.dropdown-nav-title {
    margin-bottom: 40px;
  }

  .title.experience {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .title.card-course {
    margin-bottom: 10px;
  }

  .title.course-videos-hero {
    max-width: 648px;
  }

  .title.blog-hero {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .title.blog {
    margin-bottom: 40px;
  }

  .title.direct-contact-links {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .title.contact {
    margin-bottom: 27px;
  }

  .title.h1-size {
    font-size: 50px;
  }

  .title.course-content-title {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .title.resource-title {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .split-content.checkout-left {
    margin-bottom: 40px;
    margin-right: 0;
  }

  .split-content.header-right, .split-content.header-left {
    z-index: 99;
    position: relative;
  }

  .split-content.home-hero-left {
    max-width: 690px;
    min-width: auto;
    margin-bottom: 100px;
    margin-right: 0;
  }

  .split-content.home-hero-right {
    max-width: 690px;
  }

  .split-content.home-about-left {
    max-width: 690px;
    margin-right: 0;
  }

  .split-content.home-about-right {
    max-width: 690px;
    min-width: auto;
    margin-bottom: 100px;
  }

  .split-content.faqs-left {
    max-width: 100%;
    margin-bottom: 24px;
    margin-right: 0;
  }

  .split-content.faqs-right {
    max-width: 100%;
  }

  .split-content.cta-left {
    margin-bottom: 40px;
    margin-right: 0;
  }

  .split-content.header-center {
    margin-left: auto;
    margin-right: 20px;
  }

  .split-content.header-center.right-mg-responsive {
    margin-right: 30px;
  }

  .split-content.about-hero-left {
    max-width: 690px;
    margin-right: 0;
  }

  .split-content.about-hero-right {
    max-width: 690px;
    min-width: auto;
    margin-bottom: 100px;
  }

  .split-content.story-left {
    max-width: 690px;
    margin-top: 0;
    margin-bottom: 100px;
    margin-right: 0;
  }

  .split-content.story-right {
    max-width: 690px;
    flex-direction: column-reverse;
    align-self: auto;
    margin-left: 0;
    display: flex;
  }

  .split-content.course-hero-content {
    max-width: 743px;
    min-width: auto;
    margin-bottom: 100px;
    margin-right: 0;
  }

  .split-content.video-preview-wrapper {
    max-width: 743px;
  }

  .split-content.course-left {
    max-width: 743px;
    margin-left: auto;
    margin-right: auto;
  }

  .split-content.course-right {
    max-width: 100%;
    margin-bottom: 100px;
  }

  .split-content.course-videos-hero-left {
    max-width: 743px;
    min-width: auto;
    margin-bottom: 100px;
    margin-right: 0;
  }

  .split-content.course-videos-hero-right {
    max-width: 743px;
  }

  .split-content.content-top-latest-articles-left {
    margin-bottom: 40px;
    margin-right: 30px;
  }

  .split-content.content-top-latest-articles-right {
    margin-bottom: 40px;
  }

  .split-content.contact-left {
    min-width: auto;
    margin-bottom: 100px;
    margin-right: 0;
  }

  .split-content.contact-right {
    max-width: 100%;
  }

  .split-content.blog-newsletter-content {
    max-width: 462px;
    min-width: auto;
    margin-bottom: 60px;
    margin-right: 0;
  }

  .split-content.comingsoon-header {
    max-width: 743px;
    min-width: auto;
    margin-bottom: 100px;
    margin-right: 0;
  }

  .image-wrapper.home-course-steps {
    max-width: 100%;
    align-self: stretch;
  }

  .image-wrapper.card-course-featured {
    max-width: none;
  }

  .image-wrapper.post {
    margin-bottom: 117px;
  }

  .image-wrapper.card-author {
    margin-right: 28px;
  }

  .image-wrapper.course-image {
    width: 100%;
    height: 320px;
  }

  .image.company-home-hero {
    width: 80%;
  }

  .image.home-course-steps {
    width: 100%;
    max-height: 550px;
  }

  .image.card-testimonial {
    max-width: 230px;
    margin-top: -27px;
  }

  .home-hero-wrapper {
    flex-direction: column;
    margin-bottom: 100px;
  }

  .companies-home-hero-wrapper {
    flex-direction: column;
  }

  .companies-home-hero-text {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .companies-home-hero-grid {
    max-width: 720px;
    justify-items: center;
  }

  .home-about-wrapper {
    flex-direction: column-reverse;
  }

  .container-small-588px.home-course-steps {
    margin-bottom: 40px;
  }

  .home-course-steps-wrapper {
    flex-direction: column-reverse;
  }

  .home-features-wrapper {
    max-width: 720px;
    grid-row-gap: 40px;
    grid-template-columns: auto auto auto auto;
    margin-left: auto;
    margin-right: auto;
  }

  .home-feature-wrapper._3 {
    border-right-width: 0;
  }

  .home-feature-number {
    font-size: 57px;
  }

  .home-course-steps-content {
    max-width: 100%;
    grid-column-gap: 22px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 60px;
  }

  .content-top.home-topics {
    text-align: center;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .content-top.experience {
    text-align: center;
    flex-direction: column;
  }

  .content-top.other-courses {
    max-width: 743px;
    margin-left: auto;
    margin-right: auto;
  }

  .content-top.blog {
    flex-direction: column;
  }

  .content-top.latest-articles {
    max-width: 780px;
    flex-wrap: wrap;
    margin-bottom: 7px;
    margin-left: auto;
    margin-right: auto;
  }

  .content-top.blog-category {
    flex-direction: column;
  }

  .content-top.course-content-topic {
    text-align: center;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .home-topics-grid {
    grid-template-columns: 1fr 1fr;
  }

  .slide-nav {
    margin-top: 40px;
  }

  .card-testimonial-content {
    max-width: 100%;
    margin-bottom: 0;
  }

  .faqs-wrapper {
    flex-direction: column;
  }

  .faqs-wrapper.contact {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .faq-content-bottom {
    margin-left: 50px;
  }

  .faq-icon-wrapper {
    margin-right: 14px;
  }

  .cta-wrapper {
    text-align: center;
    flex-direction: column;
    padding: 64px;
  }

  .shape.home-hero-3 {
    top: -64px;
    left: -130.5px;
  }

  .shape.home-about-1 {
    left: 68px;
  }

  .shape.home-about-2 {
    top: -87px;
    left: auto;
    right: -73px;
  }

  .shape.home-features-1 {
    bottom: -326.891px;
    left: -170px;
  }

  .shape.home-features-2 {
    top: -321px;
    right: -118px;
  }

  .shape.home-course-steps-2 {
    bottom: 433px;
    right: -43px;
  }

  .shape.home-course-steps-3 {
    top: 109px;
    left: -128px;
  }

  .shape.testimonials-3 {
    bottom: -70px;
    left: -131px;
  }

  .shape.testimonials-4 {
    top: -133px;
    right: -117px;
  }

  .shape.home-faqs-1 {
    bottom: 62px;
    left: -154px;
  }

  .shape.home-faqs-2 {
    top: 34px;
    right: -162px;
  }

  .shape.cta-1 {
    top: -225.203px;
  }

  .shape.cta-2 {
    bottom: -205.203px;
  }

  .shape.about-hero-1 {
    top: -53px;
    left: -106.453px;
  }

  .shape.about-hero-4 {
    bottom: -55.266px;
    right: -119px;
  }

  .shape.story-1 {
    display: none;
  }

  .shape.story-2 {
    top: 40px;
    left: -45px;
  }

  .shape.story-3 {
    bottom: -45px;
    right: -45px;
  }

  .shape.courses-hero {
    top: auto;
    bottom: -54px;
    right: -158px;
  }

  .shape.courses-1 {
    bottom: -65px;
    left: -159px;
  }

  .shape.courses-2 {
    top: -104px;
    right: -109px;
  }

  .shape.course-hero-2 {
    bottom: -22px;
  }

  .shape.course-videos-hero-1 {
    left: 85px;
  }

  .shape.contact-1 {
    bottom: -92px;
    right: 45px;
  }

  .shape.password-1 {
    left: 46.642px;
  }

  .shape.password-2 {
    right: 47.915px;
  }

  .shape.not-found-1 {
    left: -63px;
  }

  .shape.not-found-2 {
    bottom: -41px;
  }

  .shape.home-hero-3_2 {
    top: -64px;
    left: -130.5px;
  }

  .footer-content-top {
    flex-direction: column;
    margin-bottom: 64px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
  }

  .footer-menu {
    max-width: 754px;
    margin-bottom: 40px;
    margin-right: 0;
  }

  .footer-menu.tablet-bottom-0px {
    margin-bottom: 0;
  }

  .footer-newsletter-wrapper {
    width: 100%;
    max-width: 754px;
    min-width: auto;
  }

  .footer-newsletter-wrapper.footer-v2 {
    margin-bottom: 40px;
  }

  .nav-link-dropdown {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-nav-content {
    flex-wrap: wrap;
  }

  .dropdown-arrow {
    font-size: 13px;
  }

  .dropdown-nav-main-wrapper {
    box-shadow: none;
    text-align: left;
    background-color: rgba(0, 0, 0, 0);
    border-width: 0;
    border-radius: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
    padding: 40px 0 0;
  }

  .dropdown-nav-pages-wrapper {
    width: auto;
    margin-bottom: 30px;
  }

  .dropdown-nav-pages-wrapper.last {
    width: auto;
  }

  .dropdown-list {
    padding-top: 0;
    position: static;
  }

  .header-menu-button-icon-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .icon-wrapper {
    width: 23px;
    min-height: 20px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .header-menu-button-icon-top, .header-menu-button-icon-medium, .header-menu-button-icon-bottom {
    width: 100%;
    max-height: 2px;
    min-height: 2px;
    background-color: #fff;
    padding: 0;
  }

  .cart {
    z-index: 100;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .about-hero-wrapper {
    flex-direction: column-reverse;
  }

  .story-wrapper {
    flex-direction: column;
  }

  .story-left-content-top {
    margin-bottom: 60px;
  }

  .story-right-content-top {
    margin-bottom: 0;
  }

  .story-right-content-bottom {
    margin-bottom: 60px;
  }

  .values-grid {
    grid-template-columns: 1fr 1fr;
  }

  .experience-slide {
    margin-right: 22px;
  }

  .about-testimonials-grid {
    grid-template-columns: 1fr;
  }

  .card-course-featured-content {
    max-width: none;
    padding: 40px 47px 58px;
  }

  .courses-grid {
    grid-template-columns: 1fr 1fr;
  }

  .course-featured-wrapper {
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
  }

  .course-hero-wrapper {
    flex-direction: column;
  }

  .course-wrapper {
    flex-direction: column-reverse;
  }

  .course-tabs-menu {
    margin-bottom: 60px;
  }

  .course-content-rich-text ul {
    margin-right: 72px;
  }

  .course-content-rich-text p {
    margin-bottom: 31px;
  }

  .card-get-course-content-top {
    min-width: 320px;
    flex: 1;
  }

  .course-price, .course-compare-price {
    font-size: 21px;
  }

  .other-courses-grid {
    max-width: 743px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .course-features-grid {
    flex: 1;
  }

  .course-videos-tabs-menu {
    margin-bottom: 75px;
  }

  .course-videos-hero-wrapper {
    flex-direction: column;
  }

  .blog-hero-grid {
    max-width: 720px;
    grid-row-gap: 100px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-hero-popular-wrapper, .post-featured-wrapper {
    max-width: 100%;
  }

  .blog-grid {
    grid-column-gap: 20px;
    grid-row-gap: 63px;
    grid-template-columns: 1fr 1fr;
  }

  .categories-wrapper {
    justify-content: center;
  }

  .container-large-1122px.post {
    margin-bottom: 70px;
  }

  .latest-articles-grid {
    max-width: 780px;
    grid-column-gap: 20px;
    grid-row-gap: 63px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .rich-text figure, .rich-text blockquote {
    margin-bottom: 70px;
  }

  .contact-wrapper {
    flex-direction: column;
    margin-bottom: 133px;
  }

  .contact-links-grid {
    grid-row-gap: 35px;
  }

  .direct-contact-links-content {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .direct-contact-links-grid {
    grid-template-columns: 1fr;
  }

  .bg.contact, .bg.home-hero {
    min-height: 62%;
  }

  .blog-newsletter-wrapper {
    text-align: center;
    flex-direction: column;
    padding: 87px 60px;
  }

  .category-title-wrapper.blog-category {
    margin-bottom: 40px;
  }

  .line-square-icon.top-bar-close-icon {
    padding: 18px 24px;
  }

  .btn-index-cta {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .figma-file-image-wrapper {
    max-width: 100%;
  }

  .card-index.figma-file {
    flex-direction: column;
    overflow: hidden;
  }

  .card-index.figma-file.reverse-tablet {
    flex-direction: column-reverse;
  }

  .image-home-link-wrapper {
    max-width: 260px;
    border-radius: 20px;
  }

  .figma-file-text-wrapper {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .figma-file-text-wrapper.pd-large {
    padding-left: 58px;
    padding-right: 30px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr 1fr;
  }

  .grid-3-columns._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.suprises-pages-right {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
    margin-bottom: 100px;
  }

  .grid-2-columns._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.suprises-pages-left {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
    margin-bottom: 100px;
  }

  .grid-2-columns.footer-v3 {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .display-4 {
    font-size: 22px;
    line-height: 38px;
  }

  .home-pages-cointainer {
    margin-top: 60px;
  }

  .mg-bottom-56px {
    margin-bottom: 48px;
  }

  .text-right.tablet-center {
    text-align: center;
  }

  .top-bar-flex {
    flex-direction: column;
  }

  .mg-right-24px.mg-bt-16px {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .top-bar-padding {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .top-bar-wrapper {
    padding-left: 56px;
    padding-right: 56px;
  }

  .home-hero-wrapper-2 {
    flex-direction: column;
    margin-bottom: 100px;
  }

  .hero-container-companies, .hero-container-default {
    flex-direction: column;
    align-items: stretch;
  }

  .home-hero-wrapper-2-mobile, .home-hero-wrapper-2-mobile-landscape {
    flex-direction: column;
    margin-bottom: 100px;
  }

  .course-type-features-grid {
    flex: 1;
  }

  .course-title-text-box {
    font-size: 21px;
  }

  .hero-container-colleges {
    flex-direction: column;
    align-items: stretch;
  }

  .companies-home-hero-wrapper-copy, .colleges-home-hero-wrapper {
    flex-direction: column;
  }

  .colleges-home-hero-text {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .whatsapp-integration {
    bottom: 20px;
    right: 20px;
  }

  .top-bar-overflow-padding {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .top-bar-overflow-wrapper {
    padding-left: 56px;
    padding-right: 56px;
  }

  .overflow-container {
    flex-direction: column;
    align-items: stretch;
  }

  .notification-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .notification-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .notification-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .notification-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .notification-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .notification-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .notification-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .notification-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .notification-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .notification-section.courses-hero, .notification-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .notification-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .notification-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .notification-section.post, .notification-section.contact, .notification-section.course-levels, .notification-section.blog-category {
    padding-top: 40px;
  }

  .notification-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .notification-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .notification-section.other-courses {
    padding-top: 100px;
  }

  .notification-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .notification-section.notification-bar-tab, .notification-section.notification-bar-landscape, .notification-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .notification-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .notification-section.tc-hero, .notification-section.pp-hero {
    padding-top: 40px;
  }

  .alert-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .alert-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .alert-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .alert-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .alert-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .alert-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .alert-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .alert-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .alert-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .alert-section.courses-hero, .alert-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .alert-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .alert-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .alert-section.post, .alert-section.contact, .alert-section.course-levels, .alert-section.blog-category {
    padding-top: 40px;
  }

  .alert-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .alert-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .alert-section.other-courses {
    padding-top: 100px;
  }

  .alert-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .alert-section.notification-bar-tab, .alert-section.notification-bar-landscape, .alert-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .alert-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .alert-section.tc-hero, .alert-section.pp-hero {
    padding-top: 40px;
  }

  .home-hero-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .home-hero-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .home-hero-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .home-hero-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .home-hero-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .home-hero-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .home-hero-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .home-hero-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .home-hero-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .home-hero-section.courses-hero, .home-hero-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .home-hero-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .home-hero-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .home-hero-section.post, .home-hero-section.contact, .home-hero-section.course-levels, .home-hero-section.blog-category {
    padding-top: 40px;
  }

  .home-hero-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .home-hero-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .home-hero-section.other-courses {
    padding-top: 100px;
  }

  .home-hero-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .home-hero-section.notification-bar-tab, .home-hero-section.notification-bar-landscape, .home-hero-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .home-hero-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .home-hero-section.tc-hero, .home-hero-section.pp-hero {
    padding-top: 40px;
  }

  .marketing-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .marketing-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .marketing-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .marketing-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .marketing-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .marketing-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .marketing-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .marketing-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .marketing-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .marketing-section.courses-hero, .marketing-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .marketing-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .marketing-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .marketing-section.post, .marketing-section.contact, .marketing-section.course-levels, .marketing-section.blog-category {
    padding-top: 40px;
  }

  .marketing-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .marketing-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .marketing-section.other-courses {
    padding-top: 100px;
  }

  .marketing-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .marketing-section.notification-bar-tab, .marketing-section.notification-bar-landscape, .marketing-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .marketing-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .marketing-section.tc-hero, .marketing-section.pp-hero {
    padding-top: 40px;
  }

  .courses-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .courses-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .courses-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .courses-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .courses-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .courses-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .courses-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .courses-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .courses-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .courses-section.courses-hero, .courses-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .courses-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .courses-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .courses-section.post, .courses-section.contact, .courses-section.course-levels, .courses-section.blog-category {
    padding-top: 40px;
  }

  .courses-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .courses-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .courses-section.other-courses {
    padding-top: 100px;
  }

  .courses-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .courses-section.notification-bar-tab, .courses-section.notification-bar-landscape, .courses-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .courses-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .courses-section.tc-hero, .courses-section.pp-hero {
    padding-top: 40px;
  }

  .features-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .features-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .features-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .features-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .features-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .features-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .features-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .features-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .features-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .features-section.courses-hero, .features-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .features-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .features-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .features-section.post, .features-section.contact, .features-section.course-levels, .features-section.blog-category {
    padding-top: 40px;
  }

  .features-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .features-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .features-section.other-courses {
    padding-top: 100px;
  }

  .features-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .features-section.notification-bar-tab, .features-section.notification-bar-landscape, .features-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .features-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .features-section.tc-hero, .features-section.pp-hero {
    padding-top: 40px;
  }

  .student-testimonials-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .student-testimonials-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .student-testimonials-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .student-testimonials-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .student-testimonials-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .student-testimonials-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .student-testimonials-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .student-testimonials-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .student-testimonials-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .student-testimonials-section.courses-hero, .student-testimonials-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .student-testimonials-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .student-testimonials-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .student-testimonials-section.post, .student-testimonials-section.contact, .student-testimonials-section.course-levels, .student-testimonials-section.blog-category {
    padding-top: 40px;
  }

  .student-testimonials-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .student-testimonials-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .student-testimonials-section.other-courses {
    padding-top: 100px;
  }

  .student-testimonials-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .student-testimonials-section.notification-bar-tab, .student-testimonials-section.notification-bar-landscape, .student-testimonials-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .student-testimonials-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .student-testimonials-section.tc-hero, .student-testimonials-section.pp-hero {
    padding-top: 40px;
  }

  .cta-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .cta-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .cta-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .cta-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .cta-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .cta-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .cta-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .cta-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .cta-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .cta-section.courses-hero, .cta-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .cta-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .cta-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .cta-section.post, .cta-section.contact, .cta-section.course-levels, .cta-section.blog-category {
    padding-top: 40px;
  }

  .cta-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .cta-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .cta-section.other-courses {
    padding-top: 100px;
  }

  .cta-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .cta-section.notification-bar-tab, .cta-section.notification-bar-landscape, .cta-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cta-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .cta-section.tc-hero, .cta-section.pp-hero {
    padding-top: 40px;
  }

  .enquiry-section {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .enquiry-section.home-hero {
    padding-top: 0;
    padding-bottom: 86px;
  }

  .enquiry-section.bg-neutral-200.home-topics {
    padding-top: 114px;
  }

  .enquiry-section.bg-neutral-200.course {
    padding-bottom: 100px;
  }

  .enquiry-section.bg-neutral-200.course-videos-hero {
    padding-top: 60px;
    padding-bottom: 94px;
  }

  .enquiry-section.bg-neutral-800.home-features {
    padding-top: 78px;
    padding-bottom: 85px;
  }

  .enquiry-section.bg-neutral-800.testimonials {
    padding-top: 115px;
    padding-bottom: 122px;
  }

  .enquiry-section.home-faqs {
    padding-top: 122px;
    padding-bottom: 100px;
  }

  .enquiry-section.about-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .enquiry-section.courses-hero, .enquiry-section.course-hero {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .enquiry-section.course-videos {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .enquiry-section.blog-hero {
    padding-top: 40px;
    padding-bottom: 115px;
  }

  .enquiry-section.post, .enquiry-section.contact, .enquiry-section.course-levels, .enquiry-section.blog-category {
    padding-top: 40px;
  }

  .enquiry-section.utility-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .enquiry-section.changelog {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .enquiry-section.other-courses {
    padding-top: 100px;
  }

  .enquiry-section.notification-bar {
    padding-top: 40px;
    padding-bottom: 86px;
  }

  .enquiry-section.notification-bar-tab, .enquiry-section.notification-bar-landscape, .enquiry-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .enquiry-section.resources-hero {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .enquiry-section.tc-hero, .enquiry-section.pp-hero {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    margin-bottom: 15px;
    font-size: 42px;
  }

  h2 {
    margin-bottom: 15px;
    font-size: 32px;
  }

  h3 {
    font-size: 23px;
  }

  h4 {
    font-size: 20px;
  }

  blockquote {
    padding: 79px 40px;
    font-size: 23px;
  }

  figcaption {
    margin-top: 23px;
    font-size: 16px;
    line-height: 1.5em;
  }

  .style-guide-title {
    font-size: 46px;
    line-height: 52px;
  }

  .style-guide-header {
    padding-left: 32px;
    padding-right: 32px;
  }

  .color-primary-grid, .color-neutral-grid {
    grid-template-columns: 1fr 1fr;
  }

  .paragraph-large {
    font-size: 22px;
  }

  .style-guide-subheader, .style-guide-content-wrapper {
    padding-left: 32px;
    padding-right: 32px;
  }

  .icons-grid {
    grid-template-columns: repeat(auto-fit, 80px);
  }

  .button-primary {
    padding-left: 25px;
    padding-right: 25px;
  }

  .button-primary.small {
    padding-left: 20px;
    padding-right: 20px;
  }

  .button-primary.large {
    padding-left: 40px;
    padding-right: 40px;
  }

  .button-primary._2-buttons {
    flex: 1;
    margin-right: 20px;
  }

  .button-primary.header-button {
    display: none;
  }

  .button-primary.cta {
    display: block;
  }

  .button-secondary {
    padding-left: 25px;
    padding-right: 25px;
  }

  .button-secondary.small {
    padding-left: 20px;
    padding-right: 20px;
  }

  .button-secondary.large {
    padding-left: 40px;
    padding-right: 40px;
  }

  .button-secondary._2-buttons {
    flex: 1;
  }

  .button-secondary.category {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }

  .button-secondary.category.all {
    margin-right: 20px;
  }

  .header {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .nav-item-wrapper {
    padding-top: 23px;
    padding-bottom: 23px;
  }

  .nav-item-wrapper.button-mobile {
    margin-top: 140px;
  }

  .footer {
    padding-top: 64px;
  }

  .footer-logo-container {
    align-self: flex-start;
  }

  .footer-logo {
    width: auto;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.home-hero {
    padding-bottom: 69px;
  }

  .section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .section.about-hero {
    padding-bottom: 82px;
  }

  .section.courses-hero, .section.course-hero {
    padding-bottom: 70px;
  }

  .section.course-videos {
    padding-bottom: 60px;
  }

  .section.blog-hero {
    padding-bottom: 92px;
  }

  .section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.other-courses {
    padding-top: 82px;
  }

  .section.hero-page {
    padding-top: 60px;
  }

  .section.notification-bar, .section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .section.notification-bar-landscape, .section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .section.resources-hero {
    padding-bottom: 82px;
  }

  .utility-page-content-password {
    padding: 50px 32px;
  }

  .input.footer-newsletter {
    padding-right: 150px;
  }

  .input.blog-newsletter {
    padding-right: 160px;
  }

  ._404-title {
    margin-bottom: 25px;
  }

  .divider.card-get-course {
    width: 100%;
    min-height: 1px;
    margin: 40px 0;
  }

  .divider.course-videos {
    margin-top: 94px;
    margin-bottom: 94px;
  }

  .paragraph.home-hero {
    margin-bottom: 35px;
  }

  .paragraph.home-about {
    margin-bottom: 50px;
  }

  .paragraph.card-testimonial {
    max-width: 626px;
  }

  .paragraph.about-hero {
    margin-bottom: 35px;
  }

  .paragraph.card-experience {
    max-width: 449px;
  }

  .paragraph.card-about-testimonial, .paragraph.card-course-featured-summary, .paragraph.card-course-summary, .paragraph.course-hero {
    margin-bottom: 35px;
  }

  .paragraph.course-reviews {
    margin-bottom: 50px;
  }

  .paragraph.course-videos-hero-course-summary, .paragraph.course-videos-links {
    margin-bottom: 40px;
  }

  .paragraph.card-course-videos-community {
    margin-bottom: 28px;
  }

  .paragraph.post-excerpt {
    margin-bottom: 35px;
    margin-left: 0;
    margin-right: 0;
  }

  .paragraph.contact {
    margin-bottom: 35px;
  }

  .card.changelog {
    padding: 50px 40px;
  }

  .card.home-about {
    max-width: 90%;
    padding-bottom: 48px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .card.bg-neutral-300.value {
    padding: 42px 30px 50px;
  }

  .card.bg-neutral-300.course-video {
    flex-direction: column;
    padding: 48px 30px 57px;
  }

  .card.bg-neutral-300.course-videos-resource {
    padding: 40px 30px;
  }

  .card.bg-neutral-300.course-videos-community {
    padding: 48px 30px 52px;
  }

  .card.home-topic {
    padding: 42px 30px 50px;
  }

  .card.testimonial {
    flex-direction: column;
    align-items: stretch;
    padding-top: 20px;
    padding-bottom: 42px;
  }

  .card.faq {
    padding: 38px 25px;
  }

  .card.experience {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .card.about-testimonial {
    padding: 50px 40px;
  }

  .card.get-course {
    flex-direction: column;
    align-items: stretch;
    padding: 40px 35px 51px;
  }

  .card.author {
    flex-direction: column;
    padding: 53px 44px;
  }

  .card.contact {
    min-height: 951px;
    padding: 56px 35px;
  }

  .card.direct-contact-link {
    padding: 47px 35px 51px;
  }

  .card.default-v1, .card.default-v2, .card.default-v3 {
    padding: 50px 35px;
  }

  .checkout-form {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .checkout-block-header, .checkout-block-content {
    padding-left: 40px;
    padding-right: 40px;
  }

  .title.h3-size {
    font-size: 23px;
  }

  .title.h3-size.home-features {
    margin-bottom: 40px;
  }

  .title.h3-size.post-featured {
    margin-bottom: 15px;
  }

  .title.home-topics {
    max-width: 395px;
  }

  .title.card-testimonial {
    max-width: 527px;
  }

  .title.h4-size {
    font-size: 20px;
  }

  .title.h4-size.blog-hero-popular {
    margin-bottom: 32px;
  }

  .title.footer-menu-title {
    margin-bottom: 35px;
  }

  .title.dropdown-nav-title {
    margin-bottom: 30px;
  }

  .title.values {
    margin-bottom: 40px;
  }

  .title.experience {
    margin-bottom: 15px;
  }

  .title.about-testimonials {
    max-width: 431px;
  }

  .title.courses {
    margin-bottom: 50px;
  }

  .title.card-get-course {
    margin-bottom: 27px;
  }

  .title.card-course-videos-resource {
    margin-bottom: 10px;
  }

  .title.blog-hero {
    margin-bottom: 50px;
  }

  .title.h5-size.post-popular {
    max-width: 409px;
  }

  .title.blog {
    margin-bottom: 35px;
  }

  .title.post-item {
    margin-bottom: 15px;
  }

  .title.contact {
    margin-bottom: 22px;
  }

  .title.card-direct-contact-link {
    margin-bottom: 5px;
  }

  .title.category {
    margin-right: 8px;
  }

  .title.h1-size {
    font-size: 42px;
  }

  .title.h2-size {
    font-size: 32px;
  }

  .title.course-content-title {
    max-width: 395px;
  }

  .title.resource-title {
    margin-bottom: 50px;
  }

  .split-content.home-hero-left, .split-content.home-about-right {
    margin-bottom: 83px;
  }

  .split-content.cta-left {
    margin-bottom: 35px;
  }

  .split-content.cta-right {
    width: 100%;
    max-width: 274px;
  }

  .split-content.header-center.right-mg-responsive {
    margin-right: 20px;
  }

  .split-content.about-hero-right, .split-content.story-left, .split-content.course-hero-content, .split-content.course-right {
    margin-bottom: 83px;
  }

  .split-content.content-top-other-courses-left {
    margin-right: 30px;
  }

  .split-content.course-videos-hero-left {
    margin-bottom: 83px;
  }

  .split-content.post-about-left {
    margin-bottom: 30px;
    margin-right: 30px;
  }

  .split-content.post-about-right {
    margin-bottom: 30px;
  }

  .split-content.content-top-latest-articles-left, .split-content.content-top-latest-articles-right {
    margin-bottom: 35px;
  }

  .split-content.contact-left {
    margin-bottom: 83px;
  }

  .split-content.blog-newsletter-content {
    margin-bottom: 50px;
  }

  .split-content.comingsoon-header {
    margin-bottom: 83px;
  }

  .checkout-row, .order-item {
    flex-direction: column;
  }

  .image-wrapper.order-item-image {
    margin-right: 0;
  }

  .image-wrapper.card-experience-icon {
    width: 67px;
    max-height: 67px;
    min-height: 67px;
    min-width: 67px;
  }

  .image-wrapper.post-featured {
    margin-bottom: 30px;
  }

  .image-wrapper.post-popular {
    max-width: 34%;
    margin-right: 20px;
  }

  .image-wrapper.post {
    margin-bottom: 98px;
  }

  .image-wrapper.post-author {
    margin-right: 20px;
  }

  .image-wrapper.card-author {
    width: 140px;
    max-height: 140px;
    min-height: 140px;
    min-width: 140px;
    margin-bottom: 28px;
    margin-right: 0;
  }

  .image-wrapper.card-direct-contact-link {
    margin-bottom: 25px;
  }

  .image.home-course-steps {
    max-height: 450px;
  }

  .image.card-testimonial {
    max-width: 192px;
    margin-top: 0;
    margin-bottom: 30px;
    margin-right: 0;
  }

  .image.card-testimonial-logo {
    margin-bottom: 25px;
  }

  .image.card-about-testimonial {
    width: 60px;
    max-height: 60px;
    min-height: 60px;
    min-width: 60px;
    margin-right: 20px;
  }

  .order-item-wrapper {
    flex-flow: column;
    margin-right: 0;
  }

  .home-hero-wrapper {
    margin-bottom: 82px;
  }

  .home-hero-about-teacher-wrapper {
    margin-bottom: 50px;
  }

  ._2-buttons-wrapper {
    display: flex;
  }

  .companies-home-hero-text {
    margin-bottom: 25px;
  }

  .companies-home-hero-grid {
    grid-row-gap: 20px;
    grid-template-columns: auto auto;
    align-content: stretch;
    justify-content: center;
  }

  .card-home-about-rol {
    margin-bottom: 20px;
  }

  .home-course-steps-wrapper {
    margin-bottom: 50px;
  }

  .flex-vc {
    align-items: stretch;
  }

  .home-features-wrapper {
    max-width: 615px;
    grid-row-gap: 30px;
  }

  .home-feature-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .home-feature-number {
    margin-bottom: 10px;
    font-size: 48px;
  }

  .home-course-steps-content {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }

  .content-top.experience {
    margin-bottom: 50px;
  }

  .content-top.other-courses {
    margin-bottom: 40px;
  }

  .content-top.blog {
    align-items: stretch;
    margin-bottom: 60px;
  }

  .content-top.latest-articles {
    margin-bottom: 5px;
  }

  .content-top.blog-category {
    align-items: flex-start;
    margin-bottom: 60px;
  }

  .home-topics-grid {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }

  .card-home-topic-icon {
    width: 113px;
    max-height: 113px;
    min-height: 113px;
    min-width: 113px;
    margin-bottom: 27px;
  }

  .container-medium-728px.testimonials {
    margin-bottom: 50px;
  }

  .slide-nav {
    margin-top: 30px;
  }

  .card-testimonial-content-bottom {
    flex-wrap: wrap-reverse;
    margin-bottom: -25px;
  }

  .card-testimonial-name-wrapper {
    margin-bottom: 25px;
    margin-right: 30px;
  }

  .container-medium-661px.faqs {
    margin-bottom: 40px;
  }

  .faqs-wrapper {
    margin-bottom: 50px;
  }

  .space.faq {
    min-height: 14px;
  }

  .cta-wrapper {
    padding: 53px;
  }

  .shape.home-hero-3 {
    display: none;
    top: -17px;
    left: -158.5px;
  }

  .shape.home-about-1 {
    bottom: -23px;
    left: 1px;
  }

  .shape.home-about-2 {
    display: none;
    top: -30px;
    right: -96px;
  }

  .shape.home-features-1 {
    bottom: -203.891px;
  }

  .shape.home-features-2 {
    top: -227px;
  }

  .shape.home-course-steps-1 {
    bottom: -18px;
  }

  .shape.home-course-steps-2 {
    bottom: 328px;
  }

  .shape.home-course-steps-3 {
    top: 70px;
    left: -130px;
  }

  .shape.home-topics-2 {
    bottom: 97px;
  }

  .shape.testimonials-2 {
    top: -20px;
  }

  .shape.testimonials-3 {
    display: none;
    bottom: -27px;
    left: -71px;
  }

  .shape.testimonials-4 {
    display: none;
    top: -58px;
    right: -69px;
  }

  .shape.cta-1 {
    top: -143.203px;
  }

  .shape.cta-2 {
    bottom: -142.203px;
  }

  .shape.about-hero-1 {
    top: 116px;
  }

  .shape.about-hero-2 {
    bottom: -24px;
  }

  .shape.about-hero-3 {
    right: -16px;
  }

  .shape.values-1 {
    bottom: -20px;
  }

  .shape.values-2 {
    top: -20px;
  }

  .shape.experience-1 {
    top: -14px;
    left: auto;
  }

  .shape.experience-2 {
    bottom: -45px;
    left: -15px;
    right: auto;
  }

  .shape.about-testimonials-1 {
    bottom: -20px;
  }

  .shape.about-testimonials-2 {
    top: -20px;
  }

  .shape.courses-hero {
    bottom: -51px;
    right: -130px;
  }

  .shape.courses-1 {
    bottom: 531px;
  }

  .shape.course-hero-1 {
    top: -40px;
  }

  .shape.course-hero-2 {
    bottom: -23px;
    right: -86px;
  }

  .shape.course-reviews-1 {
    top: -19px;
  }

  .shape.course-videos-hero-1 {
    left: 50px;
  }

  .shape.contact-1 {
    bottom: -74px;
    right: -107px;
  }

  .shape.direct-contact-links-1 {
    left: -120px;
  }

  .shape.password-1 {
    left: -21.358px;
  }

  .shape.password-2 {
    right: -19.085px;
  }

  .shape.not-found-1 {
    top: 15px;
    bottom: auto;
    left: auto;
    right: -90px;
  }

  .shape.not-found-2 {
    bottom: -90px;
  }

  .shape.not-found-3 {
    top: -128.497px;
    left: -141px;
  }

  .shape.not-found-4 {
    display: none;
    top: 9.5px;
    right: -164px;
  }

  .shape.home-hero-3_2 {
    display: none;
    top: -17px;
    left: -158.5px;
  }

  .footer-small-print {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-content-top {
    align-items: stretch;
    margin-bottom: 53px;
  }

  .footer-content {
    margin-bottom: 64px;
  }

  .footer-menu {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .footer-menu-wrapper {
    margin-bottom: 30px;
  }

  .dropdown-arrow {
    font-size: 12px;
  }

  .dropdown-nav-main-wrapper {
    margin-bottom: -35px;
    padding-top: 30px;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .about-hero-features-grid {
    margin-bottom: 40px;
  }

  .story-left-content-top, .story-right-content-bottom {
    margin-bottom: 50px;
  }

  .values-grid {
    grid-template-columns: 1fr;
  }

  .card-value-icon {
    width: 113px;
    max-height: 113px;
    min-height: 113px;
    min-width: 113px;
    margin-bottom: 27px;
  }

  .experience-mask {
    max-width: 100%;
  }

  .card-experience-content-top {
    margin-bottom: 20px;
  }

  .card-experience-grid, .container-medium-795px.about-testimonials, .about-testimonials-wrapper {
    margin-bottom: 40px;
  }

  .values-wrapper, .container-medium-671px.courses-hero {
    margin-bottom: 50px;
  }

  .card-course-featured-content {
    padding-bottom: 48px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .courses-grid {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
  }

  .card-course-content {
    padding-bottom: 48px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .courses-wrapper {
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
  }

  .course-tabs-menu {
    margin-bottom: 50px;
  }

  .course-tab-link {
    white-space: normal;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .course-content-rich-text ul {
    margin-right: 50px;
  }

  .course-content-rich-text p {
    margin-bottom: 26px;
  }

  .card-get-course-content-top {
    min-width: auto;
  }

  .other-courses-grid {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
  }

  .course-videos-tabs-menu {
    margin-bottom: 60px;
  }

  .course-videos-tab-link {
    white-space: normal;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .course-videos-hero-course-features-grid {
    grid-template-columns: 1fr;
  }

  .course-videos-title-wrapper {
    margin-bottom: 40px;
  }

  .card-course-video-button {
    margin-bottom: 26px;
    margin-right: 0;
  }

  .card-course-videos-resource-icon-wrapper {
    width: 65px;
    max-height: 65px;
    min-height: 65px;
    min-width: 65px;
    margin-right: 20px;
  }

  .card-course-videos-community-content {
    max-width: 526px;
  }

  .blog-hero-grid {
    grid-row-gap: 83px;
  }

  .post-content-top {
    margin-bottom: 10px;
  }

  .post-popular-item {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blog-grid {
    grid-template-columns: 1fr;
  }

  .categories-wrapper {
    justify-content: flex-start;
    margin-bottom: -20px;
    margin-right: -20px;
  }

  .category-item {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .container-large-1122px.post {
    text-align: left;
    margin-bottom: 58px;
  }

  .post-about-wrapper {
    flex-wrap: wrap;
    margin-bottom: -30px;
  }

  .latest-articles-grid {
    grid-template-columns: 1fr;
  }

  .card-author-content-top {
    margin-bottom: 20px;
  }

  .rich-text p {
    margin-bottom: 27px;
  }

  .rich-text ul {
    margin-top: 27px;
    margin-bottom: 47px;
    padding-left: 20px;
  }

  .rich-text figure {
    margin-top: 42px;
    margin-bottom: 58px;
  }

  .rich-text h3 {
    margin-top: 47px;
  }

  .rich-text ol {
    margin-top: 27px;
    margin-bottom: 47px;
    padding-left: 20px;
  }

  .rich-text blockquote {
    margin-top: 47px;
    margin-bottom: 58px;
  }

  .rich-text h6, .rich-text h4, .rich-text h5 {
    margin-top: 47px;
  }

  .contact-wrapper {
    margin-bottom: 111px;
  }

  .contact-links-grid {
    grid-row-gap: 30px;
  }

  .contact-link-icon {
    margin-right: 13px;
  }

  .contact-form {
    grid-template-columns: 1fr;
  }

  .card-direct-contact-link-text {
    margin-bottom: 13px;
  }

  .bg.contact, .bg.home-hero {
    min-height: 56%;
  }

  .blog-newsletter-wrapper {
    padding: 72px 35px;
  }

  .category-title-wrapper.course-levels {
    margin-bottom: 50px;
  }

  .category-title-wrapper.blog-category {
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 35px;
  }

  .cart-item {
    flex-direction: column;
  }

  .heading-size-h3 {
    font-size: 23px;
  }

  .style---line-icons-grid {
    grid-template-columns: repeat(auto-fit, 18px);
  }

  .btn-index-cta {
    padding: 20px 32px;
  }

  .btn-index-cta.button-row {
    margin-right: 16px;
  }

  .card-home-pages-wrapper {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .image-home-link-wrapper {
    max-width: 209px;
    border-radius: 16px;
  }

  .figma-file-text-wrapper, .figma-file-text-wrapper.pd-large {
    padding-left: 48px;
    padding-right: 48px;
  }

  .grid-3-columns, .grid-2-columns {
    grid-template-columns: 1fr;
  }

  .home-surprises-card {
    border-radius: 28px;
  }

  .buttons-row.center-mobile {
    justify-content: center;
  }

  .mg-bottom-40px {
    margin-bottom: 34px;
  }

  .display-4 {
    font-size: 20px;
    line-height: 32px;
  }

  .home-pages-cointainer {
    margin-top: 40px;
  }

  .text-center.mobile-l {
    text-align: center;
  }

  .mg-bottom-56px {
    margin-bottom: 40px;
  }

  .cta-image-card {
    width: 980px;
    max-width: 980px;
  }

  .badge-home {
    padding: 8px 15px;
    font-size: 14px;
    line-height: 22px;
  }

  .heading-size-h2 {
    font-size: 28px;
    line-height: 39px;
  }

  .top-bar-flex {
    flex-direction: column;
  }

  .top-bar-padding {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .top-bar-padding.small-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .text-200 {
    font-size: 16px;
    line-height: 18px;
  }

  .text-200.list {
    line-height: 22px;
  }

  .top-bar-wrapper {
    padding-left: 36px;
    padding-right: 36px;
  }

  .home-hero-wrapper-2, .home-hero-wrapper-2-mobile {
    margin-bottom: 82px;
  }

  .home-hero-wrapper-2-mobile-landscape {
    margin-bottom: 82px;
    padding-left: 0;
    padding-right: 0;
  }

  .colleges-home-hero-text {
    margin-bottom: 25px;
  }

  .whatsapp-integration {
    bottom: 15px;
    right: 15px;
  }

  .top-bar-overflow-padding {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .top-bar-overflow-padding.small-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .top-bar-overflow-wrapper {
    padding-left: 36px;
    padding-right: 36px;
  }

  .overflow-text {
    font-size: 16px;
    line-height: 18px;
  }

  .overflow-text.list {
    line-height: 22px;
  }

  .top-bar-text {
    font-size: 16px;
    line-height: 18px;
  }

  .top-bar-text.list {
    line-height: 22px;
  }

  .enquiry-form {
    display: flex;
  }

  .notification-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .notification-section.home-hero {
    padding-bottom: 69px;
  }

  .notification-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .notification-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .notification-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .notification-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .notification-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .notification-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .notification-section.about-hero {
    padding-bottom: 82px;
  }

  .notification-section.courses-hero, .notification-section.course-hero {
    padding-bottom: 70px;
  }

  .notification-section.course-videos {
    padding-bottom: 60px;
  }

  .notification-section.blog-hero {
    padding-bottom: 92px;
  }

  .notification-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .notification-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .notification-section.other-courses {
    padding-top: 82px;
  }

  .notification-section.hero-page {
    padding-top: 60px;
  }

  .notification-section.notification-bar, .notification-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .notification-section.notification-bar-landscape, .notification-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .notification-section.resources-hero {
    padding-bottom: 82px;
  }

  .alert-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .alert-section.home-hero {
    padding-bottom: 69px;
  }

  .alert-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .alert-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .alert-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .alert-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .alert-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .alert-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .alert-section.about-hero {
    padding-bottom: 82px;
  }

  .alert-section.courses-hero, .alert-section.course-hero {
    padding-bottom: 70px;
  }

  .alert-section.course-videos {
    padding-bottom: 60px;
  }

  .alert-section.blog-hero {
    padding-bottom: 92px;
  }

  .alert-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .alert-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .alert-section.other-courses {
    padding-top: 82px;
  }

  .alert-section.hero-page {
    padding-top: 60px;
  }

  .alert-section.notification-bar, .alert-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .alert-section.notification-bar-landscape, .alert-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .alert-section.resources-hero {
    padding-bottom: 82px;
  }

  .home-hero-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .home-hero-section.home-hero {
    padding-bottom: 69px;
  }

  .home-hero-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .home-hero-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .home-hero-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .home-hero-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .home-hero-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .home-hero-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .home-hero-section.about-hero {
    padding-bottom: 82px;
  }

  .home-hero-section.courses-hero, .home-hero-section.course-hero {
    padding-bottom: 70px;
  }

  .home-hero-section.course-videos {
    padding-bottom: 60px;
  }

  .home-hero-section.blog-hero {
    padding-bottom: 92px;
  }

  .home-hero-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .home-hero-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .home-hero-section.other-courses {
    padding-top: 82px;
  }

  .home-hero-section.hero-page {
    padding-top: 60px;
  }

  .home-hero-section.notification-bar, .home-hero-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .home-hero-section.notification-bar-landscape, .home-hero-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .home-hero-section.resources-hero {
    padding-bottom: 82px;
  }

  .marketing-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .marketing-section.home-hero {
    padding-bottom: 69px;
  }

  .marketing-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .marketing-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .marketing-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .marketing-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .marketing-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .marketing-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .marketing-section.about-hero {
    padding-bottom: 82px;
  }

  .marketing-section.courses-hero, .marketing-section.course-hero {
    padding-bottom: 70px;
  }

  .marketing-section.course-videos {
    padding-bottom: 60px;
  }

  .marketing-section.blog-hero {
    padding-bottom: 92px;
  }

  .marketing-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .marketing-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .marketing-section.other-courses {
    padding-top: 82px;
  }

  .marketing-section.hero-page {
    padding-top: 60px;
  }

  .marketing-section.notification-bar, .marketing-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .marketing-section.notification-bar-landscape, .marketing-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .marketing-section.resources-hero {
    padding-bottom: 82px;
  }

  .courses-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .courses-section.home-hero {
    padding-bottom: 69px;
  }

  .courses-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .courses-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .courses-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .courses-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .courses-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .courses-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .courses-section.about-hero {
    padding-bottom: 82px;
  }

  .courses-section.courses-hero, .courses-section.course-hero {
    padding-bottom: 70px;
  }

  .courses-section.course-videos {
    padding-bottom: 60px;
  }

  .courses-section.blog-hero {
    padding-bottom: 92px;
  }

  .courses-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .courses-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .courses-section.other-courses {
    padding-top: 82px;
  }

  .courses-section.hero-page {
    padding-top: 60px;
  }

  .courses-section.notification-bar, .courses-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .courses-section.notification-bar-landscape, .courses-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .courses-section.resources-hero {
    padding-bottom: 82px;
  }

  .features-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .features-section.home-hero {
    padding-bottom: 69px;
  }

  .features-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .features-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .features-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .features-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .features-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .features-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .features-section.about-hero {
    padding-bottom: 82px;
  }

  .features-section.courses-hero, .features-section.course-hero {
    padding-bottom: 70px;
  }

  .features-section.course-videos {
    padding-bottom: 60px;
  }

  .features-section.blog-hero {
    padding-bottom: 92px;
  }

  .features-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .features-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .features-section.other-courses {
    padding-top: 82px;
  }

  .features-section.hero-page {
    padding-top: 60px;
  }

  .features-section.notification-bar, .features-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .features-section.notification-bar-landscape, .features-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .features-section.resources-hero {
    padding-bottom: 82px;
  }

  .student-testimonials-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .student-testimonials-section.home-hero {
    padding-bottom: 69px;
  }

  .student-testimonials-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .student-testimonials-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .student-testimonials-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .student-testimonials-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .student-testimonials-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .student-testimonials-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .student-testimonials-section.about-hero {
    padding-bottom: 82px;
  }

  .student-testimonials-section.courses-hero, .student-testimonials-section.course-hero {
    padding-bottom: 70px;
  }

  .student-testimonials-section.course-videos {
    padding-bottom: 60px;
  }

  .student-testimonials-section.blog-hero {
    padding-bottom: 92px;
  }

  .student-testimonials-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .student-testimonials-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .student-testimonials-section.other-courses {
    padding-top: 82px;
  }

  .student-testimonials-section.hero-page {
    padding-top: 60px;
  }

  .student-testimonials-section.notification-bar, .student-testimonials-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .student-testimonials-section.notification-bar-landscape, .student-testimonials-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .student-testimonials-section.resources-hero {
    padding-bottom: 82px;
  }

  .cta-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .cta-section.home-hero {
    padding-bottom: 69px;
  }

  .cta-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .cta-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .cta-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .cta-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .cta-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .cta-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .cta-section.about-hero {
    padding-bottom: 82px;
  }

  .cta-section.courses-hero, .cta-section.course-hero {
    padding-bottom: 70px;
  }

  .cta-section.course-videos {
    padding-bottom: 60px;
  }

  .cta-section.blog-hero {
    padding-bottom: 92px;
  }

  .cta-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .cta-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .cta-section.other-courses {
    padding-top: 82px;
  }

  .cta-section.hero-page {
    padding-top: 60px;
  }

  .cta-section.notification-bar, .cta-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .cta-section.notification-bar-landscape, .cta-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .cta-section.resources-hero {
    padding-bottom: 82px;
  }

  .enquiry-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .enquiry-section.home-hero {
    padding-bottom: 69px;
  }

  .enquiry-section.bg-neutral-200.home-topics {
    padding-top: 91px;
  }

  .enquiry-section.bg-neutral-200.course {
    padding-bottom: 82px;
  }

  .enquiry-section.bg-neutral-200.course-videos-hero {
    padding-top: 40px;
    padding-bottom: 76px;
  }

  .enquiry-section.bg-neutral-800.home-features {
    padding-top: 63px;
    padding-bottom: 68px;
  }

  .enquiry-section.bg-neutral-800.testimonials {
    padding-top: 92px;
    padding-bottom: 97px;
  }

  .enquiry-section.home-faqs {
    padding-top: 92px;
    padding-bottom: 86px;
  }

  .enquiry-section.about-hero {
    padding-bottom: 82px;
  }

  .enquiry-section.courses-hero, .enquiry-section.course-hero {
    padding-bottom: 70px;
  }

  .enquiry-section.course-videos {
    padding-bottom: 60px;
  }

  .enquiry-section.blog-hero {
    padding-bottom: 92px;
  }

  .enquiry-section.utility-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .enquiry-section.changelog {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .enquiry-section.other-courses {
    padding-top: 82px;
  }

  .enquiry-section.hero-page {
    padding-top: 60px;
  }

  .enquiry-section.notification-bar, .enquiry-section.notification-bar-tab {
    padding-bottom: 69px;
  }

  .enquiry-section.notification-bar-landscape, .enquiry-section.overflow-notification-bar {
    padding-bottom: 0;
  }

  .enquiry-section.resources-hero {
    padding-bottom: 82px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    margin-bottom: 10px;
    font-size: 30px;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 26px;
  }

  h3 {
    font-size: 21px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  li {
    margin-bottom: 13px;
  }

  blockquote {
    padding: 66px 25px;
    font-size: 20px;
  }

  figcaption {
    margin-top: 20px;
    font-size: 14px;
  }

  .container-default {
    padding-left: 16px;
    padding-right: 16px;
  }

  .section-styleguide {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .style-guide-header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .color-primary-grid, .color-neutral-grid {
    grid-template-columns: 1fr;
  }

  .paragraph-large {
    font-size: 20px;
  }

  .style-guide-subheader {
    padding-left: 20px;
    padding-right: 20px;
  }

  .style-guide-content-wrapper {
    padding: 60px 20px;
  }

  .icons-grid {
    grid-template-columns: repeat(auto-fit, 60px);
  }

  .button-primary {
    display: block;
  }

  .button-primary.small {
    font-size: 16px;
  }

  .button-primary.small.footer-newsletter {
    margin-top: 20px;
    padding: 24px 20px;
    font-size: 18px;
    line-height: 1.111em;
    position: static;
  }

  .button-primary.small.back-to-resources {
    margin-left: 0;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .button-primary.large {
    padding-left: 25px;
    padding-right: 25px;
  }

  .button-primary._2-buttons {
    margin-right: 0;
  }

  .button-primary.blog-newsletter {
    margin-top: 20px;
    position: static;
  }

  .button-primary.button-row {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .button-secondary {
    display: block;
  }

  .button-secondary.large {
    padding-left: 25px;
    padding-right: 25px;
  }

  .button-secondary.pagination {
    flex: 1;
  }

  .header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header-navigation {
    margin-left: 16px;
    margin-right: 16px;
  }

  .nav-item-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .nav-item-wrapper.button-mobile {
    margin-top: 130px;
  }

  .nav-link {
    font-size: 28px;
  }

  .menu-button {
    padding: 10px;
    font-size: 26px;
  }

  .menu-button:hover {
    background-color: var(--primary-dark);
  }

  .nav-menu {
    padding-top: 110px;
  }

  .footer {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .footer-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo {
    width: 160px;
  }

  .section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .section.home-hero {
    padding-bottom: 24px;
  }

  .section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .section.about-hero {
    padding-bottom: 66px;
  }

  .section.courses-hero, .section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .section.course-videos {
    padding-top: 40px;
  }

  .section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .section.post, .section.contact, .section.course-levels, .section.blog-category {
    padding-top: 30px;
  }

  .section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section.other-courses {
    padding-top: 66px;
  }

  .section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .section.notification-bar, .section.notification-bar-tab, .section.notification-bar-landscape, .section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .section.resources-hero {
    padding-bottom: 66px;
  }

  .section.tc-hero, .section.pp-hero {
    padding-top: 30px;
  }

  .licenses-image {
    border-radius: 12px;
  }

  .utility-page-wrap {
    padding: 60px 16px;
  }

  .utility-page-content-password {
    padding: 40px 25px;
  }

  .input {
    padding-left: 20px;
    padding-right: 20px;
  }

  .input.footer-newsletter {
    min-height: 68px;
    padding-right: 20px;
  }

  .input.blog-newsletter {
    min-height: 68px;
    padding-left: 20px;
    padding-right: 20px;
  }

  ._404-title {
    margin-bottom: 20px;
    font-size: 110px;
  }

  .container-medium-761px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .divider.card-testimonial {
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .divider.card-get-course {
    margin-top: 33px;
    margin-bottom: 33px;
  }

  .divider.course-videos {
    margin-top: 0;
    margin-bottom: 0;
  }

  .icon-password {
    width: 130px;
    height: 130px;
  }

  .paragraph.home-hero {
    margin-bottom: 30px;
  }

  .paragraph.home-about {
    margin-bottom: 40px;
  }

  .paragraph.about-hero, .paragraph.card-about-testimonial, .paragraph.card-course-featured-summary, .paragraph.card-course-summary, .paragraph.course-hero {
    margin-bottom: 30px;
  }

  .paragraph.course-reviews {
    margin-bottom: 40px;
  }

  .paragraph.course-videos-hero-course-summary {
    margin-bottom: 35px;
  }

  .paragraph.card-course-videos-community {
    margin-bottom: 23px;
  }

  .paragraph.post-excerpt {
    margin-bottom: 30px;
  }

  .paragraph.card-author {
    max-width: 356px;
    margin-bottom: 35px;
  }

  .paragraph.contact {
    margin-bottom: 30px;
  }

  .paragraph.story {
    padding-right: 0;
  }

  .card.changelog {
    padding: 40px 20px;
  }

  .card.home-about {
    max-width: 100%;
    margin-top: -22px;
    padding: 33px 25px 40px;
  }

  .card.bg-neutral-300.home-course-step {
    padding-left: 25px;
    padding-right: 25px;
  }

  .card.bg-neutral-300.value {
    padding: 35px 25px 42px;
  }

  .card.bg-neutral-300.course-video {
    padding: 40px 25px 48px;
  }

  .card.bg-neutral-300.course-videos-resource {
    flex-direction: column;
    align-items: stretch;
    padding: 33px 25px;
  }

  .card.bg-neutral-300.course-videos-community {
    flex-direction: column;
    padding: 40px 25px 43px;
  }

  .card.home-topic {
    padding: 35px 25px 42px;
  }

  .card.testimonial {
    padding-left: 25px;
    padding-right: 25px;
  }

  .card.faq {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .card.experience {
    padding-top: 42px;
    padding-bottom: 42px;
  }

  .card.about-testimonial {
    padding: 42px 25px;
  }

  .card.course-featured {
    margin-bottom: 20px;
  }

  .card.get-course {
    padding: 33px 25px 43px;
  }

  .card.author {
    padding: 44px 25px;
  }

  .card.contact {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    min-height: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 28px 25px;
  }

  .card.direct-contact-link {
    padding: 40px 25px 43px;
  }

  .card.default-v1, .card.default-v2, .card.default-v3 {
    padding: 42px 25px;
  }

  .checkout-form {
    padding-bottom: 82px;
  }

  .checkout-block-header, .checkout-block-content {
    padding-left: 25px;
    padding-right: 25px;
  }

  .title.order-item-title {
    font-size: 18px;
  }

  .title.h3-size {
    font-size: 21px;
  }

  .title.h3-size.home-features {
    margin-bottom: 35px;
  }

  .title.h3-size.post-featured {
    max-width: 380px;
    margin-bottom: 10px;
  }

  .title.home-topics {
    max-width: 313px;
  }

  .title.h4-size {
    font-size: 18px;
  }

  .title.h4-size.blog-hero-popular, .title.footer-menu-title {
    margin-bottom: 30px;
  }

  .title.dropdown-nav-title {
    margin-bottom: 20px;
  }

  .title.experience {
    margin-bottom: 10px;
  }

  .title.card-experience {
    margin-bottom: 4px;
  }

  .title.about-testimonials {
    max-width: 366px;
    overflow: visible;
  }

  .title.courses {
    margin-bottom: 40px;
  }

  .title.card-get-course {
    margin-bottom: 20px;
  }

  .title.card-course-videos-resource {
    font-size: 20px;
  }

  .title.card-course-videos-community {
    margin-bottom: 6px;
  }

  .title.blog-hero {
    margin-bottom: 40px;
  }

  .title.h5-size {
    font-size: 16px;
  }

  .title.h5-size.post-popular {
    max-width: 358px;
  }

  .title.blog {
    margin-bottom: 30px;
  }

  .title.post-item {
    max-width: 380px;
    margin-bottom: 10px;
  }

  .title.contact {
    margin-bottom: 15px;
  }

  .title.h1-size {
    font-size: 30px;
  }

  .title.h2-size {
    font-size: 26px;
  }

  .title.course-content-title {
    max-width: 313px;
  }

  .title.resource-title {
    margin-bottom: 40px;
  }

  .split-content.header-right {
    margin-right: 20px;
  }

  .split-content.home-hero-left, .split-content.home-about-right {
    margin-bottom: 60px;
  }

  .split-content.cta-left {
    margin-bottom: 30px;
  }

  .split-content.cta-right {
    max-width: none;
  }

  .split-content.header-center, .split-content.header-center.right-mg-responsive {
    margin-right: 15px;
  }

  .split-content.about-hero-right, .split-content.story-left {
    margin-bottom: 60px;
  }

  .split-content.content-bottom-card-about-testimonial-left {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 20px;
  }

  .split-content.course-hero-content, .split-content.course-right {
    margin-bottom: 60px;
  }

  .split-content.content-top-other-courses-left {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .split-content.course-videos-hero-left {
    margin-bottom: 60px;
  }

  .split-content.card-course-video-left {
    margin-right: 0;
  }

  .split-content.post-about-left {
    margin-bottom: 25px;
    margin-right: 0;
  }

  .split-content.post-about-right {
    margin-bottom: 0;
  }

  .split-content.content-top-latest-articles-left {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .split-content.content-top-latest-articles-right {
    margin-bottom: 0;
  }

  .split-content.contact-left {
    margin-bottom: 60px;
  }

  .split-content.blog-newsletter-content {
    margin-bottom: 40px;
  }

  .split-content.comingsoon-header {
    margin-bottom: 60px;
  }

  .checkout-required-text {
    font-size: 18px;
  }

  .image-wrapper.home-hero-about-teacher {
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .image-wrapper.home-about {
    max-width: 100%;
  }

  .image-wrapper.story-right {
    justify-content: space-around;
  }

  .image-wrapper.card-experience-icon {
    width: 60px;
    max-height: 60px;
    min-height: 60px;
    min-width: 60px;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .image-wrapper.post-featured {
    margin-bottom: 28px;
  }

  .image-wrapper.post-popular {
    max-width: 100%;
    margin-bottom: 23px;
    margin-right: 0;
  }

  .image-wrapper.post-item {
    margin-bottom: 28px;
  }

  .image-wrapper.post {
    margin-bottom: 82px;
  }

  .image-wrapper.post-author {
    width: 60px;
    max-height: 60px;
    min-height: 60px;
    min-width: 60px;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .image-wrapper.card-author {
    margin-bottom: 20px;
  }

  .image-wrapper.card-direct-contact-link {
    width: 92px;
    max-height: 92px;
    min-height: 92px;
    min-width: 92px;
    margin-bottom: 20px;
  }

  .image.card-testimonial {
    margin-bottom: 25px;
  }

  .image.address-icon {
    width: 22px;
    max-height: 22px;
    min-height: 22px;
    min-width: 22px;
    margin-right: 10px;
  }

  .image.story-right.image_right_mobile {
    width: 85%;
    height: 85%;
    max-height: 85%;
    max-width: 50%;
  }

  .image.card-about-testimonial-logo {
    margin-bottom: 20px;
  }

  .image.card-about-testimonial {
    margin-bottom: 15px;
    margin-right: 0;
  }

  .image.video-preview-button-icon {
    left: 4px;
  }

  .image.course-feature-icon {
    margin-right: 10px;
  }

  .image.course-image {
    object-fit: contain;
  }

  .grid-licenses-images {
    grid-template-columns: 1fr;
  }

  .home-hero-wrapper {
    margin-bottom: 66px;
  }

  .home-hero-about-teacher-wrapper {
    flex-wrap: wrap;
    margin-bottom: 25px;
  }

  ._2-buttons-wrapper {
    flex-direction: column;
  }

  .home-hero-about-teacher-content {
    margin-bottom: 15px;
  }

  .home-hero-about-teacher-name {
    font-size: 18px;
  }

  .home-hero-about-teacher-rol {
    font-size: 16px;
  }

  .companies-home-hero-text {
    margin-bottom: 20px;
  }

  .container-large-1215px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .card-home-about-rol {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .container-small-588px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .home-course-steps-wrapper {
    margin-bottom: 40px;
  }

  .home-features-wrapper {
    grid-template-columns: auto auto;
  }

  .home-feature-wrapper {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-feature-wrapper._2 {
    border-right-width: 0;
  }

  .home-feature-wrapper._3 {
    border-right-width: 1px;
  }

  .home-feature-wrapper._4 {
    border-right-width: 0;
  }

  .home-feature-number {
    font-size: 40px;
  }

  .home-feature-text {
    white-space: normal;
    font-size: 16px;
  }

  .home-course-steps-content {
    margin-bottom: 40px;
  }

  .card-home-course-step-content-top {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 10px;
  }

  .card-home-course-step-number {
    width: 33px;
    max-height: 33px;
    min-height: 33px;
    min-width: 33px;
    margin-bottom: 10px;
    margin-right: 0;
    font-size: 18px;
  }

  .content-top.experience {
    margin-bottom: 40px;
  }

  .content-top.other-courses {
    text-align: center;
    flex-direction: column;
    align-items: stretch;
  }

  .content-top.blog {
    margin-bottom: 50px;
  }

  .content-top.latest-articles {
    flex-flow: column;
    align-items: stretch;
    margin-bottom: 40px;
  }

  .content-top.blog-category {
    margin-bottom: 50px;
  }

  .home-topics-grid {
    margin-bottom: 40px;
  }

  .card-home-topic-icon {
    width: 94px;
    max-height: 94px;
    min-height: 94px;
    min-width: 94px;
    margin-bottom: 20px;
  }

  .container-medium-728px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .container-medium-728px.testimonials {
    margin-bottom: 40px;
  }

  .container-large-1048px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .card-testimonial-content-bottom {
    flex-flow: column-reverse;
    align-items: flex-start;
  }

  .card-testimonial-rol {
    font-size: 16px;
  }

  .container-medium-661px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .faqs-wrapper {
    margin-bottom: 40px;
  }

  .faq-content-bottom {
    margin-left: 0;
  }

  .faq-icon-wrapper {
    margin-right: 10px;
  }

  .cta-wrapper {
    align-items: stretch;
    padding: 50px 25px;
  }

  .shape.home-hero-1 {
    bottom: -16px;
    left: -38px;
  }

  .shape.home-hero-2 {
    right: -37px;
  }

  .shape.home-hero-3 {
    width: 69%;
    top: -37px;
    left: -110.5px;
  }

  .shape.home-about-2 {
    top: 120px;
    right: -115px;
  }

  .shape.home-features-1 {
    bottom: -98.891px;
    left: -96px;
  }

  .shape.home-features-2 {
    top: -96px;
    right: -109px;
  }

  .shape.home-course-steps-2 {
    bottom: 305px;
  }

  .shape.home-topics-1 {
    top: 391px;
  }

  .shape.home-topics-2 {
    bottom: 180px;
  }

  .shape.testimonials-1 {
    bottom: 48px;
  }

  .shape.cta-1 {
    top: -56.203px;
  }

  .shape.cta-2 {
    bottom: -57.203px;
  }

  .shape.about-hero-1 {
    display: none;
    top: -18px;
  }

  .shape.about-hero-2 {
    bottom: -21px;
  }

  .shape.about-hero-3 {
    top: -17px;
    right: -14px;
  }

  .shape.story-2 {
    left: -37px;
  }

  .shape.story-3 {
    bottom: -18px;
    right: -9px;
  }

  .shape.values-1 {
    left: -11px;
  }

  .shape.values-2 {
    right: -11px;
  }

  .shape.experience-2 {
    bottom: -19px;
    left: -12px;
  }

  .shape.about-testimonials-2 {
    right: -10px;
  }

  .shape.courses-hero {
    display: none;
    right: -93px;
  }

  .shape.course-hero-1 {
    top: -13px;
    left: -11px;
  }

  .shape.course-hero-2, .shape.course-reviews-1 {
    display: none;
  }

  .shape.course-reviews-2 {
    display: none;
    bottom: -17px;
  }

  .shape.course-videos-hero-1 {
    bottom: -18px;
    left: -89px;
  }

  .shape.course-videos-hero-2 {
    top: -14px;
    right: -65px;
  }

  .shape.contact-1 {
    display: none;
    bottom: -43px;
  }

  .shape.contact-2 {
    left: -11px;
  }

  .shape.contact-3 {
    top: -17px;
  }

  .shape.direct-contact-links-1 {
    top: -13px;
  }

  .shape.direct-contact-links-2 {
    bottom: -18px;
  }

  .shape.password-1 {
    bottom: 50.832px;
    left: 4.642px;
  }

  .shape.password-2 {
    top: 41.355px;
    right: -23.085px;
  }

  .shape.not-found-1 {
    top: -60px;
  }

  .shape.not-found-2 {
    bottom: -68px;
    right: 15px;
  }

  .shape.not-found-3 {
    top: -158.497px;
    left: -211px;
  }

  .shape.home-hero-3_2 {
    width: 69%;
    top: -37px;
    left: -110.5px;
  }

  .shape.home-hero-1_2 {
    bottom: -16px;
    left: -38px;
  }

  .shape.home-hero-shape {
    top: 391px;
  }

  .footer-content-top {
    margin-bottom: 44px;
  }

  .footer-content {
    margin-bottom: 51px;
  }

  .footer-menu {
    margin-bottom: 0;
  }

  .footer-menu-content {
    flex-wrap: wrap;
  }

  .footer-nav-link {
    white-space: normal;
  }

  .footer-social-media-wrapper {
    padding-top: 16px;
    padding-bottom: 0;
  }

  .footer-social-media-link {
    margin-right: 20px;
  }

  .footer-newsletter-form {
    flex-direction: column;
    align-items: stretch;
  }

  .dropdown-arrow {
    font-size: 11px;
  }

  .dropdown-nav-main-wrapper {
    margin-bottom: -32px;
  }

  .dropdown-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon-wrapper {
    width: 20px;
  }

  .cart-header {
    padding: 15px 16px;
  }

  .about-hero-features-grid {
    margin-bottom: 35px;
  }

  .about-hero-feature-wrapper {
    font-size: 16px;
  }

  .story-left-content-top {
    margin-bottom: 40px;
  }

  .story-right-content-bottom {
    margin-bottom: 40px;
    padding-left: 0;
  }

  .values-grid {
    margin-bottom: 40px;
  }

  .card-value-icon {
    width: 94px;
    max-height: 94px;
    min-height: 94px;
    min-width: 94px;
    margin-bottom: 20px;
  }

  .card-experience-content-top {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 15px;
  }

  .card-experience-rol, .card-experience-list-wrapper {
    font-size: 16px;
  }

  .card-experience-list-dot {
    margin-right: 6px;
  }

  .card-experience-date {
    font-size: 16px;
  }

  .container-medium-795px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .card-about-testimonial-rol {
    font-size: 16px;
  }

  .container-medium-671px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .container-medium-671px.courses-hero {
    margin-bottom: 40px;
  }

  .card-course-featured-content {
    padding: 33px 25px 40px;
  }

  .card-course-featured-content-bottom {
    flex-wrap: wrap-reverse;
    margin-bottom: -25px;
  }

  .card-course-featured-badge {
    margin-bottom: 25px;
    margin-right: 30px;
  }

  .link-underline.card-course-featured {
    margin-bottom: 25px;
  }

  .link-underline.card-course {
    margin-bottom: 25px;
    margin-right: 30px;
  }

  .card-course-content {
    padding: 33px 25px 40px;
  }

  .card-course-content-bottom {
    flex-wrap: wrap-reverse;
    margin-bottom: -25px;
  }

  .card-course-level {
    margin-bottom: 25px;
  }

  .card-course-price {
    margin-bottom: 6px;
  }

  .empty-state.cart-empty {
    align-items: stretch;
    padding-left: 16px;
    padding-right: 16px;
  }

  .video-preview-button {
    width: 28%;
    max-height: 50%;
    min-height: 50%;
    min-width: 28%;
  }

  .course-tabs-menu {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .course-tab-link {
    padding: 20px 0;
    font-size: 18px;
  }

  .course-content-rich-text ul {
    margin-right: 0;
    display: block;
  }

  .course-content-rich-text p {
    margin-bottom: 22px;
  }

  .add-cart-option-list {
    margin-bottom: 15px;
  }

  .course-price-wrapper {
    margin-bottom: 30px;
  }

  .course-price, .course-compare-price {
    font-size: 19px;
  }

  .select-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .course-features-grid {
    max-width: 300px;
  }

  .course-feature-wrapper {
    font-size: 16px;
  }

  .container-medium-888px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .course-videos-tabs-menu {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .course-videos-tab-link {
    padding: 20px 0;
    font-size: 18px;
  }

  .card-course-video-button {
    margin-bottom: 20px;
  }

  .card-course-video-content {
    flex-direction: column-reverse;
  }

  .card-course-video-duration {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .card-course-videos-resource-icon-wrapper, .card-course-videos-community-icon-wrapper {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .blog-hero-grid {
    grid-row-gap: 70px;
  }

  .post-content-top {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .post-content-top-divider {
    width: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .post-popular-wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .blog-grid {
    grid-row-gap: 60px;
  }

  .categories-wrapper {
    margin-bottom: -15px;
    margin-right: -15px;
  }

  .category-item {
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .container-large-1122px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .container-large-1122px.post {
    margin-bottom: 48px;
  }

  .post-about-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .post-author-link {
    flex-wrap: wrap;
    margin-bottom: -15px;
  }

  .post-author-content {
    margin-bottom: 15px;
  }

  .post-author-name {
    font-size: 18px;
  }

  .post-author-rol {
    font-size: 16px;
  }

  .latest-articles-grid {
    grid-row-gap: 60px;
  }

  .card-author-content-top {
    margin-bottom: 15px;
  }

  .rich-text p {
    margin-bottom: 23px;
  }

  .rich-text ul {
    margin-top: 23px;
    margin-bottom: 40px;
  }

  .rich-text h3 {
    margin-top: 40px;
  }

  .rich-text ol {
    margin-top: 23px;
    margin-bottom: 40px;
  }

  .rich-text blockquote {
    margin-top: 40px;
    margin-bottom: 48px;
  }

  .rich-text h6, .rich-text h4, .rich-text h5 {
    margin-top: 40px;
  }

  .contact-wrapper {
    margin-bottom: 93px;
  }

  .contact-links-grid {
    grid-row-gap: 25px;
  }

  .contact-link {
    flex-wrap: wrap;
    margin-bottom: -10px;
    font-size: 16px;
  }

  .contact-link-icon {
    width: 35px;
    max-height: 35px;
    min-height: 35px;
    min-width: 35px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 15px;
  }

  .contact-link-icon.icon-size-22px {
    font-size: 19px;
  }

  .text-area {
    padding-left: 20px;
    padding-right: 20px;
  }

  .card-direct-contact-link-text {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .bg.contact {
    min-height: 58%;
  }

  .bg.home-hero {
    height: 65%;
    max-height: 75%;
    min-height: auto;
    top: 202px;
  }

  .contact-link-text {
    margin-bottom: 10px;
  }

  .blog-newsletter-wrapper {
    padding: 60px 25px;
  }

  .blog-newsletter-form {
    flex-direction: column;
    align-items: stretch;
  }

  .category-title-wrapper.course-levels {
    margin-bottom: 40px;
  }

  .category-title-wrapper.blog-category {
    margin-bottom: 30px;
  }

  .cart-footer {
    padding-left: 16px;
    padding-right: 16px;
  }

  .cart-item {
    font-size: 16px;
  }

  .cart-list {
    padding-left: 16px;
    padding-right: 16px;
  }

  .heading-size-h3 {
    font-size: 21px;
  }

  .line-square-icon.top-bar-close-icon {
    padding-left: 12px;
    padding-right: 12px;
  }

  .btn-index-cta {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .btn-index-cta.button-row {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .card-home-pages-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .home-pages-top-wrapper {
    margin-bottom: 16px;
  }

  .image-home-link-wrapper {
    max-width: 180px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .figma-file-text-wrapper, .figma-file-text-wrapper.pd-large {
    padding-left: 24px;
    padding-right: 24px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr;
  }

  .home-surprises-card {
    border-radius: 22px;
  }

  .buttons-row {
    flex-direction: column;
  }

  .buttons-row.center {
    align-items: stretch;
  }

  .mg-bottom-24px {
    margin-bottom: 16px;
  }

  .mg-bottom-56px {
    margin-bottom: 32px;
  }

  .top-bar-wrapper {
    padding-left: 19px;
    padding-right: 19px;
  }

  .logo {
    font-size: 32px;
    line-height: 32px;
  }

  .html-embed {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  .home-hero-wrapper-2 {
    margin-bottom: 66px;
  }

  .hero-container-companies {
    background-color: var(--neutral-100);
    margin-bottom: 0;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .hero-container-default {
    padding-left: 16px;
    padding-right: 16px;
  }

  .home-hero-wrapper-2-mobile, .home-hero-wrapper-2-mobile-landscape {
    margin-bottom: 66px;
    padding-left: 0;
    padding-right: 0;
  }

  .syllabus-subject {
    height: 100%;
    display: inline-block;
    overflow: hidden;
  }

  .accordion-toggle {
    width: 100%;
    height: auto;
    max-width: 100%;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: stretch;
  }

  .subject-title {
    width: auto;
  }

  .subject-title.too-long {
    height: auto;
    max-width: 100%;
    white-space: break-spaces;
    overflow-wrap: normal;
  }

  .view-more {
    height: auto;
  }

  .course-type-accordian {
    height: 100%;
    display: inline-block;
    overflow: hidden;
  }

  .course-type-title {
    width: auto;
  }

  .course-type-title.too-long {
    height: auto;
    max-width: 100%;
    white-space: break-spaces;
    overflow-wrap: normal;
  }

  .course-type-features-grid {
    max-width: 300px;
  }

  .course-card-title {
    margin-bottom: 30px;
  }

  .course-title-text-box {
    font-size: 19px;
  }

  .hero-container-colleges {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .colleges-home-hero-text {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .slider-t8.slider-mobile {
    width: 100%;
    margin-left: 10px;
  }

  .whatsapp-container {
    width: 65px;
    height: 65px;
    bottom: 10px;
    right: 0;
  }

  .whatsapp-chat {
    width: 100%;
    height: 40vh;
    position: relative;
  }

  .whatsapp-integration {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    bottom: 0;
    right: 0;
  }

  .top-bar-overflow-wrapper {
    padding-left: 4px;
    padding-right: 4px;
  }

  .overflow-container {
    padding-left: 0;
    padding-right: 0;
  }

  .overflow-text.list {
    margin-left: 0;
  }

  .div-block-3, .privacy-policy-body {
    padding-left: 18px;
    padding-right: 18px;
  }

  .heading-2 {
    overflow: visible;
  }

  .terms-and-conditions-body {
    padding-left: 18px;
    padding-right: 18px;
  }

  .link-2, .text-block-5, .pdf-title {
    padding-left: 16px;
  }

  .enquiry-form {
    flex-direction: column;
  }

  .html-embed-3 {
    width: 100%;
    height: 3640px;
  }

  .notification-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .notification-section.home-hero {
    padding-bottom: 24px;
  }

  .notification-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .notification-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .notification-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .notification-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .notification-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .notification-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .notification-section.about-hero {
    padding-bottom: 66px;
  }

  .notification-section.courses-hero, .notification-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .notification-section.course-videos {
    padding-top: 40px;
  }

  .notification-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .notification-section.post, .notification-section.contact, .notification-section.course-levels, .notification-section.blog-category {
    padding-top: 30px;
  }

  .notification-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .notification-section.other-courses {
    padding-top: 66px;
  }

  .notification-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .notification-section.notification-bar, .notification-section.notification-bar-tab, .notification-section.notification-bar-landscape, .notification-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .notification-section.resources-hero {
    padding-bottom: 66px;
  }

  .notification-section.tc-hero, .notification-section.pp-hero {
    padding-top: 30px;
  }

  .alert-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .alert-section.home-hero {
    padding-bottom: 24px;
  }

  .alert-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .alert-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .alert-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .alert-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .alert-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .alert-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .alert-section.about-hero {
    padding-bottom: 66px;
  }

  .alert-section.courses-hero, .alert-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .alert-section.course-videos {
    padding-top: 40px;
  }

  .alert-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .alert-section.post, .alert-section.contact, .alert-section.course-levels, .alert-section.blog-category {
    padding-top: 30px;
  }

  .alert-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .alert-section.other-courses {
    padding-top: 66px;
  }

  .alert-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .alert-section.notification-bar, .alert-section.notification-bar-tab, .alert-section.notification-bar-landscape, .alert-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .alert-section.resources-hero {
    padding-bottom: 66px;
  }

  .alert-section.tc-hero, .alert-section.pp-hero {
    padding-top: 30px;
  }

  .home-hero-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .home-hero-section.home-hero {
    padding-bottom: 24px;
  }

  .home-hero-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .home-hero-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .home-hero-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .home-hero-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .home-hero-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .home-hero-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .home-hero-section.about-hero {
    padding-bottom: 66px;
  }

  .home-hero-section.courses-hero, .home-hero-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .home-hero-section.course-videos {
    padding-top: 40px;
  }

  .home-hero-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .home-hero-section.post, .home-hero-section.contact, .home-hero-section.course-levels, .home-hero-section.blog-category {
    padding-top: 30px;
  }

  .home-hero-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .home-hero-section.other-courses {
    padding-top: 66px;
  }

  .home-hero-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .home-hero-section.notification-bar, .home-hero-section.notification-bar-tab, .home-hero-section.notification-bar-landscape, .home-hero-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .home-hero-section.resources-hero {
    padding-bottom: 66px;
  }

  .home-hero-section.tc-hero, .home-hero-section.pp-hero {
    padding-top: 30px;
  }

  .marketing-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .marketing-section.home-hero {
    padding-bottom: 24px;
  }

  .marketing-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .marketing-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .marketing-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .marketing-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .marketing-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .marketing-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .marketing-section.about-hero {
    padding-bottom: 66px;
  }

  .marketing-section.courses-hero, .marketing-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .marketing-section.course-videos {
    padding-top: 40px;
  }

  .marketing-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .marketing-section.post, .marketing-section.contact, .marketing-section.course-levels, .marketing-section.blog-category {
    padding-top: 30px;
  }

  .marketing-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .marketing-section.other-courses {
    padding-top: 66px;
  }

  .marketing-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .marketing-section.notification-bar, .marketing-section.notification-bar-tab, .marketing-section.notification-bar-landscape, .marketing-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .marketing-section.resources-hero {
    padding-bottom: 66px;
  }

  .marketing-section.tc-hero, .marketing-section.pp-hero {
    padding-top: 30px;
  }

  .courses-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .courses-section.home-hero {
    padding-bottom: 24px;
  }

  .courses-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .courses-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .courses-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .courses-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .courses-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .courses-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .courses-section.about-hero {
    padding-bottom: 66px;
  }

  .courses-section.courses-hero, .courses-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .courses-section.course-videos {
    padding-top: 40px;
  }

  .courses-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .courses-section.post, .courses-section.contact, .courses-section.course-levels, .courses-section.blog-category {
    padding-top: 30px;
  }

  .courses-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .courses-section.other-courses {
    padding-top: 66px;
  }

  .courses-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .courses-section.notification-bar, .courses-section.notification-bar-tab, .courses-section.notification-bar-landscape, .courses-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .courses-section.resources-hero {
    padding-bottom: 66px;
  }

  .courses-section.tc-hero, .courses-section.pp-hero {
    padding-top: 30px;
  }

  .features-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .features-section.home-hero {
    padding-bottom: 24px;
  }

  .features-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .features-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .features-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .features-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .features-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .features-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .features-section.about-hero {
    padding-bottom: 66px;
  }

  .features-section.courses-hero, .features-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .features-section.course-videos {
    padding-top: 40px;
  }

  .features-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .features-section.post, .features-section.contact, .features-section.course-levels, .features-section.blog-category {
    padding-top: 30px;
  }

  .features-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .features-section.other-courses {
    padding-top: 66px;
  }

  .features-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .features-section.notification-bar, .features-section.notification-bar-tab, .features-section.notification-bar-landscape, .features-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .features-section.resources-hero {
    padding-bottom: 66px;
  }

  .features-section.tc-hero, .features-section.pp-hero {
    padding-top: 30px;
  }

  .student-testimonials-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .student-testimonials-section.home-hero {
    padding-bottom: 24px;
  }

  .student-testimonials-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .student-testimonials-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .student-testimonials-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .student-testimonials-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .student-testimonials-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .student-testimonials-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .student-testimonials-section.about-hero {
    padding-bottom: 66px;
  }

  .student-testimonials-section.courses-hero, .student-testimonials-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .student-testimonials-section.course-videos {
    padding-top: 40px;
  }

  .student-testimonials-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .student-testimonials-section.post, .student-testimonials-section.contact, .student-testimonials-section.course-levels, .student-testimonials-section.blog-category {
    padding-top: 30px;
  }

  .student-testimonials-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .student-testimonials-section.other-courses {
    padding-top: 66px;
  }

  .student-testimonials-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .student-testimonials-section.notification-bar, .student-testimonials-section.notification-bar-tab, .student-testimonials-section.notification-bar-landscape, .student-testimonials-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .student-testimonials-section.resources-hero {
    padding-bottom: 66px;
  }

  .student-testimonials-section.tc-hero, .student-testimonials-section.pp-hero {
    padding-top: 30px;
  }

  .cta-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .cta-section.home-hero {
    padding-bottom: 24px;
  }

  .cta-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .cta-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .cta-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .cta-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .cta-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .cta-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .cta-section.about-hero {
    padding-bottom: 66px;
  }

  .cta-section.courses-hero, .cta-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .cta-section.course-videos {
    padding-top: 40px;
  }

  .cta-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .cta-section.post, .cta-section.contact, .cta-section.course-levels, .cta-section.blog-category {
    padding-top: 30px;
  }

  .cta-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .cta-section.other-courses {
    padding-top: 66px;
  }

  .cta-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .cta-section.notification-bar, .cta-section.notification-bar-tab, .cta-section.notification-bar-landscape, .cta-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cta-section.resources-hero {
    padding-bottom: 66px;
  }

  .cta-section.tc-hero, .cta-section.pp-hero {
    padding-top: 30px;
  }

  .enquiry-section {
    padding-top: 82px;
    padding-bottom: 82px;
  }

  .enquiry-section.home-hero {
    padding-bottom: 24px;
  }

  .enquiry-section.bg-neutral-200.home-topics {
    padding-top: 73px;
  }

  .enquiry-section.bg-neutral-200.course {
    padding-bottom: 66px;
  }

  .enquiry-section.bg-neutral-200.course-videos-hero {
    padding-bottom: 60px;
  }

  .enquiry-section.bg-neutral-800.home-features {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .enquiry-section.bg-neutral-800.testimonials {
    padding-top: 74px;
    padding-bottom: 78px;
  }

  .enquiry-section.home-faqs {
    padding-top: 78px;
    padding-bottom: 69px;
  }

  .enquiry-section.about-hero {
    padding-bottom: 66px;
  }

  .enquiry-section.courses-hero, .enquiry-section.course-hero {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .enquiry-section.course-videos {
    padding-top: 40px;
  }

  .enquiry-section.blog-hero {
    padding-top: 30px;
    padding-bottom: 74px;
  }

  .enquiry-section.post, .enquiry-section.contact, .enquiry-section.course-levels, .enquiry-section.blog-category {
    padding-top: 30px;
  }

  .enquiry-section.utility-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .enquiry-section.other-courses {
    padding-top: 66px;
  }

  .enquiry-section.hero-page {
    background-image: linear-gradient(to top, var(--primary-main) 30%, white 30%);
  }

  .enquiry-section.notification-bar, .enquiry-section.notification-bar-tab, .enquiry-section.notification-bar-landscape, .enquiry-section.overflow-notification-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .enquiry-section.resources-hero {
    padding-bottom: 66px;
  }

  .enquiry-section.tc-hero, .enquiry-section.pp-hero {
    padding-top: 30px;
  }
}

#w-node-da96b995-b1b6-4e85-755c-59103631ba77-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631ba7e-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631ba85-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631baa3-08079d46, #w-node-ccee99c9-83e8-7044-ebe3-9405d063c5e4-08079d46, #w-node-_1753b3a2-f504-1314-beb4-5b9fc5f71a4e-08079d46, #w-node-d6035dc0-0c8f-ebc8-a8de-86d5b7ee7709-08079d46, #w-node-_0cb51210-1ba5-5479-937c-b49eb29ca774-08079d46, #w-node-_223cab3a-1e46-64ad-f006-d0c87ff501dd-08079d46, #w-node-_7bfdbe04-8b6c-cb7c-0791-874134c0f5f6-08079d46, #w-node-f7b0d21e-87f6-a803-799b-07b40bbd0a7d-08079d46, #w-node-_7dc66e84-5341-a112-a097-6023ba50b874-08079d46, #w-node-_7dc66e84-5341-a112-a097-6023ba50b87a-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb1f-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb26-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb29-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb2b-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb33-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb40-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb43-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb45-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb4d-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb54-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb59-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb63-08079d46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b12acbf1-4401-ffa1-233f-a292ddc46c10-08079da7, #w-node-f883a31a-5f18-7a34-43a1-6d578a17f715-08079da7, #w-node-_736a4f51-80ee-b602-655b-93ac609e0d7c-08079da7, #w-node-bc5a2fcc-d37b-420b-a5bc-89854bb46816-08079da7, #w-node-_2f9cb310-c807-7746-c7c3-56f0f0bd8dd5-08079da7, #w-node-f4055a69-9b88-c1f3-7375-8bedfd5278bc-08079da7 {
  align-self: end;
}

#w-node-bbb4fdd4-a0b7-e940-ad9f-ceda48ab5d6c-08079da9, #w-node-_532f6b29-7137-9805-9a2b-57aefaad2312-08079da9, #w-node-b27ac1c4-2b60-574f-d958-922b44c6050b-08079da9, #w-node-_99266125-75ee-03b5-a2bd-0f8ea29c17d5-08079da9, #w-node-_047a43bd-51d5-b72f-f525-3cd71baa2f43-08079da9, #w-node-_931fd501-2c87-f3a7-af4c-e204d7551e0c-08079da9, #w-node-_6a7a0c89-0b55-7a1f-029d-33eda19b2c31-08079da9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f3b131a7-5d28-3896-fcd3-82065587c3ad-b1ce5f52 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_14ccd42d-8405-df93-06f2-1836f2edc4bb-b1ce5f52 {
  grid-area: span 1 / span 2 / span 1 / span 2;
  justify-self: start;
}

#w-node-c51064d0-7bee-84e5-ec96-a4b68685ce48-c312a0ca, #w-node-_864723fd-6daa-a1a7-1f92-b5dfcdd0dd75-c312a0ca, #w-node-_1992e4a7-2b68-8a30-08fa-e7805550f0f0-c312a0ca, #w-node-de962e70-b158-f6d9-ca44-d5f1ae395247-c312a0ca, #w-node-_2119a84d-a407-7791-3601-f5abd3308caf-c312a0ca, #w-node-_2119a84d-a407-7791-3601-f5abd3308cf2-c312a0ca, #w-node-_2119a84d-a407-7791-3601-f5abd3308cfe-c312a0ca, #w-node-_2119a84d-a407-7791-3601-f5abd3308d0a-c312a0ca, #w-node-_2119a84d-a407-7791-3601-f5abd3308d16-c312a0ca, #w-node-_2119a84d-a407-7791-3601-f5abd3308d22-c312a0ca, #w-node-_2119a84d-a407-7791-3601-f5abd3308d2e-c312a0ca, #w-node-c51064d0-7bee-84e5-ec96-a4b68685ce48-5653ccab, #w-node-_864723fd-6daa-a1a7-1f92-b5dfcdd0dd75-5653ccab, #w-node-_1992e4a7-2b68-8a30-08fa-e7805550f0f0-5653ccab, #w-node-de962e70-b158-f6d9-ca44-d5f1ae395247-5653ccab, #w-node-c02cef61-4a49-88ab-e2e6-966dad96af33-5653ccab, #w-node-_90403ae3-d5b5-b5aa-0fc6-fc5cccdeb91d-5653ccab, #w-node-e2320300-078d-724a-351c-42c9678e66d3-5653ccab {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 991px) {
  #w-node-da96b995-b1b6-4e85-755c-59103631bb2b-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb45-08079d46 {
    order: -9999;
  }

  #w-node-_5aeeca34-ff35-8a2a-0379-23a93bd1e8a7-08079da1, #w-node-c5d2f148-ae3c-4efa-65bb-7a4bbbe6cabb-08079da1, #w-node-fd9d0d48-6c47-fe7a-5934-9c0cfb618f4c-08079da1, #w-node-_5aeeca34-ff35-8a2a-0379-23a93bd1e8a7-4684f176, #w-node-c5d2f148-ae3c-4efa-65bb-7a4bbbe6cabb-4684f176 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-da96b995-b1b6-4e85-755c-59103631bb2b-08079d46, #w-node-da96b995-b1b6-4e85-755c-59103631bb45-08079d46 {
    order: -9999;
  }

  #w-node-f3b131a7-5d28-3896-fcd3-82065587c3ad-b1ce5f52 {
    grid-column: span 1 / span 1;
  }

  #w-node-_14ccd42d-8405-df93-06f2-1836f2edc4bb-b1ce5f52 {
    grid-column: span 1 / span 1;
    justify-self: auto;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_5aeeca34-ff35-8a2a-0379-23a93bd1e8a7-08079da1, #w-node-c5d2f148-ae3c-4efa-65bb-7a4bbbe6cabb-08079da1, #w-node-_5aeeca34-ff35-8a2a-0379-23a93bd1e8a7-4684f176, #w-node-c5d2f148-ae3c-4efa-65bb-7a4bbbe6cabb-4684f176 {
    grid-column: span 1 / span 1;
  }
}


@font-face {
  font-family: 'Icons Course X Template';
  src: url('../fonts/icons-course-x-template.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Filled Icons';
  src: url('../fonts/filled-icon-font.woff2') format('woff2'), url('../fonts/filled-icon-font.eot') format('embedded-opentype'), url('../fonts/filled-icon-font.woff') format('woff'), url('../fonts/filled-icon-font.ttf') format('truetype'), url('../fonts/filled-icon-font.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Line Rounded Icons';
  src: url('../fonts/line-rounded-icons.woff2') format('woff2'), url('../fonts/line-rounded-icons.eot') format('embedded-opentype'), url('../fonts/line-rounded-icons.woff') format('woff'), url('../fonts/line-rounded-icons.ttf') format('truetype'), url('../fonts/line-rounded-icons.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Line Square Icons';
  src: url('../fonts/line-square-icons.woff2') format('woff2'), url('../fonts/line-square-icons.eot') format('embedded-opentype'), url('../fonts/line-square-icons.woff') format('woff'), url('../fonts/line-square-icons.ttf') format('truetype'), url('../fonts/line-square-icons.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Social Icons Font';
  src: url('../fonts/social-icon-font.woff2') format('woff2'), url('../fonts/social-icon-font.eot') format('embedded-opentype'), url('../fonts/social-icon-font.woff') format('woff'), url('../fonts/social-icon-font.ttf') format('truetype'), url('../fonts/social-icon-font.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Onstage';
  src: url('../fonts/OnStage.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plus Jakarta Display';
  src: url('../fonts/PlusJakartaDisplay-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plus Jakarta Display';
  src: url('../fonts/PlusJakartaDisplay-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plus Jakarta Display';
  src: url('../fonts/PlusJakartaDisplay-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.question-text {
  font-family: "Palatino";
  font-size: 18px;
  margin-bottom: 10px;
}

.answer-choice {
  font-family: "Palatino";
  display: block;
  margin-bottom: 5px;
  max-width: 650px;
}

.input-radio-button {
  margin-right: 5px;
}

/* Style for the checkmark */
.checkmark, .cross {
  position: absolute;
  margin-left: 10px;
}

.checkmark {
  color: green;
}

.cross {
  color: red;
}

.moving-text-band {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.text {
  display: inline-block;
  animation: moveText linear infinite;
}

@keyframes moveText {
  to {
    transform: translateX(100%);
  }
}

.question-btn-panel {
  padding: 20px 15px;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.quiz-btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.quiz-btn-clear {
  color: #000000;
  background-color: #fff;
  border-color: #000000;
}

.quiz-btn-save-review {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.quiz-btn-review {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.question-numbers {
  display: flex;
  flex-wrap: wrap;
}

.question-number-box {
  width: 30px;
  height: 30px;
  background-color: lightgray;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.legend-item {
  display: flex;
  align-items: center;
}

.question-not-visited {
  color: #303030 !important;
  background-size: 100% 100%;
  background: url("../../public/images/test-portal-icons/Not_Visited.png");
}

.question-not-answered {
  border-color: #33414E !important;
  color: #fff !important;
  background: url("../../public/images/test-portal-icons/Not_Answered.png");
}

.question-answered {
  color: #fff !important;
  background: url("../../public/images/test-portal-icons/Answered.png");
  background-size: 100% 100%;
}

.question-marked-for-review {
  border-color: #33414E !important;
  color: #fff !important;
  background: url("../../public/images/test-portal-icons/Marked_For_Review.png");
  background-size: 100% 100%;
}

.question-answered-marked-for-review {
  color: #fff !important;
  background: url("../../public/images/test-portal-icons/Answered_And_Marked_For_Review.png");
  background-size: 100% 100%;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.time-started {
  background: #0098DA none repeat scroll 0 0;
  border-radius: 20px;
  color: #fff !important;
  padding: 0px 10px;
}

.choice-container {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 5px; /* Add some spacing between choices */
}

.question-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.question-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}