.quantity-display {
    display: inline-block;
    width: 40px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    border: 2px solid #007bff;
    border-radius: 5px;
    text-align: center;
    font-size: 1.5em; /* Adjust font size as needed */
    font-weight: bold;
    margin: 0 10px;
}

.mock-test-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.mock-test-item label {
    margin-right: auto; /* Pushes the label to the left */
}

.mock-test-item input[type="checkbox"] {
    margin-left: 8px; /* Adjust the margin as needed */
}