.form-row {
  display: flex;
  gap: 20px; /* Adjust the gap between the input fields */
}

.form-group {
  flex: 1; /* Distribute the available space evenly between the input fields */
  padding-bottom: 16px;
}

.auth-form {
  background: rgba(19, 35, 47, 0.9);
  padding: 28px;
  max-width: 600px;
  margin: 40px auto;
  border-radius: 32px;
  box-shadow: 0 4px 10px 4px rgba(19, 35, 47, 0.3);
}

.tab-group {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
}
.tab-group:after {
  content: "";
  display: table;
  clear: both;
}
.tab-group li button {
  display: block;
  text-decoration: none;
  padding: 15px;
  background: rgba(160, 179, 176, 0.25);
  color: #a0b3b0;
  font-size: 20px;
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.5s ease;
}
.tab-group li button:hover {
  background: #1362ab;
  color: #ffffff;
}
.tab-group .active button {
  background: #166fc2;
  color: #ffffff;
}

.auth-button {
  border: 0;
  outline: none;
  border-radius: 8px;
  padding: 8px 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: #166fc2;
  color: #ffffff;
  transition: all 0.5s ease;
}
.auth-button:hover, .button:focus {
  background: #1362ab;
}

.auth-button-block {
  display: block;
  width: 100%;
}

.auth-label {
  color: white
}