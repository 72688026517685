.horizontal-bars-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .horizontal-bar {
    width: 85%; /* Adjust width as needed */
    height: 56px; /* Adjust height as needed */
    background-color: #166fc2; /* Set background color */
    color: white; /* Set text color */
    text-align: center;
    border-radius: 16px; /* Half of the height for rounded corners */
    margin-bottom: 16px; /* Add space between buttons */
    margin-top: 16px; /* Add space between buttons */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  
  .horizontal-bar:hover {
    background-color: #0056b3; /* Change background color on hover */
  }