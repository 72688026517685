@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

form .otp-input-field {
    flex-direction: row;
    column-gap: 10px;
}

:where(.otp-input-field) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

form .otp-input-field {
    flex-direction: row;
    column-gap: 10px;
}

.otp-input-field input {
    height: 88px;
    width: 64px;
    border-radius: 6px;
    outline: none;
    font-size: 40px;
    text-align: center;
    border: 1px solid #ddd;
}

.otp-input-field input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.otp-input-field input::-webkit-inner-spin-button,
.otp-input-field input::-webkit-outer-spin-button {
  display: none;
}

@media screen and (max-width: 768px) {
    .otp-input-field {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .otp-input-field input {
      width: 100%;
      max-width: 200px; /* Adjust max-width as needed */
      margin-bottom: 10px; /* Add some spacing between input fields */
    }
  }